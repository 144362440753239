import React, { useState, useEffect } from 'react';
import { noop } from 'lodash/fp';
import {
  string, shape, func, bool,
} from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormElementContainer, { BaseInput, OutlineInput, useInput } from 'components/BaseInput';
import { capMap } from 'utils/lodash';
import { StyledSelect } from './styled';

export const Select = ({
  width, values, defaultValue, label, error, errorText, register,
  setValue, name, required, variant, disabled, displayEmpty, onSelect
}) => {
  const [value, setSelectedValue] = useState(defaultValue || '');

  useEffect(() => {
    register({ name });
    setValue(name, value);
  }, [register, name, setValue, value]);

  const { inputErrorText } = useInput('', errorText, error);


  const onChangeValue = (name, value) => {
    setSelectedValue(value)
    onSelect(name, value)
  };

  return (
    <FormElementContainer
      error={!!error}
      label={label}
      errorText={inputErrorText}
      width={width}
      required={required}
      name={name}
      variant={variant}
      disabled={disabled}
    >
      <StyledSelect
        name={name}
        id={name}
        input={variant === 'outlined' ? <OutlineInput /> : <BaseInput />}
        value={value}
        onChange={(e) => onChangeValue(name, e.target.value)}
        displayEmpty={displayEmpty}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        {capMap((item, key) => (
          <MenuItem
            key={key}
            value={key}
          >
            {item}
          </MenuItem>
        ), values)}
      </StyledSelect>
    </FormElementContainer>
  );
};

Select.propTypes = {
  width: string,
  values: shape({}),
  defaultValue: string,
  label: string,
  error: string,
  errorText: string,
  variant: string,
  register: func,
  setValue: func,
  onSelect: func,
  name: string.isRequired,
  required: bool,
  disabled: bool,
  displayEmpty: bool,
};

Select.defaultProps = {
  width: '100%',
  values: {},
  defaultValue: '',
  label: '',
  error: '',
  errorText: '',
  variant: 'standard',
  required: false,
  disabled: false,
  register: noop,
  setValue: noop,
  displayEmpty: false,
};

export default Select;
