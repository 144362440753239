import React, { useState } from 'react';
import { curry } from 'lodash/fp';
import {
  func, shape, string,
} from 'prop-types';

import { getUnits, CURRENCIES } from 'configs';

import MultipleSelect from 'components/MultipleSelect';

import { getFieldError } from 'utils/forms';
import { ProductInputPlaceholder } from 'static/images';
import {
  ImageInput,
  TextInput,
  ColumnInputs,
  RowInputs,
  Select,
  CustomInputs,
  StyledFormHeadline,
} from 'components';

import { getSlotTypes } from 'configs/slotTypes';
import { getDropHeights } from 'configs/dropHeights';

import {
  RowContainer,
  PriceContainer,
  PackageContainer,
  UnitContainer,
  WeightContainer,
} from './styled';

import { FIELDS } from './form';

import { USER } from 'hooks/graphQL/queries';
import { useQuery } from '@apollo/react-hooks';

const ProductForm = ({
  register, setValue, categories, vendors, errors, t, productData, picture,
}) => {

  const { data: { role, id: userId } } = useQuery(USER);
  const isVendor = role === "vendor";
  const data = JSON.stringify(categories);
  const categoryName = JSON.parse(data)

  const [category, setCategory] = useState(categoryName[productData.categoryId]);

  const onSelect = (name, value) => {
      if(name === 'categoryId'){
        setCategory(categoryName[value])
      }
  };
  
  const getField = curry((register, errors, t, setValue, defaultValues, name, {
    type,
    step,
    required,
    values,
    width,
    min,
    max,
    multiline,
    defaultValue,
    hidden
  }) => {
    const props = {
      required,
      step,
      error: getFieldError(name, errors),
      register,
      name,
      min,
      max,
      multiline,
      label: t(`product.common.${name}`),
      defaultValue: defaultValue ?? defaultValues[name],
      hidden
    };
  
    return type === 'select'
      ? <Select {...props} values={values} setValue={setValue} onSelect={onSelect}/>
      : <TextInput type={type} {...props} width={width} />;
  });

  const field = getField(register, errors, t, setValue, productData);

  return (
    <>
      <ImageInput
        register={register}
        picture={picture}
        name={FIELDS.PICTURE}
        setValue={setValue}
        placeholder={ProductInputPlaceholder}
        width="224px"
        height="224px"
      />
      <ColumnInputs>
        {field(FIELDS.NAME, { required: true })}
        {field(FIELDS.DESCRIPTION,  { 
          required: true, 
          multiline: true
        })}
        {field(FIELDS.CATEGORY, {
          type: 'select',
          required: true,
          values: categories,
          onSelect: onSelect,
        })}
        {field(FIELDS.VENDOR, isVendor ? {
          defaultValue: userId,
          hidden: true
        } : {
          type: 'select',
          required: true,
          values: vendors,
        })}
        <RowInputs>
          <PriceContainer>
            {field(FIELDS.PRICE, { type: 'number', step: 0.01, required: true })}
          </PriceContainer>
          <RowContainer>
            {field(FIELDS.CURRENCY, {
              type: 'select',
              values: CURRENCIES,
            })}
          </RowContainer>
        </RowInputs>
        {field(FIELDS.VAT, { type: 'number', step: 1 })}
        <StyledFormHeadline>
          {t('product.common.promotion')}
        </StyledFormHeadline>
        <RowInputs>
          <PriceContainer>
            {field(FIELDS.DISCOUNT_PRICE, { type: 'number', min: 0, step: 0.01 })}
          </PriceContainer>
          <RowContainer>
            {field(FIELDS.DISCOUNT_TIME, { type: 'number', min: 0, step: 1 })}
          </RowContainer>
        </RowInputs>
        <StyledFormHeadline>
          {t('product.common.dimension')}
        </StyledFormHeadline>
        <RowInputs>
          <RowContainer>
            {field(FIELDS.HEIGHT, { type: 'number', required: true })}
          </RowContainer>
          <RowContainer>
            {field(FIELDS.WIDTH, { type: 'number', required: true })}
          </RowContainer>
          <RowContainer>
            {field(FIELDS.DEPTH, { type: 'number', required: true })}
          </RowContainer>
        </RowInputs>
        <RowInputs>
          <PackageContainer>
            {field(FIELDS.PACKAGE, { required: true })}
          </PackageContainer>
          <WeightContainer>
            {field(FIELDS.WEIGHT, { type: 'number', min: 0, step: 0.001, required: true })}
          </WeightContainer>
          <UnitContainer>
            {field(FIELDS.UNIT, {
              required: true,
              type: 'select',
              values: getUnits(t),
            })}
          </UnitContainer>
        </RowInputs>
        <MultipleSelect
          required
          error={getFieldError(FIELDS.SLOT_TYPES, errors)}
          values={getSlotTypes(t)}
          name={FIELDS.SLOT_TYPES}
          label={t('product.common.slotTypes')}
          defaultValue={productData[FIELDS.SLOT_TYPES]}
          register={register}
          setValue={setValue}
        />
        {field(FIELDS.DROP_HEIGHT, {
          type: 'select',
          required: true,
          values: getDropHeights(t),
        })}
        <StyledFormHeadline>
          {t('product.common.details')}
        </StyledFormHeadline>
        {category !== 'non-food' ? (
          <>
            {field(FIELDS.SHELFLIFE, { type: 'number', required: true })}
            {field(FIELDS.BARCODE, {})}
            {field(FIELDS.STORAGE, {})}
            {field(FIELDS.QUALITY, {})}
            {field(FIELDS.PRODUCED, {})}
            {field(FIELDS.INGREDIENTS, {})}
            {field(FIELDS.PROPERTIES, {})}
            {field(FIELDS.NUTRITIONAL, {})}
            {field(FIELDS.ALLERGENS, { multiline: true })}
            {field(FIELDS.BIOLOGICAL, {})}
            <CustomInputs register={register} defaultValues={productData.additionalFields} />
          </>
        ) : (
          <>
            {field(FIELDS.BARCODE, {})}
            <CustomInputs register={register} defaultValues={productData.additionalFields} />
          </>
        )}
      </ColumnInputs>
    </>
  );
};

ProductForm.propTypes = {
  categories: shape({}),
  vendors: shape({}),
  errors: shape({}),
  t: func.isRequired,
  register: func.isRequired,
  setValue: func.isRequired,
  picture: string,
  productData: shape({}),
};

ProductForm.defaultProps = {
  categories: {},
  vendors: {},
  errors: {},
  productData: {
    category: {},
    vendor: {},
  },
  picture: '',
};

export default ProductForm;
