import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useCallback, useState } from 'react';
import { noop, get } from 'lodash/fp';

import { useProducts, useSnackBar, useProductAssignments } from 'hooks';
import { onFormError } from 'utils';

import { getConfig } from './tableConfig';
import { ASSIGN_PRODUCT } from './queries';

export const useProductAssignment = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const { productAssignments, loadingProductAssignments, productAssignmentsError } = useProductAssignments();
  const { products, loadingProducts, productsError } = useProducts();
  const [assignedSlots, setAssignedSlots] = useState([]);

  const [assignProduct, { loading: assignLoading }] = useMutation(ASSIGN_PRODUCT, {
    onCompleted: (data) => {
      const slotId = get(['newAssignProductIntoSlot', 'bookedSlot', 'id'], data);
      setAssignedSlots([...assignedSlots, slotId]);
    },
    onError: onFormError(t, noop, openBar),
  });

  const resetComplete = () => setAssignedSlots([]);

  const onSubmit = useCallback((productId, slotId) => assignProduct({
    variables: {
      input: {
        productId,
        slotId,
      },
    },
  }), [assignProduct]);

  return {
    t,
    loading: loadingProducts || loadingProductAssignments,
    data: productAssignments,
    onSubmit,
    config: getConfig(t, products, onSubmit, resetComplete, assignedSlots, assignLoading),
    errorCode: productsError || productAssignmentsError,
  };
};
