import React from 'react';
import {
  arrayOf, shape, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map, get } from 'lodash/fp';
import { Link } from 'react-router-dom';

import { ROUTES } from 'configs';
import {
  Button,
  Tabs,
  HeaderContainer,
  Container,
  PrivateComponent,
} from 'components';
import { firstPagination } from './ProductTable/pagination';
import ProductTable from './ProductTable';

import FilterDialog from './FilterDialog';

var vendor = null;

const ProductList = ({ categories }) => {
  const { t } = useTranslation();

  const [open, setOpenDialog] = React.useState(false);
  const pagination = firstPagination();

  const onSelectVendor = () => {           
    setOpenDialog(true);  
  }

  const onOkButton = ( selectedVendor ) => {    
    vendor = selectedVendor;
    setOpenDialog(false);  
  }

  const onCancelButton = () => {           
    setOpenDialog(false);  
  }

  return (
    <Container maxWidth="1312px">
      <HeaderContainer>
        <h1>{t('product.list.title')}</h1>
        <PrivateComponent roles={['admin']} >
          <Button onClick={onSelectVendor}>
            {t('product.list.filterVendors')}
          </Button>
        </PrivateComponent>
        <PrivateComponent roles={['admin', 'vendor']} availableGroups={['advancedVendor']}>
          <Button
            component={Link}
            to={`${ROUTES.PRODUCTS}/new`}
          >
            {t('product.list.addProduct')}
          </Button>
        </PrivateComponent>
      </HeaderContainer>
      <PrivateComponent roles={['admin']} >
        <FilterDialog key="vendor-dialog" open={open} onOkButton={onOkButton} onCancelButton={onCancelButton}/>
      </PrivateComponent>

      <Tabs
        labels={map(get(['name']), categories)}
        displayType="destroy"
        initIndex={categories.findIndex((category) => (category.id === pagination.categoryId))}
      >
        {map(({ id, name }) => (
          <ProductTable key={name} categoryId={id} vendorId={vendor}/>
        ), categories)}   
      </Tabs>
    </Container>
  );
};

ProductList.propTypes = {
  categories: arrayOf(shape({
    name: string,
  })).isRequired,
};


export default ProductList;
