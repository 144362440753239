import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.palette.lightGrayBlue}`};
  padding: 38px 224px 53px 192px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
`;

export const StyledHeader = styled.h1`
   margin: 13px 0 42px 0;
`;

export const RowContainer = styled.div`
  width: 30%;
`;

export const PriceContainer = styled.div`
  width: 67%;
`;

export const PackageContainer = styled.div`
  width: 45%;
`;

export const WeightContainer = styled.div`
  width: 35%;
`;

export const UnitContainer = styled.div`
  width: 15%;
`;
