import React, { useState } from 'react';
import {
  arrayOf, bool, node, string, oneOf, number,
} from 'prop-types';
import TabPanel from './TabPanel';
import {
  TabsContainer,
  StyledTab,
  StyledTabs,
  WrappedTab,
} from './styled';

const Tabs = ({
  labels, children, withBorder, displayType, initIndex,
}) => {
  let initValue = initIndex;
  if ((initValue === undefined) || (initValue === null) || (initValue > labels.length)) {
    initValue = 0;
  }

  const [value, setValue] = useState(initValue);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <TabsContainer>
      <StyledTabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
      >
        {labels.map((tab) => (withBorder
          ? <WrappedTab key={tab} label={tab} />
          : <StyledTab key={tab} label={tab} />))}
      </StyledTabs>
      {children.map((content, index) => (
        <TabPanel
          withBorder={withBorder}
          key={labels[index]}
          index={index}
          value={value}
          displayType={displayType}
        >
          {content}
        </TabPanel>
      ))}
    </TabsContainer>
  );
};

Tabs.propTypes = {
  labels: arrayOf(string),
  children: arrayOf(node).isRequired,
  withBorder: bool,
  displayType: oneOf(['hide', 'destroy']),
  initIndex: number,
};

Tabs.defaultProps = {
  labels: [],
  withBorder: false,
  displayType: 'hide',
  initIndex: 0,
};
export default Tabs;
