import jwtDecode from 'jwt-decode';
import { pick } from 'lodash/fp';

export const getTokenData = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const decoded = jwtDecode(token);

    const { profile, role, _id, availableGroups } = pick(['profile', 'role', '_id', 'availableGroups'], decoded);
    const { firstName, lastName, companyName } = pick(['firstName', 'lastName', 'companyName'], profile);

    return {
      token,
      firstName: firstName || '',
      lastName: lastName || '',
      companyName: companyName || '',
      role,
      id: _id || '',
      availableGroups,
    };
  }
  return {};
};
