import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { curry } from 'lodash/fp';

import { ROUTES, NESTED_ROUTES, roleAccess } from 'configs';

import {
  NavigationContainer,
  StyledNavLink,
} from './styled';

import { isLinkActive } from './util';

const getNavLink = curry((t, route) => {
  switch (route) {
    case ROUTES.PRODUCTS:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.PRODUCTS)}
          to={ROUTES.PRODUCTS}
          key={ROUTES.PRODUCTS}
        >
          {t('navigation.products')}
        </StyledNavLink>
      );
    case ROUTES.SLOT_BOOKING:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.SLOT_BOOKING)}
          to={ROUTES.SLOT_BOOKING}
          key={ROUTES.SLOT_BOOKING}
        >
          {t('navigation.slotBooking')}
        </StyledNavLink>
      );
      
    case ROUTES.SLOTS:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.SLOTS)}
          to={`${ROUTES.SLOTS}/${NESTED_ROUTES.PER_PRODUCT}`}
          key={ROUTES.SLOTS}
        >
          {t('navigation.slots')}
        </StyledNavLink>
      );

    case ROUTES.STOCK:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.STOCK)}
          to={`${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}`}
          key={ROUTES.STOCK}
        >
          {t('navigation.stock')}
        </StyledNavLink>
      );
    case ROUTES.VENDORS:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.VENDORS)}
          to={ROUTES.VENDORS}
          key={ROUTES.VENDORS}
        >
          {t('navigation.vendors')}
        </StyledNavLink>
      );
      /*
    case ROUTES.REFILL:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.REFILL)}
          to={ROUTES.REFILL}
          key={ROUTES.REFILL}
        >
          {t('navigation.refill')}
        </StyledNavLink>
      );*/
    case ROUTES.PRODUCT_ASSIGNMENT:
      return (
        <StyledNavLink
          isActive={isLinkActive(ROUTES.PRODUCT_ASSIGNMENT)}
          to={ROUTES.PRODUCT_ASSIGNMENT}
          key={ROUTES.PRODUCT_ASSIGNMENT}
        >
          {t('navigation.productAssignment')}
        </StyledNavLink>
      );
      case ROUTES.SALES:
        return (
          <StyledNavLink
            isActive={isLinkActive(ROUTES.SALES)}
            to={ROUTES.SALES}
            key={ROUTES.SALES}
          >
            {t('navigation.sales')}
          </StyledNavLink>
        );
      case ROUTES.MACHINE:
        return (
          <StyledNavLink
            isActive={isLinkActive(ROUTES.MACHINE)}
            to={ROUTES.MACHINE}
            key={ROUTES.MACHINE}
          >
            {t('navigation.machine')}
          </StyledNavLink>
        );  
      case ROUTES.HISTORY:
          return (
            <StyledNavLink
              isActive={isLinkActive(ROUTES.HISTORY)}
              to={ROUTES.HISTORY}
              key={ROUTES.HISTORY}
            >
              {t('navigation.history')}
            </StyledNavLink>
          );
          case ROUTES.MACHINE_STATUS:
            return (
              <StyledNavLink
                isActive={isLinkActive(ROUTES.MACHINE_STATUS)}
                to={ROUTES.MACHINE_STATUS}
                key={ROUTES.MACHINE_STATUS}
              >
                {t('navigation.machineStatus')}
              </StyledNavLink>
            );
  
          default:
      return null;
  }
});

const NavigationBar = ({ role }) => {
  const { navigationRoutes } = roleAccess[role];
  const { t } = useTranslation();

  const navLink = getNavLink(t);

  return (
    <NavigationContainer>
      {navigationRoutes.map(navLink)}
    </NavigationContainer>
  );
};

NavigationBar.propTypes = {
  role: string.isRequired,
};

export default NavigationBar;
