import { CELL_TYPE_INPUT, CELL_TYPE_HISTORY } from 'components/Table/constants';
import { get } from 'lodash/fp';
import {
  isActualQuantityVisible,
  isRefillQuantityVisible,
  isActualQuantityDisabled,
} from './utils';

export const getConfig = (t, register) => ({
  register,
  headers: [
    {
      id: 'numberId',
      label: t('stock.common.slot'),
      key: 'numberId',
    },
    {
      id: 'vendor',
      label: t('stock.common.vendor'),
      key: ['vendor', 'companyName'],
      prevKey: ['systemRequest', 'vendor', 'companyName'],
      defaultValue: t('common.placeholders.vendor'),
      type: CELL_TYPE_HISTORY,
    },
    {
      id: 'product',
      label: t('slotsList.table.product'),
      defaultValue: t('common.placeholders.product'),
      key: (header, row) => {
        const actualProduct = get(['product', 'name'], row);
        const refillType = get(['refill', 'type'], row);

        const isSystemRequest = !!get(['systemRequest'], row);

        if (refillType === 'remove' || isSystemRequest) return t('common.remove');
        if (!refillType) return actualProduct;

        return get(['refill', 'product', 'name'], row);
      },
      prevKey: (row) => (get(['systemRequest'], row) ? get(['systemRequest', 'product', 'name'], row) : get(['product', 'name'], row)),
      type: CELL_TYPE_HISTORY,
    },
    {
      id: 'actualQty',
      label: t('stock.common.actualQuantity'),
      key: (header, row) => (get(['systemRequest'], row) ? get(['systemRequest', 'qty'], row) : get(['productItems', 'length'], row)),
      type: CELL_TYPE_INPUT,
      isDisabled: (row) => isActualQuantityDisabled(row),
      isVisible: (row) => isActualQuantityVisible(row),
    },
    {
      id: 'refillQty',
      label: t('stock.common.refillQuantity'),
      key: ['refill', 'qty'],
      type: CELL_TYPE_INPUT,
      isVisible: (row) => isRefillQuantityVisible(row),
    },
  ],
});
