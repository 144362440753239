import React from 'react';
import {
  string, bool, element,
} from 'prop-types';
import { useInput } from './hook';

import {
  BaseInput, BaseInputMultiline, StyledLabel, StyledFormControl, StyledFormControlMultiline, StyledError, OutlineInput, OutlineFormContainer,
} from './styled';

const getFormContent = (children, label, name, required, errorText) => (
  <>
    {children}
    {label && <StyledLabel id={name} htmlFor={name}>{`${label}${required ? ' *' : ''}`}</StyledLabel>}
    {errorText && (<StyledError>{errorText}</StyledError>)}
  </>
);

const FormElementContainer = ({
  error, label, errorText, children, required, name, variant, disabled, hidden
}) => {
  const formContent = getFormContent(children, label, name, required, errorText);

  const content = () => (
    variant === 'outlined' ? 
      (
        <OutlineFormContainer error={error} disabled={disabled}>
          {formContent}
        </OutlineFormContainer>
      ) : children.props.multiline !== undefined && children.props.multiline === true ? (
        <StyledFormControlMultiline error={error} disabled={disabled} margin="normal">
          {formContent}
        </StyledFormControlMultiline>
      ) : (
        <StyledFormControl error={error} disabled={disabled}>
          {formContent}
        </StyledFormControl>
      )
  );

  return hidden ? (<span hidden={hidden}>
    {content()} 
  </span>) : (content())
};

FormElementContainer.propTypes = {
  error: bool,
  disabled: bool,
  label: string,
  errorText: string,
  children: element,
  required: bool,
  name: string.isRequired,
  variant: string,
};

FormElementContainer.defaultProps = {
  error: false,
  disabled: false,
  label: '',
  errorText: '',
  children: null,
  required: false,
  variant: 'standard',
};

export { BaseInput, BaseInputMultiline, OutlineInput, useInput };
export default FormElementContainer;
