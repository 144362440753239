import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { ROUTES } from 'configs';

import ViewVendorContainer from './View';
import ListContainer from './List';
import VendorEditContainer from './Edit/edit';
import CreateVendorContainer from './Edit/create';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.VENDORS}/new`}>
      <CreateVendorContainer />
    </Route>
    <Route path={`${ROUTES.VENDORS}/:id/edit`}>
      {(props) => (
        <VendorEditContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.VENDORS}/:id`}>
      {(props) => (
        <ViewVendorContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.VENDORS}/`}>
      <ListContainer />
    </Route>
  </Switch>
);

export default Router;
