export const CELL_TYPE_HIGHLIGHTED = 'CELL_TYPE_HIGHLIGHTED';
export const CELL_TYPE_ARROW = 'CELL_TYPE_ARROW';
export const CELL_TYPE_DISABLED = 'CELL_TYPE_DISABLED';
export const CELL_TYPE_IMAGE = 'CELL_TYPE_IMAGE';
export const CELL_TYPE_INPUT = 'CELL_TYPE_INPUT';
export const CELL_TYPE_SELECT = 'CELL_TYPE_SELECT';
export const CELL_TYPE_HISTORY = 'CELL_TYPE_HISTORY';
export const CELL_TYPE_TRANSLATION = 'CELL_TYPE_TRANSLATION';
export const CELL_TYPE_DATE = 'CELL_TYPE_DATE';
export const CELL_TYPE_ASSIGN = 'CELL_TYPE_ASSIGN';
export const CELL_TYPE_DIVIDE = 'CELL_TYPE_DIVIDE';
export const CELL_TYPE_STATUS = 'CELL_TYPE_STATUS';
