import React, { Fragment } from 'react';
import { map } from 'lodash/fp';
import { arrayOf, shape, func } from 'prop-types';

import {
  ContentBox,
  Table,
  FooterSubmitControlls,
  StyledForm,
  DateTimePicker,
  BackLink,
  Label,
  DateTimePickerContainer,
  StyledBackLinkHeaderContainer,
  InfoContainer,
  HeadContainer,
} from 'components';

import { ROUTES } from 'configs';

import {
  StyledTableContainer,
  StyledHeadInfo,
} from './styled';

const CreateRequest = ({
  data, config, t, onSubmit, register, setValue,
}) => (
  <>
    <BackLink to={ROUTES.REFILL} />
    <ContentBox>
      <StyledBackLinkHeaderContainer>
        <h1>{t('vendorRefill.edit.title')}</h1>
      </StyledBackLinkHeaderContainer>
    </ContentBox>
    <StyledForm onSubmit={onSubmit}>
      {map(({
        product, vendingMachines, overallCapacity,
        overallQuantity, emptyPlaces, overallEjectedSinceLastRefill,
      }) => (
        <Fragment
          key={product.id}
        >
          <ContentBox>
            <h2>{product.name}</h2>
            <HeadContainer>
              <InfoContainer>
                <StyledHeadInfo
                  title={t('vendorRefill.edit.overallQuantity')}
                  value={overallQuantity}
                />
                <StyledHeadInfo
                  title={t('vendorRefill.edit.overallCapacity')}
                  value={overallCapacity}
                />
                <StyledHeadInfo
                  title={t('vendorRefill.edit.emptyPlaces')}
                  value={emptyPlaces}
                />
                <StyledHeadInfo
                  title={t('vendorRefill.edit.overallEjectedSinceLastRefill')}
                  value={overallEjectedSinceLastRefill}
                />
              </InfoContainer>
              <DateTimePickerContainer>
                <Label>{t('vendorRefill.expiryDate')}</Label>
                <DateTimePicker
                  defaultValue={vendingMachines[0].refillRequest.expDate || new Date(Date.now()).setHours(23, 59, 0)}
                  register={register}
                  setValue={setValue}
                  name={`date.${product.id}`}
                />
              </DateTimePickerContainer>
            </HeadContainer>
          </ContentBox>
          <StyledTableContainer>
            <Table
              config={config}
              data={vendingMachines}
            />
          </StyledTableContainer>
        </Fragment>
      ), data)}
      <FooterSubmitControlls returnLink={ROUTES.REFILL} />
    </StyledForm>
  </>
);

CreateRequest.propTypes = {
  data: arrayOf(shape({})),
  config: shape({}).isRequired,
  t: func.isRequired,
  register: func.isRequired,
  setValue: func.isRequired,
  onSubmit: func.isRequired,
};

CreateRequest.defaultProps = {
  data: [],
};

export default CreateRequest;
