import styled from 'styled-components';


export const ListContainer = styled.div`
  width: 1320px;
`;

export const HeaderContainer = styled.div`
  margin-left: 0px;
  margin-top: 12px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;


