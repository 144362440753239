import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

export const StyledSelect = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: theme.palette.grayBlue,
    textTransform: 'uppercase',
    '&:focus': { backgroundColor: 'transparent' },
    marginRight: '15px',
  },
  icon: {
    display: 'none',
  },
}))(Select);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: '15px',
    textTransform: 'uppercase',
    color: theme.palette.lynch,
    '&:hover': { color: theme.palette.greenBlueDarkened },
  },
}))(MenuItem);
