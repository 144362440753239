import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: ${({ width }) => width || '162px'};
  height: ${({ height }) => height || '162px'};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid ${({ theme, isError }) => (isError ? theme.palette.outrageousOrange : theme.palette.lightGrayBlue)};
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme, isError }) => (isError ? theme.palette.outrageousOrange : theme.palette.lightGrayBlue)};
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
