import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { get } from 'lodash/fp';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import MainRouter from './MainRouter';
import AuthRouter from './AuthRouter';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    token @client(always: true)
    firstName @client(always: true)
    lastName @client(always: true)
    role @client(always: true)
  }
`;

const Router = () => {
  const { data } = useQuery(IS_LOGGED_IN);

  return (
    <BrowserRouter>
      {get('token', data) ? (
        <MainRouter
          firstName={data.firstName}
          lastName={data.lastName}
          role={data.role}
        />
      ) : <AuthRouter />}
    </BrowserRouter>
  );
};

export default Router;
