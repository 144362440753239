import { gql } from 'apollo-boost';

export const USER = gql`
  query IsUserLoggedIn {
    role @client(always: true)
    id @client(always: true)
  }
`;

export const VENDORS_NAMES_LIST = gql`
  query Accounts($roles: [accountRole] = [vendor],  $first: ConnectionLimitInt = 9999) {
    accounts(roles:$roles, first:$first) {
      nodes {
        id: _id
        companyName
      }
    }
  }
`;

export const VENDING_MACHINE_LIST = gql`
  query VendingMachines {
    vendingMachines {
      nodes {
        id: _id
        machineId
        name
        address
        lastRefillUpdate
        stagedSlots {
          id: _id,
          soldFromLastRefill
          numberId
          slotParams {
            capacity
            slotType
          }
          vendor {
            id: _id
            companyName
          }
          product {
            id: _id
            name
          }
          productItems {
            id: _id
          }
        }
      }
    }
  }
`;

export const PRODUCT_TYPE_LIST = gql`  
  query Products($vendorId: ID = null) {
    products(vendorId: $vendorId) {
      nodes {
        id: _id
        name
        slotTypes
        variants {
          id: _id
        }
      }
    }
  }
`;

export const OWN_REFILL_REQUEST = gql`
{
  ownRefillRequest{
    id: _id
    status
    vendingMachines{
      id: _id
      name
      address
      machineId
      slots{
        id: _id
        soldFromLastRefill
        numberId
        productItems{
          id: _id
        }
        product{
          id: _id
          name
        }
        refill{
          qty
          type
          product{
            id: _id
            name
          }
        }
      }
    }
  }
}`;

export const VENDING_MACHINE = gql`
  query vendingMachine ($input: VendingMachineInput!){
    vendingMachine(input: $input) {
      id: _id
      machineId
      name
      address
      lastRefillUpdate
      stagedSlots {
        id: _id
        numberId
        vendor {
          id: _id
          companyName
        }
        product {
          id: _id
          name
        }
        productItems {
          id: _id
        }
        systemRequest {
          _id
          type
          status
          vendor {
            id: _id
            companyName
          }
          product {
            id: _id
            name
          }
          qty
          }
        refill {
          qty
          type
          status
          product {
            id: _id
            name
          }
        }
      }
      slots {
          id: _id
          numberId
          vendor {
              id: _id
              companyName
          }
          product {
              id: _id
              name
          }
          productItems {
              id: _id
          }
          systemRequest {
              _id
              type
              status
              vendor {
                  id: _id
                  companyName
              }
              product {
                  id: _id
                  name
              }
              qty
          }
          refill {
              qty
              type
              status
              product {
                  id: _id
                  name
              }
          }
      }
    }
  }
`;

export const STOCK_OVERVIEW = gql`
  query stockOverview {
    stockOverview {
      nodes {
        id: _id
        overallCapacity
        overallQuantity
        overallEjectedSinceLastRefill
        emptyPlaces
        product {
          id: _id
          name
        }
        vendingMachines {
          id: _id
          address
          capacity
          actualQuantity
          ejectedSinceLastRefill
          name
          machineId
        }
      }
    }
  }
`;

export const STOCK_OVERVIEW_PER_MACHINE = gql`
query stockOverviewPerMachine {
  stockOverviewPerMachine {
    nodes {
      id: _id
      overallCapacity
      overallQuantity
      overallEjectedSinceLastRefill
      emptyPlaces
      products {
        id: _id
        name
        actualQuantity
        capacity
        ejectedSinceLastRefill          
      }
      vendingMachine {
        id: _id
        address
        machineId
        name
      }
    }
  }
}
`;

export const REFILL_REQUEST_VIEW = gql`
  query refillRequestView {
    refillRequestView {
      refillRequest {
        id: _id
        status
      }
      nodes {
        id: _id
        overallCapacity
        overallQuantity
        overallEjectedSinceLastRefill
        emptyPlaces
        product {
          id: _id
          name
        }
        vendingMachines {
          id: _id
          address
          capacity
          actualQuantity
          ejectedSinceLastRefill
          refillRequest {
            qty
            expDate
          }
          name
          machineId
        }
      }
    }
  }
`;

export const PRODUCT_ASSIGNMENTS = gql`
  query {
  productAssignments {
    nodes {
      _id
      machineId
      name
      address
      slots {
        id:_id
        numberId
        slotParams {
          slotType
          height
          width
          depth
          capacity
        }
        active {
          id:_id
          bookedSlot
          productItems
          productId
          product {
            name
          }
          vendorId
          vendor {
            companyName
          }
        }
        staged {
          id:_id
          productId
          product {
            name
          }
          vendorId
          vendor {
            companyName
          }
        }
        planned {
          id:_id
          productId
          product {
            name
          }
          vendorId
          vendor {
            companyName
          }
        }
      }
    }
  }
} 
`;

