import React from 'react';
import {
  func, node, string, bool,
} from 'prop-types';
import { noop } from 'lodash/fp';
import { useForm } from 'react-hook-form';

import { TextInput } from 'components';

import ConfirmDialog from './ConfirmDialog';

const PromptDialog = ({
  label,
  onConfirm,
  defaultValue,
  ...props
}) => {
  const { register, handleSubmit } = useForm({ defaultValues: { prompt: defaultValue } });

  const handleSubmittion = (data) => {
    onConfirm(data.prompt);
  };

  return (
    <ConfirmDialog {...props} onConfirm={handleSubmit(handleSubmittion)}>
      <TextInput
        name="prompt"
        label={label}
        register={register}
      />
    </ConfirmDialog>
  );
};

PromptDialog.propTypes = {
  label: string,
  defaultValue: string,
  open: bool,
  confirmButtonText: string,
  cancelButtonText: string,
  title: string,
  children: node,
  onConfirm: func,
  onCancel: func,
};

PromptDialog.defaultProps = {
  label: '',
  defaultValue: '',
  open: false,
  confirmButtonText: 'Agree',
  cancelButtonText: 'Cancel',
  title: 'Are you sure?',
  children: null,
  onConfirm: noop,
  onCancel: noop,
};

export default PromptDialog;
