import React from 'react';
import { shape } from 'prop-types';
import { get } from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';

import { ROUTES } from 'configs';

import { CreatePasswordForm } from 'components';

import { onFormError } from 'utils';
import { useSnackBar } from 'hooks';

import { CREATE_NEW_PASSWORD } from './queries';

yup.addMethod(yup.string, 'equalsToPassword', function equalsToPassword() {
  return this.test('equalsToPassword', function eq(value) {
    return value === this.parent.password;
  });
});

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  'confirm-password': yup.string().required().equalsToPassword(),
});

const CreatePassword = ({ match }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const {
    register, handleSubmit, errors, setError,
  } = useForm({ validationSchema });

  const [sendNewPassword] = useMutation(
    CREATE_NEW_PASSWORD,
    {
      onCompleted: () => {
        history.push(`${ROUTES.AUTH}`);
        openBar(t('auth.password-changed-success'));
      },
      onError: onFormError(t, setError, openBar),
    },
  );

  const handleSubmittion = ({ password }) => {
    const token = get(['params', 'token'], match);
    sendNewPassword({ variables: { input: { token, password } } });
  };

  return (
    <CreatePasswordForm
      handleSubmit={handleSubmit(handleSubmittion)}
      register={register}
      errors={errors}
    />
  );
};

CreatePassword.propTypes = {
  match: shape({}).isRequired,
};

export default CreatePassword;
