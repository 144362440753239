import React from 'react';
import {
  node, shape, oneOfType, func,
} from 'prop-types';
import { get, noop, isFunction } from 'lodash/fp';

import { ImageBox } from 'components';

import {
  getPictureUrl,
  getDate,
  getKeyByValue,
  getProductNames,
  getProductsBySlotType,
} from 'utils';
import { ImagePlaceholder } from 'static/images';
import {
  StyledTableCell,
  StyledImageCell,
  StyledInputCell,
} from '../styled';

import { parseContent } from '../util';
import { headerType, rowType } from '../propTypes';

import InputCell from './Input';
import SelectCell from './Select';
import HistoryCell from './History';
import { DisabledCellValue, Placeholder, StyledArrowIcon } from './styled';

import {
  CELL_TYPE_HIGHLIGHTED, CELL_TYPE_ARROW, CELL_TYPE_DISABLED,
  CELL_TYPE_IMAGE, CELL_TYPE_INPUT, CELL_TYPE_SELECT,
  CELL_TYPE_HISTORY, CELL_TYPE_TRANSLATION, CELL_TYPE_DATE,
  CELL_TYPE_ASSIGN, CELL_TYPE_DIVIDE, CELL_TYPE_STATUS,
} from '../constants';
import AssignCell from './AssignInput';

const getCellByType = (header, row, children, register, setValue) => {
  switch (header.type) {
    case CELL_TYPE_HIGHLIGHTED:
      return (
        <StyledTableCell className="highlighted">
          {children}
        </StyledTableCell>
      );

    case CELL_TYPE_ARROW:
      return (
        <StyledTableCell className="highlighted">
          <StyledArrowIcon />
        </StyledTableCell>
      );

    case CELL_TYPE_DISABLED:
      return (
        <StyledTableCell>
          <DisabledCellValue isDisabled={header.isDisabled(row)}>
            {children}
          </DisabledCellValue>
        </StyledTableCell>
      );

    case CELL_TYPE_IMAGE:
      return (
        <StyledImageCell>
          <ImageBox width="56px" height="56px" borderRadius="2px" src={getPictureUrl(children)} placeholder={ImagePlaceholder} />
        </StyledImageCell>
      );

    case CELL_TYPE_INPUT:
      return (
        <StyledInputCell>
          <InputCell
            name={`${header.id}-${row.id}`}
            defaultValue={children ? parseContent(children) : '0'}
            register={register}
            isVisible={isFunction(header.isVisible) ? header.isVisible(row) : true}
            isDisabled={isFunction(header.isDisabled) ? header.isDisabled(row) : false}
            type={header.inputType ? header.inputType : 'text'}
            max={header.maxValue ? get(header.maxValue, row) : null}
          />
        </StyledInputCell>
      );

    case CELL_TYPE_SELECT:
      return (
        <StyledTableCell>
          <SelectCell name={`${header.id}-${row.id}`} values={header.values} value={children} register={register} setValue={setValue} />
        </StyledTableCell>
      );

    case CELL_TYPE_HISTORY:
      return (
        <StyledTableCell>
          <HistoryCell
            prevValue={isFunction(header.prevKey) ? header.prevKey(row) : get(header.prevKey, row)}
            value={children}
            defaultValue={header.defaultValue}
          />
        </StyledTableCell>
      );

    case CELL_TYPE_DIVIDE:
      return (
        <StyledTableCell>
          {parseContent(children)}
          {' / '}
          <Placeholder>{parseContent(get(header.secondKey, row))}</Placeholder>
        </StyledTableCell>
      );

    case CELL_TYPE_TRANSLATION:
      return (
        <StyledTableCell>
          {header.translations[children] || children}
        </StyledTableCell>
      );

    case CELL_TYPE_DATE:
      return (
        <StyledTableCell>
          {children ? getDate(children) : <Placeholder>{header.placeholder}</Placeholder>}
        </StyledTableCell>
      );

    case CELL_TYPE_ASSIGN:
      return (
        <StyledTableCell>
          <AssignCell
            name={`${header.id}-${row.id}`}
            values={{ ...getProductsBySlotType(header.values, row), not_assigned: 'Not Assigned' }}
            defaultValue={getKeyByValue(getProductNames(header.values), children) || ''}
            onSubmit={header.onSubmit}
            assignedSlots={header.assignedSlots}
            resetComplete={header.resetComplete}
            loading={header.assignLoading}
          />
        </StyledTableCell>
      );

    case CELL_TYPE_STATUS:
    {
      let statusClass = '';

      switch (children) {
        case 'disabled': {
          statusClass = 'disabled';
          break;
        }
        case 'operational': {
          statusClass = 'operational';
          break;
        }
        default:
          statusClass = 'notoperational';
      } 

      return (
        <StyledTableCell className={statusClass}>
          {children}
        </StyledTableCell>
      );
    }
    default:
      return (
        <StyledTableCell>
          {parseContent(children)
          || parseContent(header.defaultValue)
          || <Placeholder>{header.placeholder}</Placeholder>}
        </StyledTableCell>
      );
  }
};


const Cell = ({
  header, row, children, register, setValue,
}) => getCellByType(header, row, children, register, setValue);

Cell.propTypes = {
  header: headerType.isRequired,
  row: rowType.isRequired,
  children: oneOfType([node, shape({})]),
  register: func,

};

Cell.defaultProps = {
  children: null,
  register: noop,
};

export default Cell;
