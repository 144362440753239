import { ApolloClient } from 'apollo-client';
import ApolloBoostClient from 'apollo-boost';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { get, pick } from 'lodash/fp';
import jwtDecode from 'jwt-decode';

import { getTokenData } from 'utils/token';


const appCache = new InMemoryCache();
const baseUri = process.env.REACT_APP_NODE_ENV !== 'development' ? '/api' : `${process.env.REACT_APP_API_HOST}`;
const uri = `${baseUri}/graphql`

const resolvers = {
  Query: {
  },
  Mutation: {
    updateLoggedIn: ({ login }, args, { cache }) => {
      if (get(['token'], login)) {
        localStorage.setItem('token', login.token);
        const decoded = jwtDecode(login.token);
        const { profile, role, _id, availableGroups } = pick(['profile', 'role', '_id', 'availableGroups'], decoded);
        const { firstName, lastName, companyName } = pick(['firstName', 'lastName', 'companyName'], profile);
        cache.writeData({
          data: {
            token: login.token,
            firstName: firstName || '',
            lastName: lastName || '',
            companyName: companyName || '',
            role,
            id: _id,
            availableGroups,
          },
        });
      }
    },
    logout: (obj, args, { cache }) => {
      localStorage.removeItem('token');
      cache.writeData({
        data: {
          token: null,
          firstName: null,
          lastName: null,
          companyName: null,
          role: null,
          availableGroups: null,
        },
      });
    },
  },
};

export const uploadClient = new ApolloClient({
  link: createUploadLink({
    uri
  }),
  cache: appCache,
});

const client = new ApolloBoostClient({
  uri,
  cache: appCache,
  resolvers,
  request: (operation) => {
    const token = localStorage.getItem('token');
    operation.setContext({
      headers: {
        authorization: token || '',
      },
    });
  },
});

appCache.writeData({
  data: {
    ...getTokenData(),
  },
});

export default client;
