import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';

import { AppWrapper, Snackbar } from 'components';
import client from './apolloClient';
import Router from './router';

import { GlobalStyles } from './globalStyles';
import theme from './theme';
import i18n from './i18next';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <StyledThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <ApolloProvider client={client}>
            <I18nextProvider i18n={i18n}>
              <AppWrapper>
                <Snackbar />
                <Router />
              </AppWrapper>
            </I18nextProvider>
          </ApolloProvider>
        </StylesProvider>
      </StyledThemeProvider>
    </ThemeProvider>
  );
}

export default App;
