import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';

import { useSnackBar, useVendingMachine } from 'hooks';
import { onFormError } from 'utils';
import { ROUTES, NESTED_ROUTES } from 'configs';

import { UPDATE_VENDING_MACHINE } from '../queries';
import { getSlots } from './utils';

export const useEditVM = (id) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const history = useHistory();
  const [redirectUrl, setRedirectUrl] = useState(`${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}`);
  const { loadingVendingMachine, vendingMachineError, vendingMachine } = useVendingMachine(id);
  const {
    setValue, setError, handleSubmit, formState, ...formParams
  } = useForm();

  const [updateVM] = useMutation(UPDATE_VENDING_MACHINE, {
    onCompleted: () => history.push(redirectUrl),
    onError: onFormError(t, setError, openBar),
  });

  const onEditVM = useCallback((url) => handleSubmit((form) => {
    if (url) setRedirectUrl(url);
    updateVM({
      variables: {
        input: {
          vendingMachineId: id,
          slots: getSlots(form, vendingMachine),
        },
      },
    });
  }), [id, vendingMachine, handleSubmit, updateVM]);

  return {
    ...formParams,
    t,
    setValue,
    error: vendingMachineError,
    data: vendingMachine,
    loading: loadingVendingMachine,
    onEditVM,
    formState,
  };
};
