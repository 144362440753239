import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const getDefaultPaginationStyles = (theme) => ({
  fontSize: '12px',
  fontFamily: theme.typography.roboto,
  color: theme.palette.purpleBlue,
});

export const StyledTableCell = withStyles((theme) => ({
  head: {
    color: `${theme.palette.grayBlue} !important`, // rewrite root
    fontSize: '13px',
  },
  root: {
    fontWeight: 500,
    fontFamily: theme.typography.robotoMedium,
    color: theme.palette.purpleBlue,
    textAlign: 'left',
    verticalAlign: 'middle',
    padding: '15px',

    '&.highlighted': {
      color: theme.palette.greenBlueLightened,
    },
    '&.operational': {
      color: theme.palette.greenBlueLightened,
    },
    '&.notoperational': {
      color: theme.palette.outrageousOrange,
    },
    '&.disabled': {
      color: theme.palette.yellow,
    },
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

export const StyledImageCell = withStyles({
  root: {
    padding: '8px',
    textAlign: 'center',

    '& > div': {
      margin: '0 auto',
    },
  },
})(StyledTableCell);

export const StyledInputCell = withStyles({
  root: {
    padding: '6px 15px',
  },
})(StyledTableCell);

export const StyledTable = withStyles({
  root: {
    minWidth: 650,
    boxShadow: 'none',
    width: 'calc(100% - 32px)',
    margin: '0 auto',
  },
})(Table);

export const StyledTablePagination = withStyles((theme) => ({
  caption: getDefaultPaginationStyles(theme),
  input: getDefaultPaginationStyles(theme),
  menuItem: getDefaultPaginationStyles(theme),
  actions: {
    color: theme.palette.grayBlue,
  },
}))(TablePagination);

export const StyledTableContainer = withStyles({
  root: {
    textAlign: 'center',
  },
})(TableContainer);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&[data-highlighted = true]': {
      background: theme.palette.lightGray,
    },
    '&[data-haslink = true]': {
      '&:hover': {
        background: theme.palette.polar,
        cursor: 'pointer',

        '& .highlighted': {
          color: theme.palette.grayBlue,
        },
      },
    },
  },
}))(TableRow);
