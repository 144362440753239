import React, { useCallback } from 'react';
import { string } from 'prop-types';

import { ImagePlaceholder } from 'static/images';

import { ImageWrapper, StyledImage } from './styled';


const ImageBox = ({
  width, height, src, placeholder, errorMessage, borderRadius,
}) => {
  const handleImageError = useCallback((e) => {
    e.target.src = placeholder;
  }, [placeholder]);

  return (
    <ImageWrapper width={width} height={height} isError={errorMessage} borderRadius={borderRadius}>
      <StyledImage src={src || placeholder} onError={handleImageError} alt="ImageBox" />
    </ImageWrapper>
  );
};

ImageBox.propTypes = {
  src: string,
  placeholder: string,
  errorMessage: string,
  width: string,
  height: string,
  borderRadius: string,
};

ImageBox.defaultProps = {
  src: '',
  placeholder: ImagePlaceholder,
  errorMessage: '',
  width: '162px',
  height: '162px',
  borderRadius: '4px',
};

export default ImageBox;
