import React from 'react';
import { string } from 'prop-types';

import { FreshTableLogoIcon } from 'static/icons';

import {
  NavigationBar, ContentBox, LanguageSelector,
} from 'components';
import UserMenu from './UserMenu';

import { HeaderContainer, SideContent, StyledLigthWrapper } from './styled';

const Header = ({ firstName, lastName, role }) => (
  <StyledLigthWrapper>
    <ContentBox>
      <HeaderContainer>
        <FreshTableLogoIcon />
        <NavigationBar
          role={role}
        />
        <SideContent>
          <LanguageSelector />
          <UserMenu
            firstName={firstName}
            lastName={lastName}
            role={role}
          />
        </SideContent>
      </HeaderContainer>
    </ContentBox>
  </StyledLigthWrapper>
);

Header.propTypes = {
  firstName: string,
  lastName: string,
  role: string,
};

Header.defaultProps = {
  firstName: '',
  lastName: '',
  role: '',
};

export default Header;
