import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageWrapper, LightWrapper, ContentBox } from 'components';

const HomePage = ({ name }) => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <LightWrapper>
        <ContentBox>
          {name}
          Page Content
          <br />
          {t('hello')}
        </ContentBox>
      </LightWrapper>
    </PageWrapper>
  );
};

HomePage.defaultProps = {
  name: '',
};

HomePage.propTypes = {
  name: string,
};

export default HomePage;
