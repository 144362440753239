import { CELL_TYPE_ASSIGN, CELL_TYPE_TRANSLATION } from 'components/Table/constants';

export const getConfig = (t, products, onSubmit, resetComplete, assignedSlots, assignLoading) => ({
  headers: [
    {
      id: 'slotType',
      label: t('common.slotType'),
      key: ['slotParams', 'slotType'],
      translations: t('vendorAssign.slotTypes', { returnObjects: true }),
      type: CELL_TYPE_TRANSLATION,
    },
    {
      id: 'numberId',
      label: t('common.slotId'),
      key: ['numberId'],
      translations: t('vendorAssign.slotTypes', { returnObjects: true }),
      type: CELL_TYPE_TRANSLATION,
    },
    {
      id: 'product',
      label: t('common.product'),
      key: ['active', 'product', 'name'],
      translations: t('vendorAssign.slotTypes', { returnObjects: true }),
      type: CELL_TYPE_TRANSLATION,
    },
    {
      id: 'stagedProduct',
      label: t('common.stagedProduct'),
      key: ['staged', 'product', 'name'],
      translations: t('vendorAssign.slotTypes', { returnObjects: true }),
      type: CELL_TYPE_TRANSLATION,
    },
    {
      id: 'plannedProduct',
      label: t('common.plannedProduct'),
      key: ['planned', 'product' , 'name'],
      type: CELL_TYPE_ASSIGN,
      values: {
        ...products,
      },
      onSubmit,
      resetComplete,
      assignedSlots,
      assignLoading,
    },
    {
      id: 'capacity',
      label: t('common.capacity'),
      key: ['slotParams', 'capacity'],
    },
  ],
  collapse: {
    visibleNumber: 5,
    getCollapseText: () => t('slotsList.table.collapse'),
    getExpandText: (number) => t('slotsList.table.expand', { number }),
  },
});
