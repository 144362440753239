import styled from 'styled-components';

import { AngleLeftIcon } from 'static/icons';
import { Link } from 'components';

export const ReturnIcon = styled(AngleLeftIcon)`
  width: 10px;
  height: 16px;
  fill: ${({ theme }) => theme.palette.greenBlueLightened};
  margin-right: 6px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 15px;
  color: ${({ theme }) => theme.palette.purpleBlue};
`;

export const LinkContainer = styled.div`
  width: 100%;
  max-width: 1344px;
  display: flex;
  margin-top: 26px;
`;
