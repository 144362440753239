import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { ROUTES } from 'configs';

import ViewRefillRequest from './View';
import CreateRefillRequest from './Create/create';
import EditRefillRequest from './Create/edit';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.REFILL}/new`}>
      <CreateRefillRequest />
    </Route>
    <Route path={`${ROUTES.REFILL}/edit`}>
      <EditRefillRequest />
    </Route>
    <Route path={`${ROUTES.REFILL}`}>
      <ViewRefillRequest />
    </Route>
  </Switch>
);

export default Router;
