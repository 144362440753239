import React from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { convertGqlError } from 'utils';
import { Error, ProgressWrapper } from 'components';

import { getConfig } from './tableConfig';
import { VIEW_MACHINE } from '../queries';
import VendingMachineStatus from './VendingMachineStatus';

const ViewVendingMachineStatusContainer = ({ match }) => {
  const vmId = get(['params', 'id'], match);
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(VIEW_MACHINE, {
    variables: { vmId },
  });

  if (error) return <Error code={convertGqlError(error)} />;
  if (loading) return <ProgressWrapper />;

  const machineData = get(
    ['vendingMachineEvents', 'vendingMachineEvents'],
    data
  );

  const transMachineData = machineData.map((vmEvent) => {

    let alarms = null;
    let description = null

    if( vmEvent.alarms != null ){

      alarms = vmEvent.alarms.map((arr) => {
          return arr.id;
      });

      description = vmEvent.alarms.map((arr) => {
        return arr.description;
      });
    }

    return {
      ...vmEvent,
      alarms,
      description,
    };
  });

  const props = {
    config: getConfig(t),
    data: transMachineData,
    t,
  };
  return <VendingMachineStatus {...props} />;
};

ViewVendingMachineStatusContainer.propTypes = {
  match: shape({
    id: string,
  }).isRequired,
};

export default ViewVendingMachineStatusContainer;
