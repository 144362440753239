import { useQuery, useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { convertGqlError } from 'utils';

import { useTranslation } from 'react-i18next';
import { useSnackBar, useUser } from 'hooks';

import {
  REFILL_REQUESTS,
  READY_FOR_FETCH,
} from '../queries';


const useRefillRequests = () => {
  const { loading, error, data } = useQuery(REFILL_REQUESTS, { fetchPolicy: 'cache-and-network' });

  return {
    loading,
    error: error && convertGqlError(error),
    data,
  };
};

const useReadyForFetch = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const [requestReadyForFetch, { data, loading, error }] = useMutation(
    READY_FOR_FETCH,
    {
      onError: (gqlError) => {
        const { code } = convertGqlError(gqlError);
        openBar(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
      },
    },
  );

  return {
    handleReadyForFetchRequest: requestReadyForFetch, loading, data, error: convertGqlError(error),
  };
};

export const useRefillRequestList = () => {
  const {
    handleReadyForFetchRequest,
    data: requestListAfterFetch,
    loading: readyForFetchLoading,
    error: readyForFetchError,
  } = useReadyForFetch();

  const {
    data: requestList,
    loading: requestListLoading,
    error: requestListError,
  } = useRefillRequests();

  const { user: { role } } = useUser();

  return {
    data: get(['fetchRefillRequest', 'nodes'], requestListAfterFetch) || get(['refillRequests', 'nodes'], requestList),
    loading: readyForFetchLoading || requestListLoading,
    error: readyForFetchError || requestListError,
    handleReadyForFetchRequest,
    role,
  };
};
