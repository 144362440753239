import React, { useState, useEffect, useCallback } from 'react';
import { func, string, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop, isString } from 'lodash/fp';

import { ImageBox } from 'components';
import { invokeIfArgExists } from 'utils';

import { ImagePlaceholder } from 'static/images';

import {
  LabelWrapper,
  HiddenInput,
  ButtonsContainer,
  TextButton,
  TipMessage,
  Separator,
  ErrorMessage,
} from './styled';

const AVAILABLE_SIZE = 15360;
const AVAILABLE_TYPES = [
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
  'image/svg+xml',
];

const validImageType = (imageFile) => AVAILABLE_TYPES.includes(imageFile.type);
const validImageSize = (imageFile) => Math.round((imageFile.size / 1024)) < AVAILABLE_SIZE;

const getUrl = invokeIfArgExists((file) => (isString(file) ? file : URL.createObjectURL(file)));

const ImageInput = ({
  register,
  unregister,
  validation,
  setValue,
  name,
  picture,
  placeholder,
  width,
  height,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(picture);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    register({ name }, validation);
    return () => unregister(name);
  }, [register, unregister, name, validation]);

  useEffect(() => {
    setValue(name, image);
  }, [setValue, name, image]);

  const handleChange = (e) => {
    const imageFile = e.target.files[0];

    if (!imageFile) {
      return false;
    }

    if (!validImageType(imageFile) || !validImageSize(imageFile)) {
      setErrorMessage(t('imageInput.invalidFile'));
      return false;
    }

    setErrorMessage('');
    setImage(imageFile);
    return true;
  };

  const handleDelete = () => {
    setImage(null);
  };

  const preventExtraClicks = useCallback((e) => {
    const itemName = e.target.getAttribute('name');
    if (itemName !== 'upload') {
      e.preventDefault();
    }
  }, []);

  return (
    <LabelWrapper width={width}>
      <ImageBox
        src={getUrl(image)}
        errorMessage={errorMessage}
        placeholder={placeholder}
        width={width}
        height={height}
      />
      <HiddenInput
        type="file"
        name={name}
        onChange={handleChange}
        accept={AVAILABLE_TYPES.join(', ')}
      />
      <ButtonsContainer onClick={preventExtraClicks}>
        <TextButton name="upload">
          {t('common.upload')}
        </TextButton>
        <Separator>|</Separator>
        <TextButton onClick={handleDelete}>
          {t('common.delete')}
        </TextButton>
      </ButtonsContainer>
      <TipMessage>
        {t('imageInput.types')}
        <Separator>| </Separator>
        {t('imageInput.size')}
      </TipMessage>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </LabelWrapper>
  );
};

ImageInput.propTypes = {
  register: func,
  unregister: func,
  setValue: func,
  validation: shape({}),
  name: string.isRequired,
  picture: string,
  placeholder: string,
  width: string,
  height: string,
};

ImageInput.defaultProps = {
  register: noop,
  unregister: noop,
  setValue: noop,
  validation: {},
  picture: null,
  placeholder: ImagePlaceholder,
  width: '',
  height: '',
};

export default ImageInput;
