import styled from 'styled-components';


export const InfoContainer = styled.div`
  width: 528px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 26px;
  padding: 0 32px 0 32px;
`;

export const DescriptionContainer = styled.div`
  flex: 1;
`;

export const DescriptionTitle = styled.p`
  color: ${({ theme }) => theme.palette.purpleGray};
  margin: 0 0 15px 15px;
`;
