import * as yup from 'yup';
import { useForm } from 'react-hook-form';

export const FIELDS = {
  HEIGHT: 'height',
  WIDTH: 'width',
  DEPTH: 'depth',
  PACKAGE: 'package',
  CURRENCY: 'currency',
  PICTURE: 'picture',
  NAME: 'name',
  DESCRIPTION: 'description',
  CATEGORY: 'categoryId',
  VENDOR: 'vendorId',
  PRICE: 'price',
  VAT: 'vat',
  DISCOUNT_PRICE: 'discountPrice',
  DISCOUNT_TIME: 'discountTime',
  UNIT: 'unit',
  WEIGHT: 'weight',
  SHELFLIFE: 'shelfLife',
  BARCODE: 'barcode',
  STORAGE: 'storageRequirements',
  QUALITY: 'quality',
  PRODUCED: 'originCountry',
  INGREDIENTS: 'ingredientList',
  PROPERTIES: 'propertyList',
  NUTRITIONAL: 'nutritionalInfo',
  ALLERGENS: 'allergenList',
  BIOLOGICAL: 'biologicalSpecies',
  SLOT_TYPES: 'slotTypes',
  DROP_HEIGHT: 'dropHeight',
};

export const productTypeScheme = yup.object().shape({
  [FIELDS.NAME]: yup.string().required().max(1000).trim(),
  [FIELDS.DESCRIPTION]: yup.string().required().trim(),
  [FIELDS.CATEGORY]: yup.string().required().trim(),
  [FIELDS.VENDOR]: yup.string().required().trim(),
  [FIELDS.PACKAGE]: yup.string().required().trim(),
  [FIELDS.PRICE]: yup.number().required().positive(),
  [FIELDS.HEIGHT]: yup.number().required().positive(),
  [FIELDS.WIDTH]: yup.number().required().positive(),
  [FIELDS.DEPTH]: yup.number().required().positive(),
  [FIELDS.WEIGHT]: yup.number(),
  [FIELDS.SHELFLIFE]: yup.number(),
  [FIELDS.BARCODE]: yup.string().trim(),
  [FIELDS.SLOT_TYPES]: yup.array().required(),
});

export const useProductTypeForm = (defaultValues) => useForm({
  validationSchema: productTypeScheme,
  defaultValues,
});
