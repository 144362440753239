import React from 'react';

import { useSnackBar } from 'hooks';
import { StyledSnackbar, StyledSnackbarContent, SnackBarButton } from './styled';


const Snackbar = () => {
  const {
    bar: {
      message, isOpen, type, confirmText,
    },
    hideBar,
  } = useSnackBar();

  return isOpen ? (
    <StyledSnackbar
      disableWindowBlurListener
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      open={isOpen}
      type={type}
      onClose={hideBar}
    >
      <StyledSnackbarContent
        message={message}
        type={type}
        action={(
          <SnackBarButton
            variant="text"
            color={type === 'alert' ? 'secondary' : 'primary'}
            width="65px"
            onClick={hideBar}
          >
            {confirmText || 'Ok'}
          </SnackBarButton>
      )}
      />
    </StyledSnackbar>
  ) : null;
};

export default Snackbar;
