import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Error, ProgressWrapper } from 'components';
import { convertGqlError } from 'utils';

import { get } from 'lodash/fp';
import { format, parseISO} from 'date-fns';

import { getConfig } from './tableConfig';
import { LIST_MACHINES } from '../queries';
import MachineStatusList from './List';

const MachineStatusListContainer = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(LIST_MACHINES, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;

  const { vendingMachines, vendingMachineEvents } = data;

  const eventMap = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const vmEvent of vendingMachineEvents.vendingMachineEvents) {
    const createdAt = format(parseISO(vmEvent.createdAt), 'dd/MM/yyyy HH:mm');
    if (!(vmEvent.vmId in eventMap)) {
      eventMap[vmEvent.vmId] = {
        status: vmEvent.event,
        details: `${t('machineStatus.occurredAt')} ${createdAt}`,
      };
    } else {
      eventMap[vmEvent.vmId].status += `, ${vmEvent.event}`;
      eventMap[vmEvent.vmId].details += `; ${createdAt}`;
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const vm of vendingMachines.nodes) {
    if (vm.id in eventMap) {
      vm.status = eventMap[vm.id].status;
      vm.details = eventMap[vm.id].details;
    } else {
      vm.status = 'operational';
      vm.details = '';
    }
  }

  const props = {
    config: getConfig(t),
    data: get(['vendingMachines', 'nodes'], data),
    t,
  };

  return <MachineStatusList {...props} />;
};

export default MachineStatusListContainer;
