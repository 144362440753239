import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { ROUTES } from 'configs';

import { PrivateRoute } from 'components';

import ViewProductContainer from './View';
import ListContainer from './List';
import ProductCreateContainer from './Edit/create';
import ProductEditContainer from './Edit/edit';

const Router = () => (
  <Switch>
    <PrivateRoute roles={['admin', 'vendor']} availableGroups={['advancedVendor']} path={`${ROUTES.PRODUCTS}/new`}>
      <ProductCreateContainer />
    </PrivateRoute>
    <PrivateRoute roles={['admin', 'vendor']} availableGroups={['advancedVendor']} path={`${ROUTES.PRODUCTS}/:id/edit`}>
      {(props) => (
        <ProductEditContainer {...props} />
      )}
    </PrivateRoute>
    <Route path={`${ROUTES.PRODUCTS}/:id`}>
      {(props) => (
        <ViewProductContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.PRODUCTS}/`}>
      <ListContainer />
    </Route>
  </Switch>
);

export default Router;
