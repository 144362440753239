import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { ROUTES } from 'configs';

import SalesAndRefillHistoryView from './View';

const Router = () => (
  <Switch>
    <Route path={ROUTES.HISTORY}>
      <SalesAndRefillHistoryView />
    </Route>
  </Switch>
);

export default Router;
