import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { get, map, keys } from 'lodash/fp';

import { Error } from 'components';

import { VENDORS_LIST } from '../queries';

import { getConfig } from './tableConfig';

import VendorList from './List';

const populateContactName = map((account) => ({
  ...account,
  contact: `${account.firstName} ${account.lastName}`,
}));

const ListContainer = () => {
  const { t } = useTranslation();
  const {
    loading, error, data, refetch,
  } = useQuery(VENDORS_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const handleSort = useCallback((sort) => {
    const sortBy = keys(sort)[0];
    const sortOrder = sort[sortBy];
    refetch({ sortBy, sortOrder });
  }, [refetch]);

  if (error) {
    return (
      <Error
        code={get(['graphQLErrors', '0', 'extensions', 'code'], error)}
      />
    );
  }

  const vendorsList = populateContactName(get(['accounts', 'nodes'], data));

  const props = {
    config: getConfig(t),
    data: loading ? [] : vendorsList,
    t,
  };

  return (<VendorList {...props} handleSort={handleSort} />);
};

export default ListContainer;
