import React, { useMemo } from 'react';
import { shape, func, bool } from 'prop-types';
import { noop, get } from 'lodash/fp';

import { ROUTES } from 'configs';

import {
  StyledForm,
  BackLink,
  ContentBox,
  BackLinkHeaderContainer,
  FooterSubmitControlls,
  Select,
  NoContentPlaceholder,
  InfoBlock,
} from 'components';

import {
  InputsWrapper, PlaceholderContainer, BookingConteiner, InfoContainer, InputCaption,
} from './styled';

const EditSlot = ({
  t, vendors, data, register, setValue, handleSubmit, bookingDisabled,
}) => {
  const product = get(['product', 'name'], data);

  const demension = useMemo(() => {
    const { width = '', height = '', depth = '' } = data.slotParams || {};

    return `${height} × ${width} × ${depth} mm`;
  }, [data]);

  return (
    <StyledForm onSubmit={handleSubmit} grow>
      <BackLink to={`${ROUTES.SLOT_BOOKING}/${get(['vendingMachine', 'id'], data)}`} />
      <ContentBox grow>
        <BackLinkHeaderContainer>
          <h1>{t('slot.booking.title', { numberId: data.numberId || '' })}</h1>
        </BackLinkHeaderContainer>
        <BookingConteiner>
          <InfoContainer>
            <InfoBlock label={t('slot.booking.VMId')} text={get(['vendingMachine', 'machineId'], data)} />
            <InfoBlock label={t('common.address')} text={get(['vendingMachine', 'address'], data)} />
            <InfoBlock label={t('slot.common.slotType')} text={t(`slot.types.${get(['slotParams', 'slotType'], data)}`)} />
            <InfoBlock label={t('slot.common.demension')} text={demension} />
            <InfoBlock label={t('slot.common.maxCapacity')} text={get(['slotParams', 'capacity'], data)} />
          </InfoContainer>
          <InputsWrapper>
            {bookingDisabled && (
              <PlaceholderContainer>
                <NoContentPlaceholder placeholder={t('slot.booking.refill-info')} />
              </PlaceholderContainer>
            )}
            <Select
              name="vendor"
              register={register}
              setValue={setValue}
              label={t('common.vendor')}
              values={vendors}
              defaultValue={get(['vendor', 'id'], data)}
              disabled={bookingDisabled}
            />
            <InputCaption>
              {t('slot.common.productAssignment')}
              <span>{product || t('common.placeholders.product')}</span>
            </InputCaption>
          </InputsWrapper>
        </BookingConteiner>
      </ContentBox>
      <FooterSubmitControlls
        returnLink={`${ROUTES.SLOT_BOOKING}/${get(['vendingMachine', 'id'], data)}`}
        disabled={bookingDisabled}
        confirmation={data.vendor ? {
          onConfirm: handleSubmit,
          title: product ? t('slot.booking.confirm-change-product') : t('slot.booking.confirm-change-vendor'),
          confirmButtonText: t('common.confirm'),
          cancelButtonText: t('common.cancel'),
        } : {}}
      />
    </StyledForm>
  );
};

EditSlot.propTypes = {
  t: func.isRequired,
  vendors: shape({}),
  data: shape({}),
  register: func,
  setValue: func,
  handleSubmit: func,
  bookingDisabled: bool,
};

EditSlot.defaultProps = {
  vendors: {},
  data: {},
  register: noop,
  setValue: noop,
  handleSubmit: noop,
  bookingDisabled: false,
};

export default EditSlot;
