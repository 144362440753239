import React from 'react';
import { useTranslation } from 'react-i18next';
import { Error, ProgressWrapper } from 'components';
import { useStockOverviewPerMachine } from 'hooks';

import { isNotEmpty } from 'utils/lodash';

import List from './List';
import { getConfig } from './tableConfig';

const SlotsListPerMachine = () => {
  const { t } = useTranslation();
  const {
    stockOverviewPerMachine, loadingStockOverviewPerMachine, stockOverviewsPerMachineError, handleClearFailedReservations
  } = useStockOverviewPerMachine();

  if (stockOverviewsPerMachineError) return <Error code={stockOverviewsPerMachineError} />;
  if (loadingStockOverviewPerMachine) return <ProgressWrapper />;

  const props = {
    config: getConfig(t),
    data: stockOverviewPerMachine,
    t,
    requestAvailable: isNotEmpty(stockOverviewPerMachine),
    handleClearFailedReservations: handleClearFailedReservations
  };

  return (
    <>
      <List {...props} />
    </>
  );
};

export default SlotsListPerMachine;
