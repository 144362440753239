import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${({ size }) => size || '16px'};
  color: ${({ theme }) => theme.palette.greenBlueDarkened};

  &:hover {
    color: ${({ theme }) => theme.palette.lynch}
  }
`;
