import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { func, shape, arrayOf } from 'prop-types';
import { useSnackBar } from 'hooks';
import { ROUTES } from 'configs';
import {
  ContentBox,
  TableContainer,
  BackLink,
  StyledHeader,
  Button,
  ConfirmDialog,
} from 'components';

import {
  noop
} from 'lodash/fp';

import DataTable  from './DataTable';

import { Row } from '../styled';

import { useMutation } from '@apollo/react-hooks';
import { onFormError } from 'utils';

import {
  RESET_ALARMS,
  RESET_MACHINE,
  ENABLE_MACHINE
} from '../queries';


var alarmId = [];


const VendingMachineStatus = ({ t, config, data }) => {

  const [showResetMachineDlg, setVisibleDlgReset] = useState(false);
  const [showEnableDisableMachineDlg, setVisibleDlgEnableDisable] = useState(false);
  const [showResetAlarmDlg, setVisibleDlgAlarm] = useState(false);

  const [showButton, setShowButton] = useState(true);
  const { openBar } = useSnackBar();
  const history = useHistory();

  const [resetMachine] = useMutation(RESET_MACHINE, {
    onError: onFormError(t, noop, openBar),
    onCompleted: () => history.push(ROUTES.MACHINE_STATUS),
  });

  const [resetAlarms] = useMutation(RESET_ALARMS, {
    onError: onFormError(t, noop, openBar),
    onCompleted: () => history.push(ROUTES.MACHINE_STATUS),
  });

  const [enableMachine] = useMutation(ENABLE_MACHINE, {
    onError: onFormError(t, noop, openBar),
    onCompleted: () => history.push(ROUTES.MACHINE_STATUS),
  });

 
  let disabled = false;
  let title = '';
  let buttonName = '';
  let cancelButtonText = '';
  let confirmButtonText = '';

  for (let i = 0; i < data.length; i++){
    if(( data[i].event === 'disabled' ) && ( data[i].pending === true )){
      disabled = true;
      break;
    }
  }

  if( disabled ){
    buttonName = 'Enable machine';
    title = t('machineStatus.dialogEnableMachine.title')
    cancelButtonText = t('machineStatus.dialogEnableMachine.cancel')
    confirmButtonText = t('machineStatus.dialogEnableMachine.confirm')
  }else{
    buttonName = 'Disable machine';
    title = t('machineStatus.dialogDisableMachine.title')
    cancelButtonText = t('machineStatus.dialogDisableMachine.cancel')
    confirmButtonText = t('machineStatus.dialogDisableMachine.confirm')
  }

  /*---------- reset machine stuff ---------------*/

   const onResetMachine = (event)=>{
    setVisibleDlgReset(true);
  };

  const onConfirmDlgReset = ()=>{

    const vmId = data[0].vmId;
    resetMachine({ variables: { input: { vmId }}});
    //history.push(ROUTES.MACHINE_STATUS);
    setVisibleDlgReset(false);
  };

  const onCancelDlgReset = ()=>{
    setVisibleDlgReset(false);
  };

  /*---------- disable machine stuff ---------------*/

  const onEnableDisableMachine = (event)=>{
    setVisibleDlgEnableDisable(true);
  };

  const onConfirmDlgEnableDisable = ()=>{

    const vmId = data[0].vmId;

    if(disabled){
      enableMachine({ variables: { input: { vmId, enabled:true }}});
    } else {
      enableMachine({ variables: { input: { vmId, enabled:false }}});
    }
    //history.push(ROUTES.MACHINE_STATUS);
    setVisibleDlgEnableDisable(false);
  };

  const onCancelDlgEnableDisable = ()=>{
    setVisibleDlgEnableDisable(false);
  };
  
  /*---------- reset alarm stuff ---------------*/

  const onSelected = (selectedRows) =>{

    console.log('onSelected : ', selectedRows)

    alarmId = [];

    // based on selected rows assign the alarms to array alarms
    for ( let i = 0; i< selectedRows.length; i++){
      if( selectedRows[i] == true ){
        if( data[i].alarms != null){
          for ( let k = 0; k< data[i].alarms.length; k++ ){
            alarmId.push( data[i].alarms[k].toString() );
          }
        }
      }
    }

    if(selectedRows.includes(true)){
      setShowButton( true );
    }else{
      setShowButton( false );
    }
  }

  const onResetAlarms = (event)=>{
    setVisibleDlgAlarm(true);
  };

  const onConfirmDlgAlarm = ()=>{ 
    console.log('alams : ', alarmId)

    const vmId = data[0].vmId;
    resetAlarms({ variables: { input: { vmId, alarmId }}});
    //history.push(ROUTES.MACHINE_STATUS);
    setVisibleDlgAlarm(false);
  };

  const onCancelDlgAlarm = ()=>{
    setVisibleDlgAlarm(false);
  };
  

  return (
  <>
    <BackLink to={`${ROUTES.MACHINE_STATUS}`} />
    <ContentBox>
      <StyledHeader>
        {data.length > 0 && (
          <h1>{`${t('machineStatus.view.title')} ${data[0].machineId}`}</h1>
        )}
        {data.length === 0 && <h1>{t('machineStatus.view.noEvents')}</h1>}
      </StyledHeader>
      <Row>
        {( showButton ) && (
          <Button onClick={(event) => onResetAlarms(event)}>
            Reset alarm
          </Button>
         )}
        <br/>
        <Button onClick={(event) => onEnableDisableMachine(event)}>
          {buttonName}
        </Button>
        <Button onClick={(event) => onResetMachine(event)}>
          Reset machine
        </Button>
        <ConfirmDialog
          title={t('machineStatus.dialogResetMachine.title')}
          cancelButtonText={t('machineStatus.dialogResetMachine.cancel')}
          confirmButtonText={t('machineStatus.dialogResetMachine.confirm')}
          onConfirm={onConfirmDlgReset}
          onCancel={onCancelDlgReset}
          open={showResetMachineDlg}
        />
        <ConfirmDialog
          title={title}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          onConfirm={onConfirmDlgEnableDisable}
          onCancel={onCancelDlgEnableDisable}
          open={showEnableDisableMachineDlg}
        />
         <ConfirmDialog
          title={t('machineStatus.dialogResetAlarm.title')}
          cancelButtonText={t('machineStatus.dialogResetAlarm.cancel')}
          confirmButtonText={t('machineStatus.dialogResetAlarm.confirm')}
          onConfirm={onConfirmDlgAlarm}
          onCancel={onCancelDlgAlarm}
          open={showResetAlarmDlg}
        />
      </Row>
    </ContentBox>
    <TableContainer>
      {data.length > 0 && <DataTable config={config} data={data} onSelected={onSelected} />}
    </TableContainer>
  </>
  )
};

VendingMachineStatus.propTypes = {
  t: func.isRequired,
  config: shape({}).isRequired,
  data: arrayOf(shape({})),
};

VendingMachineStatus.defaultProps = {
  data: arrayOf(shape({})),
};

export default withTranslation()(VendingMachineStatus);
