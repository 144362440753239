import { CELL_TYPE_HIGHLIGHTED, CELL_TYPE_ARROW, CELL_TYPE_TRANSLATION } from 'components/Table/constants';
import { ROUTES } from 'configs';

export const getConfig = (t, id) => ({
  headers: [
    {
      id: 'numberId',
      label: t('slotsList.table.slot'),
      type: CELL_TYPE_HIGHLIGHTED,
      key: 'numberId',
      sortable: true,
    },
    {
      type: CELL_TYPE_TRANSLATION,
      id: 'slotType',
      label: t('slot.common.slotType'),
      key: ['slotParams', 'slotType'],
      translations: t('slot.types', { returnObjects: true }),
    },
    {
      id: 'vendor',
      label: t('slotsList.table.vendor'),
      key: ['active', 'vendor', 'companyName'],
      placeholder: t('slotsList.placeholders.vendor'),
    },
    {
      id: 'stagedVendor',
      label: t('slotsList.table.stagedVendor'),
      key: ['staged', 'vendor', 'companyName'],
      placeholder: "-",
    },
    {
      id: 'plannedVendor',
      label: t('slotsList.table.plannedVendor'),
      key: ['planned', 'vendor', 'companyName'],
      placeholder: "-",
    },
    {
      id: 'product',
      label: t('slotsList.table.product'),
      key: ['active', 'product', 'name'],
      placeholder: t('slotsList.placeholders.product'),
    },
    {
      id: 'plannedProduct',
      label: t('slotsList.table.plannedProduct'),
      key: ['planned', 'product', 'name'],
      placeholder: "-",
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ],
  rowLink: (data) => `${ROUTES.SLOT_BOOKING}/${id}/edit/${data.id}`,
});
