import React, { useState } from 'react';
import { string } from 'prop-types';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { roleAccess } from 'configs';

import LogoutItem from './LogoutItem';

import { UserMenuButton } from './styled';

const UserMenu = ({ firstName, lastName, role }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { userMenuRoutes } = roleAccess[role];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <UserMenuButton variant="contained" onClick={handleClick}>
        {`${firstName[0]}${lastName[0]}`}
      </UserMenuButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {userMenuRoutes.map(
          (route) => <MenuItem onClick={handleClose} key={route}>{route}</MenuItem>,
        )}
        <LogoutItem onClick={handleClose} />
      </Menu>
    </div>
  );
};

UserMenu.propTypes = {
  firstName: string,
  lastName: string,
  role: string.isRequired,
};

UserMenu.defaultProps = {
  firstName: '',
  lastName: '',
};

export default UserMenu;
