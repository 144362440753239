import React from 'react';
import { string } from 'prop-types';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import PrintButton from './PrintButton';

const PRODUCT_PRINT_DATA = gql`
query PrintingProductItems($input: PrintingProductItemsInput!) {
    printingProductItems(input: $input) {
      nodes {
        productItemId
        expDate
        productType {
          _id
          name
        }
      }
    }
  }
`;

const PrintButtonContainer = ({ refillRequestId, productId }) => {
  const { data, loading } = useQuery(PRODUCT_PRINT_DATA,
    { variables: { input: { refillRequestId, productId } } });

  return <PrintButton data={get(['printingProductItems', 'nodes'], data)} disabled={loading} />;
};

PrintButtonContainer.propTypes = {
  refillRequestId: string.isRequired,
  productId: string.isRequired,
};

export default PrintButtonContainer;
