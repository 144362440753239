import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

export const StyledButton = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontFamily: theme.typography.robotoMedium,
    color: theme.palette.purpleBlue,
    backgroundColor: 'transparent',
    borderColor: theme.palette.lightBlue,
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.lightBlue}`,
      backgroundColor: 'transparent',
    },
    '&$disabled': {
      color: theme.palette.purpleBlue,
      opacity: '40%',
      backgroundColor: theme.palette.lightBlueTransparent,
    },
  },
  disabled: {},
  transition: 'none',
}))(Button);


export const StyledButtonGroup = withStyles({
  root: {
  },
})(ButtonGroup);
