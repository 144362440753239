import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { uploadClient } from 'apolloClient';
import { useTranslation } from 'react-i18next';

import { isString, omit } from 'lodash/fp';

import { useHistory } from 'react-router-dom';

import { ROUTES } from 'configs';

import { useSnackBar } from 'hooks';
import { capMap, setInput, onFormError } from 'utils';

import { useEditForm } from './form';
import {
  CREATE_VENDOR, UPDATE_VENDOR, VENDOR,
} from '../queries';

export const useCreateVendor = () => {
  const { setError, handleSubmit, ...formParams } = useEditForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const [createVendor] = useMutation(CREATE_VENDOR, {
    onCompleted: () => {
      openBar(t('vendor.common.createMessage'));
      history.push(`${ROUTES.VENDORS}`);
    },
    onError: onFormError(t, setError, openBar),
    client: uploadClient,
    context: {
      headers: { authorization: localStorage.getItem('token') },
    },
  });

  const onCreateVendor = useCallback(handleSubmit((form) => {
    const values = { ...setInput(form) };
    const input = isString(form.picture) ? omit('picture', values) : { ...values, picture: form.picture };

    createVendor({
      variables: {
        input: {
          ...input,
          role: 'vendor',
        },
      },
    });
  }), [createVendor, handleSubmit]);

  return {
    ...formParams,
    t,
    onCreateVendor,
  };
};

export const useEditVendor = (id) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const {
    setValue, setError, handleSubmit, ...formParams
  } = useEditForm();


  const { loading, error, data } = useQuery(VENDOR, {
    variables: { id },
    onCompleted: (response) => setValue(
      capMap((value, key) => ({ [key]: value }), response.account),
    ),
  });

  const history = useHistory();

  const [updateVendor] = useMutation(UPDATE_VENDOR, {
    onCompleted: () => history.push(`${ROUTES.VENDORS}`),
    onError: onFormError(t, setError, openBar),
    client: uploadClient,
    context: {
      headers: { authorization: localStorage.getItem('token') },
    },
  });

  const onEditVendor = useCallback(handleSubmit((form) => {
    const values = { accountId: id, ...setInput(form) };
    const input = isString(form.picture) || (form.picture && form.picture.signature) ? omit('picture', values) : { ...values, picture: form.picture };

    updateVendor({
      variables: {
        input,
      },
    });
  }), [updateVendor, handleSubmit]);

  return {
    ...formParams,
    t,
    setValue,
    error,
    data,
    loading,
    onEditVendor,
  };
};
