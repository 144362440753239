import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Error, ProgressWrapper } from 'components';
import { convertGqlError } from 'utils';

import { getConfig } from './tableConfig';
import { SLOT_BOOKING_LIST } from '../queries';
import { prepareData } from './utils';
import SlotList from './List';


const SlotsContainer = () => {
  const { t } = useTranslation();
  const {
    loading, error, data,
  } = useQuery(SLOT_BOOKING_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;

  const props = {
    config: getConfig(t),
    data: prepareData(data),
    t,
  };

  return (<SlotList {...props} />);
};

export default SlotsContainer;
