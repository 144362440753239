import styled from 'styled-components';
import { ArrowRightIcon, AngleRightIcon } from 'static/icons';

export const DisabledCellValue = styled.span`
  ${({ theme, isDisabled }) => isDisabled && `color: ${theme.palette.darkPurpleTransparent} `}
`;

export const StyledArrowIcon = styled(ArrowRightIcon)`
  width: 14px;
  height: 14px;
  fill: currentColor;
  display: flex;
`;

export const Placeholder = styled.span`
  color: ${({ theme }) => theme.palette.grayBlue};
`;

export const InputContainer = styled.div`
  width: 70px;
`;

export const SelectContainer = styled.div`
  width: 272px;
`;

export const StyledRightAngleIcon = styled(AngleRightIcon)`
  width: 7px;
  height: 12px;
  fill: ${({ theme }) => theme.palette.greenBlueLightened};
  margin: 0 5px;
  vertical-align: middle;
`;

export const StyledPrevious = styled.span`
  color: ${({ theme }) => theme.palette.darkPurpleTransparent};
`;
