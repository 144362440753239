import { ROUTES } from './routes';

const {
  PRODUCTS,
  VENDING_MACHINES,
  SLOT_BOOKING,
  STOCK,
  VENDORS,
  SLOTS,
  REFILL,
  PRODUCT_ASSIGNMENT,
  SALES,
  MACHINE,
  HISTORY,
  MACHINE_STATUS,
} = ROUTES;

const roleAccess = {
  admin: {
    navigationRoutes: [MACHINE_STATUS, PRODUCTS, VENDING_MACHINES, SLOT_BOOKING, STOCK, VENDORS, SALES, MACHINE],
    userMenuRoutes: ['Profile', 'My account', 'Preferences'],
  },
  vendor: {
    navigationRoutes: [PRODUCTS, SLOTS, REFILL, PRODUCT_ASSIGNMENT, SALES, HISTORY, MACHINE],
    userMenuRoutes: ['Profile', 'My account', 'Preferences'],
  },
  refiller: {
    navigationRoutes: [STOCK],
    userMenuRoutes: ['Profile', 'My account', 'Preferences'],
  },
  observer: {
    navigationRoutes: [SALES, SLOTS],
    userMenuRoutes: ['Profile', 'My account', 'Preferences'],
  },
};

export const DEFAULT_ROUTE = {
  admin: MACHINE_STATUS,
  refiller: STOCK,
  vendor: PRODUCTS,
  observer: SALES,
};


export default roleAccess;
