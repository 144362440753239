import React from 'react';
import { noop } from 'lodash/fp';
import {
  bool, string, node, func,
} from 'prop-types';

import { StyledButton } from './styled';

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>
    {children}
  </StyledButton>
);

Button.propTypes = {
  onClick: func,
  children: node.isRequired,
  color: string,
  variant: string,
  disabled: bool,
};

Button.defaultProps = {
  onClick: noop,
  color: 'primary',
  disabled: false,
  variant: 'contained',
};

export default Button;
