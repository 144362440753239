import React from 'react';
import { func } from 'prop-types';
import { noop } from 'lodash/fp';

import MenuItem from '@material-ui/core/MenuItem';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const LOGOUT = gql`
  mutation LOGOUT {
    logout @client
  }
`;

const LogoutItem = ({ onClick }) => {
  const [logout] = useMutation(LOGOUT);

  const handleClick = () => {
    logout();
    onClick();
  };

  return (
    <MenuItem onClick={handleClick}>Logout</MenuItem>
  );
};

LogoutItem.propTypes = {
  onClick: func,
};

LogoutItem.defaultProps = {
  onClick: noop,
};

export default LogoutItem;
