import { gql } from 'apollo-boost';

export const LIST_MACHINES = gql`
  query VendingMachines {
    vendingMachines {
      nodes {
        id: _id
        machineId
        name
        address
        lastRefillUpdate
        vmNumber
        vendingWall
        stagedSlots {
          id: _id
          soldFromLastRefill
          numberId
          slotParams {
            capacity
            slotType
          }
          vendor {
            id: _id
            companyName
          }
          product {
            id: _id
            name
          }
          productItems {
            id: _id
          }
        }
      }
    }
    vendingMachineEvents(input: { pending: true }) {
      totalCount
      vendingMachineEvents {
        _id
        machineId
        vmId
        event
        alarms {
          description
          id
          t
        }
        pending
        createdAt
        clearedAt
      }
    }
  }
`;

export const VIEW_MACHINE = gql`
  query vendingMachineEvents ($vmId:ID!)  {
    vendingMachineEvents(input: {
      vmIds: [$vmId]
      events: [offline, maintenance, alarm, disabled]
      }) {
      totalCount
      vendingMachineEvents {
        _id
        machineId
        vmId
        event
        alarms {
          description
          id
          t
        }
        pending
        createdAt
        clearedAt
      }
    }
  }
`;

export const RESET_MACHINE = gql`
  mutation SystemReset($input: SystemResetInput!){
    systemReset(input: $input)
  }
`;

export const RESET_ALARMS = gql`
  mutation ResetAlarms($input: AlarmAcknowledgeInput!){
    alarmAcknowledge(input: $input)
  }
`;

export const ENABLE_MACHINE = gql`
  mutation EnableMachine($input: EnableMachineInput!){
    enableMachine(input: $input)
  }
`;
