import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from "react-i18next";
import { InfoTitle } from "components";


export const ALL_MACHINES = 'All';

var machineName = [];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const DropDownSelect = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [age, setAge] = useState('');


  const handleChange = (event) => {
    let selectedMachine = machineName[event.target.value]['name'];
    // handover selected machine to SalesOverview.jsy
    props.onSelected(selectedMachine);
    // update select state
    setAge(event.target.value);
  };

  // fill list with machines
  machineName = [];
  var obj = {'name':'All' ,'id':0 };  
  machineName.push(obj);

  for( let i = 0; i<props.machines.length; i++){  
    obj = {'name':props.machines[i] ,'id':i+1 };  
    machineName.push( obj );
  }

  return (
    <div>
      <InfoTitle>{t("sales.selectMachine")}:</InfoTitle>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Machine</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={age}
          onChange={handleChange}
          label={ALL_MACHINES}
        >         
           {machineName.map((option) => (
             <MenuItem value={option.id}>
                {option.name}
             </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDownSelect;