import React from 'react';
import { func, shape } from 'prop-types';
import { noop } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import { ROUTES } from 'configs';

import { TextInput, Link } from 'components';
import { getFieldError } from 'utils/forms';

import { AuthForm, StyledHeader, StyledButton } from './styled';

const SignInForm = ({
  t, handleSubmit, register, errors,
}) => (
  <AuthForm onSubmit={handleSubmit}>
    <StyledHeader>{t('auth.sign-in')}</StyledHeader>
    <TextInput
      type="email"
      label={t('auth.email')}
      name="email"
      register={register}
      error={getFieldError('email', errors)}
    />
    <TextInput
      type="password"
      label={t('auth.password')}
      name="password"
      register={register}
      error={getFieldError('password', errors)}
    />
    <StyledButton type="submit" width="100%">{t('auth.sign-in')}</StyledButton>
    <Link to={ROUTES.RECOVERY_PASSWORD} size="13px">{t('auth.forgot-password')}</Link>
  </AuthForm>
);

SignInForm.propTypes = {
  t: func.isRequired,
  handleSubmit: func,
  register: func,
  errors: shape({}),
};

SignInForm.defaultProps = {
  handleSubmit: noop,
  register: noop,
  errors: {},
};

export default withTranslation()(SignInForm);
