import React from 'react';
import { func, shape, string } from 'prop-types';
import { noop } from 'lodash/fp';

import { ROUTES } from 'configs';

import {
  getOr,
} from 'lodash/fp'; 

import {
  TextEditor,
  Tabs,
  ContentBox,
  BackLink,
  FooterSubmitControlls,
  StyledForm,
  BackLinkHeaderContainer,
} from 'components';
import VendorForm from './VendorForm';

const EditVendor = ({
  onSave, register, setValue, errors, defaultValues, picture, t,
}) => (
  <StyledForm onSubmit={onSave}>
    <BackLink to={ROUTES.VENDORS} />
    <ContentBox>
      <BackLinkHeaderContainer>
        <h1>{t('vendor.edit.title')}</h1>
      </BackLinkHeaderContainer>
      <Tabs withBorder labels={[t('tabs.vendorInfo'), t('tabs.description')]}>
        <VendorForm
          defaultValues={defaultValues}
          errors={errors}
          register={register}
          setValue={setValue}
          picture={picture}
        />
        <TextEditor value={   getOr( '', 'landing.description' , defaultValues)   } register={register} setValue={setValue} name="landingDescription" />
      </Tabs>
    </ContentBox>
    <FooterSubmitControlls returnLink={ROUTES.VENDORS} />
  </StyledForm>
);
EditVendor.propTypes = {
  onSave: func,
  register: func,
  setValue: func,
  errors: shape({}),
  t: func,
  defaultValues: shape({}),
  picture: string,
};

EditVendor.defaultProps = {
  t: noop,
  onSave: noop,
  register: noop,
  setValue: noop,
  errors: {},
  defaultValues: {},
  picture: null,
};

export default EditVendor;
