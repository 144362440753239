import React from 'react';

import { useCreateVendor } from './hooks';

import EditVendor from './Vendor';

const VendorEditContainer = () => {
  const {
    register, handleSubmit, setValue, errors, graphError, onCreateVendor, t,
  } = useCreateVendor();

  const props = {
    t,
    register,
    handleSubmit,
    setValue,
    errors,
    graphError,
    onSave: onCreateVendor,
  };

  return <EditVendor {...props} />;
};

export default VendorEditContainer;
