import React from 'react';
import { withTranslation } from 'react-i18next';
import { getDate } from 'utils';

import {
  bool,
  func,
  shape,
} from 'prop-types';
import { ROUTES, NESTED_ROUTES } from 'configs';

import {
  ContentBox,
  Table,
  BackLink,
  HeadInfo,
  HeadInfoContainer,
  StyledHeader,
  TableContainer,
  FooterSubmitControlls,
  StyledForm,
} from 'components';

const ViewVendingMachine = ({
  t,
  config,
  data,
  onSave,
  isDirty,
}) => (
  <StyledForm onSubmit={onSave()}>
    <BackLink to={`${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}`} />
    <ContentBox>
      <StyledHeader>
        <h1>{data.name}</h1>
      </StyledHeader>
      <HeadInfoContainer>
        <HeadInfo title={t('stock.stockManagement.view.id')} value={data.machineId} />
        <HeadInfo title={t('stock.stockManagement.view.updateDate')} value={getDate(data.lastRefillUpdate, t)} />
        <HeadInfo title={t('stock.stockManagement.view.address')} value={data.address} />
      </HeadInfoContainer>
    </ContentBox>
    <TableContainer>
      <Table
        config={config}
        data={data.slots}
      />
    </TableContainer>
    <FooterSubmitControlls
      submitText={t('common.update')}
      returnLink={`${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}`}
      onSave={onSave}
      isDirty={isDirty}
      withGuard
    />
  </StyledForm>
);

ViewVendingMachine.propTypes = {
  t: func.isRequired,
  config: shape({}).isRequired,
  data: shape({}),
  onSave: func.isRequired,
  isDirty: bool,
};

ViewVendingMachine.defaultProps = {
  data: {},
  isDirty: false,
};

export default withTranslation()(ViewVendingMachine);
