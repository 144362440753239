import React, { useState, useEffect } from 'react';
import {
  shape, arrayOf, number, func,
} from 'prop-types';

import {
  map, get, has, noop, isNumber, isFunction,
} from 'lodash/fp';

import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import { headerType, rowType } from 'components/Table/propTypes';
import Cell from 'components/Table/Cell';
import HeaderCell from 'components/Table/Cell/Header';
import CollapseButton from 'components/Table/CollapseButton';

import {
  StyledTable,
  StyledTableRow,
  StyledTablePagination,
  StyledTableContainer,
} from 'components/Table/styled';

import {
    StyledCheckbox,
} from '../styled';

import { useTable, useTableCollapse } from 'components/Table/hooks';


var selectedRows = [];
var counter = 0;


const DataTable = ({
  config,
  data,
  rowsPerPageOptions,
  itemsCount,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  defaultSort,
  onSort,
  onSelected,
}) => {
  const {
    selectedPage,
    changePage,
    pageRows,
    changeRowsPerPage,
    sort,
    changeSort,
  } = useTable(page, onChangePage, rowsPerPage, onChangeRowsPerPage, defaultSort, onSort);

  
  const { tableData, ...collapseProps } = useTableCollapse(config, data);
  const [checked, setClicked] = useState([]);
  const [checkedAll, setAllClicked] = useState(false);
  const [count, setCount] = useState(0);
 

   // init array 
   useEffect(() =>{
    for ( let i = 0; i<tableData.length; i++ ){
      selectedRows.push(false);
    }
    setClicked( selectedRows );
  },[]);

  // check box element
  const CheckBox = (props) => {

    if( props.row.event === "alarm"){
      if( checked[props.index] == true ){
        return <StyledCheckbox checked={true} /> 
      }else{
        return <StyledCheckbox checked={false} /> 
      }
    }else{
      return <p></p>;
    }
  }

  // header row clicked
  const handleAllClick = (event) =>{

    // toggle checkbox state
    if( checkedAll == false ){
      for ( let i = 0; i< tableData.length; i++){
        selectedRows[i] = true;
      }
    }else{
      for ( let i = 0; i< tableData.length; i++){
        selectedRows[i] = false;
      }
    }

    setClicked( selectedRows );
    // toggle check boxes
    setAllClicked ( !checkedAll );
    // callback to vendingMachineStatus
    onSelected( selectedRows );
  }

  // single row clicked
  const handleSingleClick = (event, index, row) =>{
    // do only an action if row has an alarm event
    if(tableData[index].event === "alarm"){
      // register the selected row
      if( selectedRows[index] == true ){
        selectedRows[index] = false;
      }else{
        selectedRows[index] = true;
      }
      
      setClicked( selectedRows );

      // check if all alarm check boxes are selected
      let allSelected = true;
      for ( let i = 0; i< tableData.length; i++){
        if((tableData[i].event === "alarm") && (selectedRows[i] == false)){
          allSelected = false;
        }
      }
      if( allSelected ){
        setAllClicked( true );
      }else{
        setAllClicked( false );
      }

      // callback to vendingMachineStatus
      onSelected( selectedRows );
      // enforce a render
      setCount( counter++ );
    }
  }


  return (
    <>
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow 
              hover
              onClick={(event) => handleAllClick(event)}
             >
            <StyledCheckbox
              checked={checkedAll}
            />
              {map((item) => (
                <HeaderCell
                  config={item}
                  key={item.id}
                  sort={sort}
                  setSort={changeSort}
                >
                  {item.label}
                </HeaderCell>
              ), config.headers)}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tableData.map((row, index) => (
                <StyledTableRow
                  hover
                  key={index}
                  onClick={(event) => handleSingleClick(event, index, row)}
                >
                  <CheckBox row={row} index={index} ></CheckBox>
                  {
                    map((header) => (
                      <Cell
                        key={`${row._id}-${header.key}`}
                        row={row}
                        header={header}
                        register={config.register}
                        setValue={config.setValue}
                      >
                        {isFunction(header.key) ? header.key(header, row) : get(header.key, row)}
                      </Cell>
                    ), config.headers)
                  }
                </StyledTableRow>
              ))
            }
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {has(['collapse'], config) && (
        <CollapseButton {...collapseProps} />
      )}
      {isNumber(page) && (itemsCount > rowsPerPageOptions[0]) && (
        <StyledTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={itemsCount}
          rowsPerPage={pageRows}
          page={selectedPage}
          onChangePage={changePage}
          onChangeRowsPerPage={changeRowsPerPage}
        />
      )}
    </>
  );
};

DataTable.propTypes = {
  config: shape({
    headers: arrayOf(headerType),
  }),
  data: arrayOf(rowType),
  defaultSort: shape({}),
  rowsPerPageOptions: arrayOf(number),
  itemsCount: number,
  rowsPerPage: number,
  page: number,
  onChangePage: func,
  onChangeRowsPerPage: func,
  onSort: func,
  onSelected: func,
};

DataTable.defaultProps = {
  config: {
    headers: [{}],
  },
  data: [],
  rowsPerPageOptions: [10, 20, 50, 100, 200],
  itemsCount: 100,
  rowsPerPage: 10,
  page: null,
  defaultSort: {},
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  onSort: noop,
  onSelected: noop,
};

export default DataTable;
