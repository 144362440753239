import React from 'react';
import { string, func, bool } from 'prop-types';
import { noop } from 'lodash/fp';

import { TextInput } from 'components';

import { InputContainer } from './styled';

const InputCell = ({
  name, defaultValue, register, isVisible, isDisabled, type, max
}) => (
  isVisible && (
  <InputContainer>
    <TextInput
      name={name}
      variant="outlined"
      placeholder="0"
      defaultValue={defaultValue}
      disabled={isDisabled}
      register={register}
      type={type}
      max={max}
    />
  </InputContainer>
  )
);

InputCell.propTypes = {
  name: string.isRequired,
  defaultValue: string,
  register: func,
  isDisabled: bool,
  isVisible: bool,
};

InputCell.defaultProps = {
  defaultValue: string,
  register: noop,
  isDisabled: false,
  isVisible: true,
};

export default InputCell;
