export const MAX_DAY_BACK   = 3;  
export const MAX_MONTH_BACK = 0;

export const INSIDE                        = true;
export const OUTSIDE                       = true;
export const CONDENSOR                     = true;
export const DOOR_STATUS                   = true;
export const INSIDE_FILTERED               = false;
export const INSIDE_ALARM_THREHOLD_MIN     = false;
export const INSIDE_ALARM_THRESHOLD_MAX    = false;
export const CONDENSOR_ALARM_THREHOLD_MIN  = false;
export const CONDENSOR_ALARM_THRESHOLD_MAX = false;
