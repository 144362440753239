import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { ROUTES, NESTED_ROUTES } from 'configs';

import SlotsList from './List';
import SlotsListPerMachine from './ListPerMachine';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.SLOTS}/${NESTED_ROUTES.PER_PRODUCT}`}>
      {(props) => (
        <SlotsList />
      )}
    </Route>
    <Route path={`${ROUTES.SLOTS}/${NESTED_ROUTES.PER_MACHINE}`}>
      {(props) => (
        <SlotsListPerMachine />
        )}
    </Route>
    <Route path={`${ROUTES.SLOTS}`}>
      {(props) => <Redirect to={`${ROUTES.SLOTS}/${NESTED_ROUTES.PER_PRODUCT}`} {...props} />}
    </Route>
  </Switch>
)


export default Router;
