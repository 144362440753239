import React from 'react';
import { useTranslation } from 'react-i18next';
import { Error, ProgressWrapper } from 'components';
import { useStockOverview } from 'hooks';

import { isNotEmpty } from 'utils/lodash';

import List from './List';
import { getConfig } from './tableConfig';

const SlotsList = () => {
  const { t } = useTranslation();
  const {
    stockOverview, loadingStockOverview, stockOverviewsError,
  } = useStockOverview();

  if (stockOverviewsError) return <Error code={stockOverviewsError} />;
  if (loadingStockOverview) return <ProgressWrapper />;

  const props = {
    config: getConfig(t),
    data: stockOverview,
    t,
    requestAvailable: isNotEmpty(stockOverview),
  };

  return (
    <List {...props} />
  );
};

export default SlotsList;
