import React from 'react';
import {
  func, node, string, bool,
} from 'prop-types';
import { noop } from 'lodash/fp';

import { Button, ConfirmDialog } from 'components';
import { useDialogState } from 'hooks';

const ConfirmPopupButton = ({
  onConfirm, color, disabled, children, ...dialogProps
}) => {
  const {
    isOpen, handleOpen, handleClose, handleConfirm,
  } = useDialogState(onConfirm);

  return (
    <>
      <Button color={color} onClick={handleOpen} disabled={disabled}>
        {children}
      </Button>
      <ConfirmDialog
        {...dialogProps}
        open={isOpen}
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />
    </>
  );
};

ConfirmPopupButton.propTypes = {
  children: node.isRequired,
  color: string,
  confirmButtonText: string,
  cancelButtonText: string,
  title: string,
  onConfirm: func,
  onCancel: func,
  disabled: bool,
};

ConfirmPopupButton.defaultProps = {
  color: 'secondary',
  confirmButtonText: 'Agree',
  cancelButtonText: 'Cancel',
  title: 'Are you sure?',
  onConfirm: noop,
  onCancel: noop,
  disabled: false,
};

export default ConfirmPopupButton;
