import React from 'react';

import { PageWrapper } from 'components';

import Router from './router';

const VendorsPage = () => (
  <PageWrapper>
    <Router />
  </PageWrapper>
);

export default VendorsPage;
