import {
  format,
  parseISO,
  formatDuration,
} from 'date-fns';

import { enUS, de } from 'date-fns/locale';

export const getDate = (dateString, t) => (dateString ? format(parseISO(dateString), 'dd/MM/yyyy HH:mm') : t('common.placeholders.date'));

export const getDateWithoutTime = (dateString, t) => (dateString ? format(parseISO(dateString), 'dd/MM/yyyy') : t('common.placeholders.date'));

export const getDuration = (duration, locale, t) => {
  if (!duration) {
    return '-';
  }

  const hDiff = Math.floor(duration / 60);

  const diff = {
    hours: hDiff,
    minutes: duration - hDiff * 60,
  };

  return formatDuration(diff, {
    format: ['hours', 'minutes'],
    locale: locale === 'en' ? enUS : de,
  });
};
