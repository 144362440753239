import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'configs';

import { PageWrapper } from 'components';

import { StyledFreshTableLogoIcon } from './styled';

import SignIn from './SignIn';
import Recovery from './Recovery';
import CreatePassword from './CreatePassword';

const AuthorisationPage = () => (
  <PageWrapper centered>
    <StyledFreshTableLogoIcon />
    <Switch>
      <Route path={ROUTES.RECOVERY_PASSWORD}>
        <Recovery />
      </Route>
      <Route path={`${ROUTES.CREATE_PASSWORD}/:token`}>
        {(props) => <CreatePassword {...props} />}
      </Route>
      <Route path={ROUTES.AUTH}>
        <SignIn />
      </Route>
    </Switch>
  </PageWrapper>
);

export default AuthorisationPage;
