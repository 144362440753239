import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/styles';

export const StyledDialog = withStyles(() => ({
  paperScrollPaper: {
    width: 352,
    boxSizing: 'border-box',
    padding: '28px 32px 30px 32px',
  },
}))(Dialog);

export const StyledTitle = withStyles((theme) => ({
  root: {
    padding: 0,
    '& .MuiTypography-h6': {
      color: theme.palette.purpleBlue,
      fontFamily: theme.typography.robotoMedium,
      fontSize: 20,
      lineHeight: '28px',
    },
  },
}))(DialogTitle);

export const StyledActions = withStyles(() => ({
  root: {
    height: 24,
    padding: 0,

    '& button': {
      minWidth: 'unset',
    },
  },
}))(DialogActions);

export const StyledContent = withStyles(() => ({
  root: {
    padding: '20px 0 15px',
  },
}))(DialogContent);
