import styled from 'styled-components';


export const ChartContainer = styled.div`
  margin-top: 50px;
  width: 1400px;
  heigth: 700px;
`;

export const ContentContainer = styled.div`
  margin-top: 20px;
  width: 1400px;
  heigth: 600px;
`;

export const StyledButton = styled.button`
  background-color: #13c552;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 2px solid;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    border-color: green;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
