import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  get, getOr, isObject, cond, stubTrue, stubFalse,
} from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { ROUTES } from 'configs';

import { useVendorsNames, useSnackBar } from 'hooks';
import { convertGqlError, onFormError, hasReadyForFetchRequest } from 'utils';

import {
  VENDING_MACHINE, SLOT, BOOK_SLOT, REFILL_TEQUESTS_STATUSES, CLEAR_SLOT_BOOKING
} from '../queries';


const isBookingDisabled = (data, hasReadyForFetch) => cond([
  [(vm) => get(['slot', 'vendor'], vm) && (isObject(get(['slot', 'refill'], vm))), stubTrue],
  [(vm) => get(['slot', 'product', 'name'], vm) && hasReadyForFetch, stubTrue],
  [stubTrue, stubFalse],
])(data);

export const useEditSlot = (vmId, slotId) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const {
    register, setValue, handleSubmit, setError,
  } = useForm();

  const { vendors, loadingVendors } = useVendorsNames();
  vendors.notBooked = t('slotsList.placeholders.vendor');

  const { loading, error, data } = useQuery(SLOT, {
    fetchPolicy: 'no-cache',
    variables: { input: { id: slotId } },
  });

  const [bookSlot, { loading: loadingBooking }] = useMutation(BOOK_SLOT, {
    refetchQueries: [{
      query: VENDING_MACHINE,
      variables: { input: { id: vmId } },
    }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      history.push(`${ROUTES.SLOT_BOOKING}/${vmId}`);
      openBar(data.slot.vendor ? t('slot.booking.update') : t('slot.booking.create'));
    },
    onError: onFormError(t, setError, openBar),
  });

  const [clearSlotBooking, { loading: loadingClearBooking }] = useMutation(CLEAR_SLOT_BOOKING, {
    refetchQueries: [{
      query: VENDING_MACHINE,
      variables: { input: { id: vmId } },
    }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      history.push(`${ROUTES.SLOT_BOOKING}/${vmId}`);
      openBar(t('slot.booking.clear'));
    },
    onError: onFormError(t, setError, openBar),
  });

  const onSubmit = useCallback(handleSubmit(({ vendor }) => {
    if (vendor === "notBooked") {
        clearSlotBooking({ variables: { input: { slotId } } });
    } else {
      bookSlot({ variables: { input: { vendorId: vendor, slotId } } });
    }
  }), [handleSubmit, bookSlot]);

  return {
    t,
    loading: loading || loadingVendors || loadingBooking || loadingClearBooking,
    error: convertGqlError(error),
    vendors,
    data: get(['slot'], data),
    bookingDisabled: false,
    handleSubmit: onSubmit,
    register,
    setValue,
  };
};
