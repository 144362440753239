import React, { useState } from "react";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';



const SelectSamplingRate = () => { 

  const [rate, setRate] = useState(1);

  const handleChange = (event) => {
    setRate(event.target.value);
    console.log('sampling : ', event.target.value);
  }

  return (
    <div>
      <FormControl style={{width:"150px"}}>
        <InputLabel id="selectLabelId">SamplingRate [min.]</InputLabel>
        <Select
          labelId="selectLabelId"
          id="selectId"
          value={rate}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={1}>  1 min.</MenuItem>
          <MenuItem value={2}>  2 min.</MenuItem>
          <MenuItem value={5}>  5 min.</MenuItem>
          <MenuItem value={10}>10 min.</MenuItem>
          <MenuItem value={60}>60 min.</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

};


export default SelectSamplingRate;
