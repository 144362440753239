import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";

import { 
  Row,
  ColumnLeftCheckBox,
  ColumnRightCheckBox,
} from './styled';


import {
  INSIDE,                      
  OUTSIDE,                     
  CONDENSOR,                   
  DOOR_STATUS,                 
  INSIDE_FILTERED,             
  INSIDE_ALARM_THREHOLD_MIN,   
  INSIDE_ALARM_THRESHOLD_MAX,  
  CONDENSOR_ALARM_THREHOLD_MIN,
  CONDENSOR_ALARM_THRESHOLD_MAX,
} from './const'


const CustomColorCheckbox = withStyles({
  root: {
    color: "#13c552",
    "&$checked": {
      color: "#13c552"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);


const CheckBoxSelection = ({ setSelected }) => { 

  const [flavors, setFlavors] = useState({
    inside                     : INSIDE,
    outside                    : OUTSIDE,
    condensor                  : CONDENSOR,
    doorStatus                 : DOOR_STATUS,
    insideFiltered             : INSIDE_FILTERED,
    insideAlarmThresholdMin    : INSIDE_ALARM_THREHOLD_MIN,
    insideAlarmThresholdMax    : INSIDE_ALARM_THRESHOLD_MAX,
    condensorAlarmThresholdMin : CONDENSOR_ALARM_THREHOLD_MIN,
    condensorAlarmThresholdMax : CONDENSOR_ALARM_THRESHOLD_MAX,
  });

  
  const { inside, 
          outside, 
          condensor, 
          doorStatus, 
          insideFiltered, 
          insideAlarmThresholdMin,
          insideAlarmThresholdMax, 
          condensorAlarmThresholdMin, 
          condensorAlarmThresholdMax
        } = flavors;
  

  const handleFlavorChange = (event) => {
    setFlavors({ ...flavors, [event.target.name]: event.target.checked });
 
    console.log( event.target.name, event.target.checked )
  };

  setSelected( flavors );

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          <Row>
            <ColumnLeftCheckBox>
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={inside}
                    onChange={handleFlavorChange}
                    name="inside"
                  />
                }
                label="Inside"
              />
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={outside}
                    onChange={handleFlavorChange}
                    name="outside"
                  />
                }
                label="Outside"
              />
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={condensor}
                    onChange={handleFlavorChange}
                    name="condensor"
                  />
                }
                label="Condensor"
              />
            </ColumnLeftCheckBox>
            <ColumnRightCheckBox>
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={doorStatus}
                    onChange={handleFlavorChange}
                    name="doorStatus"
                  />
                }
                label="DoorStatus"
              />
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={insideFiltered}
                    onChange={handleFlavorChange}
                    name="insideFiltered"
                  />
                }
                label="InsideFiltered"
              />
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={insideAlarmThresholdMin}
                    onChange={handleFlavorChange}
                    name="insideAlarmThresholdMin"
                  />
                }
                label="InsideAlarmThresholdMin"
              />
            </ColumnRightCheckBox>
            <ColumnRightCheckBox>
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={insideAlarmThresholdMax}
                    onChange={handleFlavorChange}
                    name="insideAlarmThresholdMax"
                  />
                }
                label="InsideAlarmThresholdMax"
              />
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={condensorAlarmThresholdMin}
                    onChange={handleFlavorChange}
                    name="condensorAlarmThresholdMin"
                  />
                }
                label="CondensorAlarmThresholdMin"
              />
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    checked={condensorAlarmThresholdMax}
                    onChange={handleFlavorChange}
                    name="condensorAlarmThresholdMax"
                  />
                }
                label="CondensorAlarmThresholdMax"
              />
            </ColumnRightCheckBox>
          </Row>
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default CheckBoxSelection;
