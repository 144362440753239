import React from 'react';
import { noop } from 'lodash/fp';
import {
  arrayOf, string, number, func,
} from 'prop-types';

import { StyledButtonGroup, StyledButton } from './styled';

const ButtonGroup = ({ elements, activeButtonIndex, onClick }) => (
  <StyledButtonGroup color="primary">
    {
      elements.map((text, index) => (
        <StyledButton
          key={text}
          disabled={index === activeButtonIndex}
          onClick={() => onClick(index)}
        >
          {text}
        </StyledButton>
      ))
    }
  </StyledButtonGroup>
);

ButtonGroup.propTypes = {
  elements: arrayOf(string).isRequired,
  activeButtonIndex: number,
  onClick: func,
};

ButtonGroup.defaultProps = {
  activeButtonIndex: null,
  onClick: noop,
};

export default ButtonGroup;
