import { gql } from 'apollo-boost';

export const CREATE_REFILL_REQUEST = gql`
  mutation CreateRefillRequest($input: createRefillRequestInput) {
    createRefillRequest(input:$input)
  }
`;

export const UPDATE_REFILL_REQUEST = gql`
  mutation UpdateRefillRequest($input: updateRefillRequestInput) {
    updateRefillRequest(input:$input)
  }
`;
