import { withStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Button } from 'components';
import styled from 'styled-components';

export const SnackBarButton = styled(Button)`
  min-width: 40px;
`;
export const StyledSnackbar = withStyles((theme) => ({
  root: ({ type }) => ({
    width: 639,
    height: 49,
    border: `1px solid ${type === 'alert' ? theme.palette.outrageousOrange : theme.palette.lightGrayBlue}`,
    borderRadius: 4,
    boxSizing: 'border-box',
  }),
}))(Snackbar);

export const StyledSnackbarContent = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.lightGray,
    boxShadow: 'none',
    boxSizing: 'border-box',
    padding: '0 25px',
  },
  message: ({ type }) => ({
    color: type === 'alert' ? theme.palette.outrageousOrange : theme.palette.purpleBlue,
    fontFamily: theme.typography.roboto,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '20px',
    padding: 0,
  }),
  action: {
    marginRight: 0,
  },
}))(SnackbarContent);
