import styled from 'styled-components';
import { HeaderContainer, VMHeadInfo } from 'components';

export const StyledHeaderContainer = styled(HeaderContainer)`
  margin-bottom: 23px;
`;

export const StyledVMHeadInfo = styled(VMHeadInfo)`
  margin-top: 0;
  margin-bottom: 48px;
`;

export const PlaceholderContainer = styled.div`
  margin-top: 82px;
`;

export const PrintContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 17px 0 0;
`;
