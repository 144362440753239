import {
  forEach, reduce, get, isUndefined, toNumber,
} from 'lodash/fp';
import { capMap } from 'utils';

const calculateProductsAmount = (vendingMachines) => {
  const productsAmount = {};

  forEach((vendingMachine) => {
    reduce((acc, it) => {
      const productName = get(['product', 'name'], it);
      const qty = toNumber(get(['qty'], it));
      if (productName && qty) {
        acc[productName] = isUndefined(acc[productName]) ? qty : acc[productName] += qty;
      }
      return acc;
    }, productsAmount, vendingMachine.refill);
  }, vendingMachines);

  return productsAmount;
};

export const getTotalProducts = (data) => {
  const vendingMachines = get(['refillRequest', 'vendingMachines'], data);

  const productsAmount = calculateProductsAmount(vendingMachines);

  return capMap((count, name) => `${name} (${count})`, productsAmount).join(', ');
};
