import React from 'react';
import { arrayOf, string, number } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import {
  StyledStepper,
  StyledConnector,
  StyledStepLabel,
  CompleteIcon,
  DisabledIcon,
  ActiveIcon,
} from './styled';

const Stepper = ({ steps, activeStep }) => {
  const switchIcon = (iconIndex) => {
    if (iconIndex === activeStep) {
      return ActiveIcon;
    }
    return iconIndex > activeStep ? DisabledIcon : CompleteIcon;
  };

  return (
    <StyledStepper alternativeLabel activeStep={activeStep} connector={<StyledConnector />}>
      {steps.map((label, index) => (
        <Step key={label}>
          <StyledStepLabel StepIconComponent={switchIcon(index)}>
            <Typography>
              {label}
            </Typography>
          </StyledStepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
};


Stepper.propTypes = {
  steps: arrayOf(string).isRequired,
  activeStep: number,
};

Stepper.defaultProps = {
  activeStep: null,
};

export default Stepper;
