import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Placeholder } from 'components';
import { includes } from 'lodash/fp';

export const useAssignSelect = (
  values,
  defaultValue,
  name,
  onSubmit,
  assignedSlots,
  resetComplete,
) => {
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = useState(defaultValue || '');
  const [editMode, setEditMode] = useState(false);
  const [value, confirmValue] = useState(defaultValue || '');

  useEffect(() => {
    if (includes(name.split('-')[1], assignedSlots)) {
      confirmValue(selectValue);
      setEditMode(false);
      resetComplete();
    }
  }, [name, assignedSlots, resetComplete, selectValue]);

  const confirmSelect = useCallback(() => {
    onSubmit(selectValue, name.split('-')[1]);
  }, [selectValue, name, onSubmit]);

  const toggleEditMode = useCallback(() => {
    setSelectValue(value);
    setEditMode(!editMode);
  }, [editMode, value]);

  const displayValue = useCallback(() => {
    if (value === '') {
      return <Placeholder>{t('common.placeholders.product')}</Placeholder>;
    }

    return values[value] ? values[value] : defaultValue;
  }, [defaultValue, value, values, t]);

  return {
    confirmSelect,
    toggleEditMode,
    displayValue,
    setSelectValue,
    selectValue,
    value,
    editMode,
  };
};
