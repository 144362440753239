import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import { Error, ProgressWrapper } from "components";
import { convertGqlError } from "utils";
import {
  TableContainer,
  HeaderContainer,
  HeadInfo,
  InfoTitle,
} from "components";

import {
  RowSelect,
} from './styled';

import { GET_SALES_DATA } from "./queries";
import RangePicker from "./RangePicker";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { Button } from "components";


import DropDownSelect from './DropDownSelect'
import { ALL_MACHINES } from './DropDownSelect';


var machines = [];
var selectedMachine = ALL_MACHINES;


class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  generatePDF = () => {
    const doc = new jsPDF();

    autoTable(doc, { html: this.props.tableID });
    doc.text(this.props.title, 10, 10);
    doc.save("salesOverview.pdf");
  };

  render() {
    return (
      <div>
        <InfoTitle>
          <Button onClick={this.generatePDF} type="primary">
            Download
          </Button>
        </InfoTitle>
      </div>
    );
  }
}


const SalesListView = () => {
  
  const { t } = useTranslation();

  var startOfToday = new Date();
  startOfToday.setHours(0);
  startOfToday.setMinutes(0);
  startOfToday.setSeconds(0);
  var endOfToday = new Date();
  endOfToday.setHours(23);
  endOfToday.setMinutes(59);
  endOfToday.setSeconds(59);

  const [startDate, setStartDate] = useState(startOfToday);
  const [endDate, setEndDate] = useState(endOfToday);
 
  return (
    <div>  
        <RangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          startOfToday={startOfToday}
          endOfToday={endOfToday}
        ></RangePicker>
        <SalesListMTable startDate={startDate} endDate={endDate} />
    </div>
  );
};

Date.prototype.yyyy_mm_dd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("/");
};

export const SalesListMTable = (props) => {
  const { t } = useTranslation();
  const [machine, setMachine] = useState(ALL_MACHINES);
  const startDateStr = props.startDate.toISOString();
  const endDateStr = props.endDate.toISOString();
  const startDateLabel = props.startDate.yyyy_mm_dd();
  const endDateLabel = props.endDate.yyyy_mm_dd();

  //console.log('sales table has startDate=', startDateStr, 'endDate=', endDateStr)
  const { loading, error, data } = useQuery(GET_SALES_DATA, {
    variables: { startDate: startDateStr, endDate: endDateStr },
  });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;

  const products = data.salesData.products;
  console.log("products", products);

  machines = [];

  const onSelected = (selMachine) => {           
    selectedMachine = selMachine;
    setMachine(selectedMachine)
  } 

  // count products which have the same productId and price
  // Note that we take into account price so that we can handle price changes of the product correctly
  var counts = products.reduce((p, c) => {
    // normally, we take nominal price as the price of an item
    var price = c.product.nominalPrice;

    if( machines.indexOf(c.product.machineId) === -1 ){
      machines.push(c.product.machineId) 
    }

    if(( c.product.machineId === selectedMachine ) || ( selectedMachine === ALL_MACHINES )){

      // exception to the above rule:
      if (c.product.discountType === "99") {
        price = c.product.salesPrice;
      }

      var k = c.product.productId + "__" + price;

      if (!p.hasOwnProperty(k)) {
        p[k] = {
          counts: 0,
          price: price,
          productName: c.product.name,
          vendorName: c.product.vendorName,
          sales_vm: 0,
          sales_webshop: 0,
          sales_all: 0,
        };
      }
      // discard any sales which are not by vm or by webshop (e.g., admin)
      if (c.reservedBy?.role === "vm") {
        p[k]["sales_vm"] += c.product.amount * price;
        p[k]["counts"] += c.product.amount; //take into account "amount", in case it is different from '1'
        p[k]["sales_all"] += c.product.amount * price;
      } else if (c.reservedBy?.role === "webshop") {
        p[k]["sales_webshop"] += c.product.amount * price;
        p[k]["counts"] += c.product.amount; //take into account "amount", in case it is different from '1'
        p[k]["sales_all"] += c.product.amount * price;
      }

    }

    return p;
  }, {});

  //console.log('counts', counts);

  var tableData = Object.keys(counts).map((k) => {
    return {
      count: counts[k]["counts"],
      price: counts[k]["price"],
      productName: counts[k]["productName"],
      vendorName: counts[k]["vendorName"],
      sales_vm: counts[k]["sales_vm"],
      sales_webshop: counts[k]["sales_webshop"],
      total: counts[k]["sales_all"],
    };
  });
  //console.log('tableData', tableData);

  var vendorTables = tableData.reduce(function (r, a) {
    r[a.vendorName] = r[a.vendorName] || [];
    r[a.vendorName].push(a);
    return r;
  }, Object.create(null));

  // sort each vendor table on product name
  Object.keys(vendorTables).map((vendor, index) =>
    vendorTables[vendor].sort((a, b) =>
      a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
    )
  );

  // console.log('vendorTables', vendorTables);

  function grandTotal(items) {
    return items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
  }
  function grandTotal_vm(items) {
    return items.map(({ sales_vm }) => sales_vm).reduce((sum, i) => sum + i, 0);
  }
  function grandTotal_webshop(items) {
    return items
      .map(({ sales_webshop }) => sales_webshop)
      .reduce((sum, i) => sum + i, 0);
  }

  const vendorTotals = Object.keys(vendorTables).map((vendor) =>
    grandTotal(vendorTables[vendor])
  );
  const vendorTotals_vm = Object.keys(vendorTables).map((vendor) =>
    grandTotal_vm(vendorTables[vendor])
  );
  const vendorTotals_webshop = Object.keys(vendorTables).map((vendor) =>
    grandTotal_webshop(vendorTables[vendor])
  );
  // console.log('vendorTotals', vendorTotals)

  return (
    <div>
      { machines.length > 0 &&
        <RowSelect>
          <DropDownSelect machines={machines} onSelected={onSelected} />
        </RowSelect>
      }
      {Object.keys(vendorTables).map((vendor, index1) => (
        <div>
          <TableContainer>
            <HeaderContainer>
              <HeadInfo
                title={
                  t('sales.vendorName') + " : " + vendor + ", " + t('sales.dateRange') + " : " + startDateLabel + " - " + endDateLabel + ", " +  t('sales.machine') + " : " + selectedMachine
                }
              >
                {" "}
              </HeadInfo>
            </HeaderContainer>
            <Table
              id={"sales-overview-table" + index1}
              sx={{ minWidth: 650 }}
              aria-label="sales table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("sales.productName")}
                  </TableCell>
                  <TableCell align="right">{t("sales.price")}</TableCell>
                  <TableCell align="right">{t("sales.count")}</TableCell>
                  <TableCell align="right">{t("sales.sales_vm")}</TableCell>
                  <TableCell align="right">
                    {t("sales.sales_webshop")}
                  </TableCell>
                  <TableCell align="right">{t("sales.totalPrice")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorTables[vendor].map((row, index2) => (
                  <TableRow
                    key={index2}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {" "}
                      {row.productName}
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(row.price).toFixed(2)}
                    </TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                    <TableCell align="right">
                      {row.sales_vm.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {row.sales_webshop.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {(row.price * row.count).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>{t("sales.vendorVmTotal")}</TableCell>
                  <TableCell align="right">
                    {vendorTotals_vm[index1].toFixed(2)}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3}>
                    {t("sales.vendorWebshopTotal")}
                  </TableCell>
                  <TableCell align="right">
                    {vendorTotals_webshop[index1].toFixed(2)}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>{t("sales.vendorTotal")}</TableCell>
                  <TableCell align="right">
                    {vendorTotals[index1].toFixed(2)}{" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <DownloadButton
            title={vendor + " (" + startDateLabel + " - " + endDateLabel + ")"} tableID={"#sales-overview-table"+index1}
          />
        </div>
      ))}
    </div>
  );
};

export default SalesListView;
