import { gql } from 'apollo-boost';

export const REFILL_REQUESTS = gql`
  query RefillRequests {
    refillRequests {
      totalCount
      nodes {
        id: _id
        status
        vmQuantity
        refillQuantity
        vendor {
          id: _id
          companyName
          address
          addressForRefill
        }
      }
    }
  }
`;

export const READY_FOR_FETCH = gql`
  mutation{
    fetchRefillRequest{
      totalCount
      nodes{
        id: _id
        status
        vmQuantity
        refillQuantity
        vendor {
          id: _id
          companyName
          address
          addressForRefill
        }
      }
    }
  }
`;


export const REFILL_REQUEST = gql`
  query RefillRequest($input: refillRequestInput!) {
    refillRequest(input: $input) {
      id: _id
      status
      vendingMachines {
        id: _id
        name
        address
        machineId
        refill {
          productItems
          product {
            name
          }
          qty
          actualQty
        }
      }
      vendor{
        id: _id
        companyName
        address
      }
    }
  }
`;
