import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledTabs = withStyles(() => ({
  root: {
    minWidth: 220,
    overflow: 'unset',
  },
  indicator: {
    display: 'none',
  },
  scroller: {
    overflow: 'unset !important',
  },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'unset',
    height: 56,
    color: theme.palette.purpleBlue,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: -0.11,
    lineHeight: '17px',
    textTransform: 'none',
    width: 220,

    '&:hover:not(.Mui-selected)': {
      background: theme.palette.lightGray,
      color: theme.palette.greenBlueLightened,
    },
  },
  selected: {
    color: theme.palette.greenBlueLightened,
    border: 'none',
    borderLeft: '4px solid #59D8B3',
    borderBottomLeftRadius: 3,
    borderTopLeftRadius: 3,
    '&:after': {
      position: 'absolute',
      content: '""',
      width: '2px',
      height: '100%',
      top: 0,
      right: '-2px',
      background: `${theme.palette.white}`,
    },
  },
  wrapper: {
    alignItems: 'flex-start',
    paddingLeft: 10,
  },
}))(Tab);

export const WrappedTab = withStyles((theme) => ({
  selected: {
    border: `1px solid ${theme.palette.lightGrayBlue}`,
  },
}))(StyledTab);
