import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import FormControl from '@material-ui/core/FormControl';

import { StyledSelect, StyledMenuItem } from './styled';


const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, selectLanguage] = useState(i18n.language);

  const languagesList = useMemo(
    () => Object.keys(i18n.services.resourceStore.data),
    [i18n.services.resourceStore.data],
  );

  useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return (
    <FormControl>
      <StyledSelect
        value={language}
        onChange={(e) => selectLanguage(e.target.value)}
        disableUnderline
      >
        {map(languagesList, (lng) => (
          <StyledMenuItem value={lng} key={lng}>{lng}</StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default LanguageSelector;
