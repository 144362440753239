import React, { Fragment } from 'react';
import { map, isEmpty } from 'lodash/fp';
import {
  arrayOf, shape, func, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';

import {
  ContentBox,
  HeaderContainer,
  Table,
  Button,
  VMTableContainer,
  NoContentPlaceholder,
  HeadContainer,
  InfoContainer,
  StyledHeadInfo,
} from 'components';

import { ROUTES } from 'configs';

const SlotsList = ({
  data, config, t, requestAvailable,
}) => (
  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('vendorSlots.title')}</h1>
      </HeaderContainer>
    </ContentBox>
    {map(({
      product, vendingMachines, overallCapacity,
      overallQuantity, emptyPlaces, overallEjectedSinceLastRefill,
    }) => (
      <Fragment key={product.id}>
        <ContentBox>
          <h1>{product.name}</h1>
          <HeadContainer>
            <InfoContainer>
              <StyledHeadInfo
                title={t('vendorRefill.edit.overallQuantity')}
                value={overallQuantity}
              />
              <StyledHeadInfo
                title={t('vendorRefill.edit.overallCapacity')}
                value={overallCapacity}
              />
              <StyledHeadInfo
                title={t('vendorRefill.edit.emptyPlaces')}
                value={emptyPlaces}
              />
              <StyledHeadInfo
                title={t('vendorRefill.edit.overallEjectedSinceLastRefill')}
                value={overallEjectedSinceLastRefill}
              />
            </InfoContainer>
          </HeadContainer>
        </ContentBox>
        <VMTableContainer>
          <Table
            config={config}
            data={vendingMachines}
          />
        </VMTableContainer>
      </Fragment>
    ), data)}
    {isEmpty(data) && (
      <NoContentPlaceholder placeholder={t('common.placeholders.bookedSlots')} />
    )}
  </>
);

SlotsList.propTypes = {
  data: arrayOf(shape({})),
  config: shape({}).isRequired,
  t: func.isRequired,
  requestAvailable: bool,
};

SlotsList.defaultProps = {
  data: [],
  requestAvailable: true,
};

export default SlotsList;
