import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressWrapper, Error } from 'components';

import { useVendorRefillView } from './hooks';

import { getConfig } from './tableConfig';
import ViewRefillRequest from './ViewRefillRequest';


const RefillRequestContainer = () => {
  const { t } = useTranslation();
  const {
    loading, error, networkStatus, ...viewProps
  } = useVendorRefillView();

  if (loading || networkStatus === 4) return <ProgressWrapper />;
  if (error) return <Error code={error.code} />;

  const props = {
    t,
    config: getConfig(t),
    ...viewProps,
  };

  return (
    <ViewRefillRequest {...props} />
  );
};

export default RefillRequestContainer;
