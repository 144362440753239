// This file manages the pagination for the productTable functionality
//
const LOCALSTORAGE_PAGINATION = 'productTablePagination';
const LOCALSTORAGE_PERPAGE = 50;

export function firstPagination() {
  const paginationText = sessionStorage.getItem(LOCALSTORAGE_PAGINATION);
  try {
    const temp = JSON.parse(paginationText);
    if ((temp != null) && (temp.categoryId !== undefined) && (temp.perPage !== undefined)
         && (temp.page !== undefined) && (temp.sortDirection !== undefined)
         && (temp.rowLink === true)) {
      return temp;
    }
  } catch (error) {
    // console.log(error);
  }
  const retval = {
    categoryId: null, perPage: LOCALSTORAGE_PERPAGE, page: 0, sortDirection: 'asc', rowLink: false,
  };
  sessionStorage.setItem(LOCALSTORAGE_PAGINATION, JSON.stringify(retval));
  return retval;
}

export function getPagination(categoryId) {
  const paginationText = sessionStorage.getItem(LOCALSTORAGE_PAGINATION);

  let retval = {
    categoryId: null, perPage: LOCALSTORAGE_PERPAGE, page: 0, sortDirection: 'asc', rowLink: false,
  };
  try {
    const temp = JSON.parse(paginationText);
    if ((temp != null) && (temp.categoryId !== undefined) && (temp.perPage !== undefined)
        && (temp.page !== undefined) && (temp.sortDirection !== undefined)) {

      if (temp.rowLink === true) {
        retval = temp;
      } else if (categoryId !== temp.categoryId) {
        retval.categoryId = categoryId;
        retval.perPage = temp.perPage;
        retval.sortDirection = temp.sortDirection;
      }
    }
  } catch (error) {
    // console.log(error);
  }
  return retval;
}

export function confirmPagination() {
  const paginationText = sessionStorage.getItem(LOCALSTORAGE_PAGINATION);

  try {
    const temp = JSON.parse(paginationText);
    if ((temp != null) && (temp.rowLink === false)) {
      temp.rowLink = true;
      sessionStorage.setItem(LOCALSTORAGE_PAGINATION, JSON.stringify(temp));
    }
  } catch (error) {
    // console.log(error);
  }
}

export function setPagination(categoryId, perPage, page, sortDirection) {
  const pagination = {
    categoryId, perPage, page, sortDirection, rowLink: false,
  };
  sessionStorage.setItem(LOCALSTORAGE_PAGINATION, JSON.stringify(pagination));
}
