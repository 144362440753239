import React, { useState,useRef } from "react";
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { VENDORS_LIST } from '../../Vendors/queries';
import { useQuery } from '@apollo/react-hooks';
import { Error, ProgressWrapper } from 'components';
import { convertGqlError } from 'utils';


const StyledRadio = withStyles({
  root: {
    color: "#13c552",
    "&$checked": {
      color: "#13c552"
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);


const ALL_VENDORS = 'All';


var options        = [];
var handleAction   = false;
var open           = false;
var selectedVendor = ALL_VENDORS;


const FilterDialog = (props) => {
  const [value, setValue] = useState(ALL_VENDORS);
  const radioGroupRef = useRef(null);

  open = props.open;

  
  const {loading, error, data } = useQuery( VENDORS_LIST, { fetchPolicy: 'cache-and-network'});
  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />; 

  // fill radio list with vendors but only at opening
  if(( open === true ) && ( handleAction === false )){

    const obj = {'name':ALL_VENDORS, 'id':0, 'checked':true};  
    options.push(obj);

    for( let i = 0; i<data.accounts.nodes.length; i++){  
      const obj = {'name':data.accounts.nodes[i].companyName, 'id':data.accounts.nodes[i].id , 'checked':false};  
      options.push(obj);
    }
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleChange = (event) => {
    handleAction = true;
    selectedVendor = event.target.value;
    setValue(event.target.value);
  };
  
  const handleCancel = () => {
    props.onCancelButton();
  };


  const handleOk = () => {
    if( selectedVendor === ALL_VENDORS ){
      props.onOkButton( null );
    }else{
      let vendorId;
      for( let i=0; i<options.length; i++){
        if (options[i].name === selectedVendor) {
          vendorId = options[i].id;
          break;
        }    
      };
      props.onOkButton( vendorId ); 
    }    
  };

  return (
    <Dialog 
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>Vendors</DialogTitle>
      <DialogContent style={{height:'400px', width:'400px'}}>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="vendor"
          name="vendor"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.name}
              key={option.id}
              control={<StyledRadio />}
              label={option.name}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;




/*   

Checkbox version 

- This version need an update of the graphQl querry products
  at the moment we can only set one vendorId and not an array of vendorId's






import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";

import { VENDORS_LIST } from '../../Vendors/queries';
import { useQuery } from '@apollo/react-hooks';
import { Error, ProgressWrapper } from 'components';
import { convertGqlError } from 'utils';


const StyledCheckbox = withStyles({
  root: {
    color: "#13c552",
    "&$checked": {
      color: "#13c552"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);


var options      = [];
var handleAction = false;
var selectedAll  = false;
var open         = false;


const FilterDialog = (props) => {
  const [flavors, setFlavors] = useState( options );

  options = flavors;
  open    = props.open;


  const {loading, error, data } = useQuery( VENDORS_LIST, { fetchPolicy: 'cache-and-network'});
  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />; 

  // fill checkbox list with vendors but only at opening
  if(( open == true ) && ( handleAction == false )){
    for( let i = 0; i<data.accounts.nodes.length; i++){  
      const obj = {'name':data.accounts.nodes[i].companyName, 'id':data.accounts.nodes[i].id, 'checked':true};  
      options.push(obj);
    }
  }

  const handleSetAll = () => {
    handleAction = true;
    selectedAll  = true;
    setFlavors(current =>
      current.map(obj => {
        return {...obj, checked: true};
      }),
    );
  };

  const handleRemoveAll = () => {
    handleAction = true;
    selectedAll  = false;
    setFlavors(current =>
      current.map(obj => {
        return {...obj, checked: false};
      }),
    );
  };

  const handleChange = (event) => {
    handleAction = true;
    setFlavors(current =>
      current.map(obj => {
        if (obj.name === event.target.name) {
          return {...obj, checked: event.target.checked};
        }
        return obj;
      }),
    );
  };
  
  const handleCancel = () => {
    props.onCancelButton();
  };

  const handleOk = () => {
    if( selectedAll ){
      props.onOkButton( null );
    }else{
      let k = 0;
      let selectedVendor = [];
      for( let i=0; i<options.length; i++){
        if (options[i].checked == true) {
          selectedVendor[k] = options[i].id;
          k++;
        }    
      };
      props.onOkButton( selectedVendor[0] );  // TODO AE graphQl querry products must handle list of vendorId's
    }    
  };


  return (
    <Dialog 
      open={open}
    >
      <DialogTitle>Vendors</DialogTitle>
      <DialogContent style={{height:'400px', width:'400px'}}> 
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          {options.map( option => (
            <FormControlLabel key={option.id}
              control={<StyledCheckbox checked={option.checked} onChange={handleChange} name={option.name}/>} 
              label={option.name}
            />
          ))}         
        </FormGroup> 
      </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSetAll}>Select all</Button>
        <Button autoFocus onClick={handleRemoveAll}>Remove all</Button>
        <Button autoFocus onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;
*/

