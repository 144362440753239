import styled from 'styled-components';


export const PageContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled.div`
  width: 90%;
  heigth: 1px;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0px;
  border-bottom: 1px #000 solid;
`;

export const ContentContainer1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;

  @media (min-width: 100px) {
    flex-direction: column;
  }
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const ContentContainer2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;
  

  @media (min-width: 100px) {
    flex-direction: column;
  }
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const Title = styled.h1`
  @media (min-width: 100px) {
    margin-top: 10px;
    font-size: 24px;
    margin-left: 0;
    text-align: left;
  }
  @media (min-width: 800px) {
    margin-top: 20px;
    font-size: 36px;
    margin-left: 0px;
    text-align: left;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  @media (min-width: 100px) {
    flex-direction: column;
  }
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const ColumnSelectMachines = styled.div`
  flex-direction: column;
  align-items: left;
  text-align: left;
  height: 50px; 
  width: 100px;
  margin-top: 10px;

  @media (min-width: 100px) {
    margin-left: 20px;
  }
  @media (min-width: 800px) {
    margin-left: 300px;
  }
`;

export const ColumnRangePicker = styled.div`
  flex-direction: column;
  align-items: left;
  text-align: left;
  height: 50px; 
  width: 600px;

  @media (min-width: 100px) {
    margin-left: 0px;
  }
  @media (min-width: 800px) {
    margin-left: 100px;
  }
`;

export const ColumnCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: left;
  text-align: left;
  height: 50px; 
  
  @media (min-width: 100px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (min-width: 800px) {
    margin-left: 150px;

    margin-bottom: 10px;
  }
`;

export const ColumnLeftDateTimePicker = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: left;
  text-align: left;
  height: 50px; 

  @media (min-width: 100px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (min-width: 800px) {
    margin-left: 50px;

    margin-bottom: 10px;
  }
`;

export const ColumnRightDateTimePicker  = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: left;
  text-align: left;

  @media (min-width: 100px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (min-width: 800px) {

    margin-bottom: 10px;
  }
`;

export const ColumnLeftCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: left;
  text-align: left;
  height: 50px; 

  @media (min-width: 100px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (min-width: 800px) {
    margin-left: 500px;

    margin-bottom: 10px;
  }
`;

export const ColumnRightCheckBox  = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: left;
  text-align: left;

  @media (min-width: 100px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (min-width: 800px) {
    margin-left: 50px;
    margin-bottom: 10px;
  }
`;

export const ColumnSamplingRate = styled.div`
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin-top: 10px;
  width: 300px;

  @media (min-width: 100px) {
    margin-left: 50px;
  }
  @media (min-width: 800px) {
    margin-left: 10px;
  }
`;

export const ColumnButton = styled.div`
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin-top: 20px;
  width: 400px;

  @media (min-width: 100px) {
    margin-left: 10px;
  }
  @media (min-width: 800px) {
    margin-left: 10px;
  }
`;

export const StyledButton = styled.button`
  background-color: #13c552;
  color: white;
  height: 40px; 
  width: 100px;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 2px solid;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    border-color: green;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

