import {
  useState, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

export const useInput = (type, errorText, error) => {
  const [currentType, setCurrentType] = useState(type);
  const { t } = useTranslation();

  const changeType = useCallback(() => setCurrentType(currentType === 'password' ? 'text' : 'password'), [currentType, setCurrentType]);

  const inputErrorText = useMemo(() => {
    if (errorText) return errorText;
    if (error) return t(`formErrors.${error}`);
    return null;
  }, [errorText, error, t]);

  return {
    currentType,
    changeType,
    inputErrorText,
  };
};
