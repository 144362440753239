import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavigationContainer = styled.nav`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const StyledNavLink = styled(NavLink).attrs(() => ({
  activeClassName: 'active',
}))`
  display: flex;
  align-items: center;
  margin: 0 20px;
  padding: 0 5px;
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.purpleBlue};
  font-size: 16px;
  border-top: 4px solid transparent;

  &.active {
    color: ${({ theme }) => theme.palette.greenBlueLightened};
    border-color: ${({ theme }) => theme.palette.greenBlueLightened};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.greenBlueLightened};
  }
`;

export const StyledSubNavLink = styled(StyledNavLink)`
  font-size: 15px;
  border-top: none;
  border-bottom: 4px solid transparent;
`;

export const SubNavigationContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background: ${({ theme }) => theme.palette.lightGray};
  background: ${({ theme }) => (
    `linear-gradient(0deg, ${theme.palette.lightGray}, ${theme.palette.white})`
  )};
  border-top: 1px solid ${({ theme }) => theme.palette.lightGrayBlue};
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrayBlue};;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.18);
`;
