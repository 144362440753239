const host = process.env.REACT_APP_API_HOST || 'http://34.89.177.69:8000';

export const getPictureUrl = (picture, enlarge = false) => {
  if (!picture) return '';

  const {
    signature, gravity, encodedUrl, extension, resizingType, width, height
  } = picture;

  return `${host}/images/${signature}/${resizingType}/${width}/${height}/${gravity}/${enlarge}/${encodedUrl}.${extension.toLowerCase()}`;
};
