import React from 'react';
import { string, shape } from 'prop-types';

import { ERRORS } from 'configs';

import NotFound from './NotFound';
import UnhandledError from './UnhandledError';

const Error = ({ code, props }) => {
  switch (code) {
    case ERRORS.NOT_FOUND:
    case ERRORS.BAD_USER_INPUT:
      return (
        <NotFound {...props} />
      );
    case ERRORS.INTERNAL_SERVER_ERROR:
      return (
        <UnhandledError {...props} />
      );
    default:
      return <UnhandledError code={code} {...props} />;
  }
};

Error.propTypes = {
  code: string,
  props: shape({}),
};

Error.defaultProps = {
  code: '',
  props: {},
};

export default Error;
