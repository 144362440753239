import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

import { CancelIcon, ConfirmIcon, EditIcon } from 'static/icons';

export const ProgressContainer = styled.div`
  margin-left: 10px;
  min-width: 62px;
`;

export const StyledSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginBottom: '5px',
  },
  select: {
    '&:focus': {
      background: 'none',
    },
  },
}))(Select);

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  width: 26px;
  height: 26px;
  margin-left: 10px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

`;

export const iconStyles = () => `
  width: 26px;
  height: 26px;
`;

export const StyledEditIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  path {
    fill: ${({ theme }) => theme.palette.grayBlue};
  }
`;

export const StyledConfirmIcon = styled(ConfirmIcon)`
  ${iconStyles};
  path {
    fill: ${({ theme, disabled }) => (disabled ? theme.palette.lightGrayBlue : theme.palette.white)};
  }
  circle {
    fill: ${({ theme, disabled }) => (disabled ? theme.palette.lightGray : theme.palette.greenBlueLightened)};
    stroke: ${({ theme, disabled }) => (disabled ? theme.palette.lightGray : theme.palette.greenBlueLightened)};
  }
`;

export const StyledCancelIcon = styled(CancelIcon)`
  ${iconStyles};
  path {
    fill: ${({ theme }) => theme.palette.grayBlue};
  }
`;
