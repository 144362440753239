import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
`;

export const LabelContainer = styled.div`
  width: 33%;
  min-width: 250px;
  display: flex;
  align-items: baseline;
`;

export const Label = styled.span`
  padding-left: 15px;
  color:  ${({ theme }) => theme.palette.purpleGray};
  font-family: ${({ theme }) => theme.typography.roboto};
  font-size: 16px;
  letter-spacing: -0.11px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
`;

export const InfoText = styled.div`
  width: 67%;
  display: flex;
  align-items: baseline;
  padding-left: ${({ leftIndent }) => leftIndent || 'unset'};

  font-family: ${({ theme }) => theme.typography.roboto};
  color: ${({ theme }) => theme.palette.lightPurple};
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 22px;
`;

export const InfoTextMultiLine = styled.div`
  width: 67%;
  display: flex;
  align-items: baseline;
  padding-left: ${({ leftIndent }) => leftIndent || 'unset'};

  font-family: ${({ theme }) => theme.typography.roboto};
  color: ${({ theme }) => theme.palette.lightPurple};
  font-size: 16px;
  letter-spacing: -0.13px;
  line-height: 22px;
  white-space: pre-wrap;
`;

export const styleIcon = (icon) => styled(icon)`
    width: 17px;
    height: 17px;
    fill: ${({ theme }) => theme.palette.primary.main};
    position: relative;
    top: 4px;
`;
