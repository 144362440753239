import React from 'react';
import {
  bool, node, number, oneOf,
} from 'prop-types';
import { TabPanelContainer } from './styled';

const TabPanel = ({
  children, value, index, withBorder, displayType,
}) => (
  displayType !== 'destroy' || value === index ? (
    <TabPanelContainer withBorder={withBorder} isVisible={value === index}>
      {children}
    </TabPanelContainer>
  ) : null
);

TabPanel.propTypes = {
  children: node.isRequired,
  value: number,
  index: number,
  withBorder: bool,
  displayType: oneOf(['hide', 'destroy']),
};

TabPanel.defaultProps = {
  value: 0,
  index: 0,
  withBorder: false,
  displayType: 'hide',
};

export default TabPanel;
