import React from 'react';
import { string } from 'prop-types';

import { StyledLink } from './styled';

const Link = (props) => (
  <StyledLink {...props} />
);

Link.propTypes = {
  to: string,
  size: string,
};

Link.defaultProps = {
  to: '/',
  size: '16px',
};

export default Link;
