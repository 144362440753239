import React from 'react';
import { shape } from 'prop-types';
import { get } from 'lodash/fp';
import { ProgressWrapper } from 'components';

import { getPictureUrl } from 'utils';
import { useEditProductType } from './hooks';
import EditProduct from './Product';

const ProductEditContainer = ({ match }) => {
  const id = get(['params', 'id'], match);
  const {
    loading, vendors, categories, onSave, t, ...rest
  } = useEditProductType(id);

  if (loading) return <ProgressWrapper />;

  const props = {
    t,
    ...rest,
    picture:
      rest.productData.picture ? getPictureUrl(rest.productData.picture, true) : null,
    categories,
    vendors,
    onSave,
  };

  return <EditProduct {...props} />;
};

ProductEditContainer.propTypes = {
  match: shape({}),
};

ProductEditContainer.defaultProps = {
  match: {},
};

export default ProductEditContainer;
