import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export const GlobalStyles = createGlobalStyle`
  html, body, div#root {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.palette.purpleBlue};
    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
  }
  h1 {
    font-size: 28px;
    line-height: 38px;
  }  
  h2 {
    font-size: 24px;
    line-height: 33px;
  }  
  h3 {
    font-size: 22px;
    line-height: 32px;
  }  
  h4 {
    font-size: 20px;
    line-height: 24px;
  }  
  h5 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.14px;
  } 
  h6 {
    font-size: 16px;
    line-height: 16px;
  }
  p {
    color: ${theme.palette.lightPurple};
    font-family: ${theme.typography.robotoMedium};
    font-weight: 500;
    margin: 0;
    font-size: 16px;
  }
  p[variant="primary"] {
    font-size: 16px;
    letter-spacing: -0.12px;
    line-height: 22px;
  }
  p[variant="secondary"] {
    font-size: 13px;
    letter-spacing: -0.1px;
    line-height: 16px;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
`;
