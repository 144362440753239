import React from 'react';
import { func, shape } from 'prop-types';
import { noop } from 'lodash/fp';

import { TextInput } from 'components';

import AddButton from './AddButton';
import InputControlls from './InputControlls';

import { useCustomInputs } from './hooks';
import { CustomInputContainer } from './styled';

const CustomInputs = ({ register, defaultValues }) => {
  const {
    inputNames, handleAdd, handleRemove, handleEdit,
  } = useCustomInputs(defaultValues);

  return (
    <>
      {inputNames.map((name) => (
        <CustomInputContainer key={name}>
          <TextInput
            name={`additionalFields.${name}`}
            label={name}
            register={register}
            defaultValue={defaultValues[name]}
          />
          <InputControlls
            name={name}
            onEdit={handleEdit(name)}
            onRemove={handleRemove(name)}
          />
        </CustomInputContainer>
      ))}
      <AddButton onAdd={handleAdd} />
    </>
  );
};
CustomInputs.propTypes = {
  register: func,
  defaultValues: shape({}),
};

CustomInputs.defaultProps = {
  register: noop,
  defaultValues: {},
};

export default CustomInputs;
