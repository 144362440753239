import { gql } from 'apollo-boost';


export const GET_TEMPERATURE_DATA = gql`
  query vmTemperatureQuery( $machineId: String!, $startDate : String!, $endDate : String!, $samplingRate : Int! ){
    vmTemperature(input: {
    machineId    : $machineId,
    startDate    : $startDate,
    endDate      : $endDate,
    samplingRate : $samplingRate,
  }) {
    vmId
    machineId
    values
    {
      timeStamp
      inside
      outside
      condensor
      insideFiltered
      doorStatus
      insideAlarmThresholdMin
      insideAlarmThresholdMax
      condensorAlarmThresholdMin
      condensorAlarmThresholdMax
    }
  }
}

`;
