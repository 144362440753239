import { useCallback } from 'react';
import {
  useRefillRequestView, useSnackBar,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  split, toNumber, noop, get, map, isString,
} from 'lodash/fp';
import { useMutation } from '@apollo/react-hooks';

import { formatISO, parseISO } from 'date-fns';


import { capReduce } from 'utils/lodash';
import { onFormError } from 'utils';
import { ROUTES, ERRORS, REFILL_STATUSES } from 'configs';

import { CREATE_REFILL_REQUEST, UPDATE_REFILL_REQUEST } from './queries';
import { getConfig } from './tableConfig';

export const useCreateRefillRequest = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const history = useHistory();
  const {
    stockOverview, loadingStockOverview, stockOverviewsError,
  } = useRefillRequestView();
  const { register, handleSubmit, setValue } = useForm();
  const [createRefillRequest] = useMutation(CREATE_REFILL_REQUEST, {
    onError: onFormError(t, noop, openBar),
    onCompleted: () => history.push(ROUTES.REFILL),
  });

  const onSubmit = useCallback(handleSubmit(({ date, ...items }) => {
    const data = capReduce((acc, item, key) => {
      if (toNumber(item)) {
        const [productId, vendingMachine] = split('-', key).slice(1, 3);

        acc.push({
          productId,
          vendingMachine,
          expDate: formatISO(date[productId]),
          qty: toNumber(item),
        });
      }
      return acc;
    }, [], items);

    if (data.length) {
      createRefillRequest({
        variables: {
          input: { refillItems: data },
        },
      });
    }
  }), [handleSubmit, createRefillRequest]);

  const data = map((product) => ({
    ...product,
    vendingMachines: map((item) => ({
      ...item,
      id: `${product.id}-${item.id}`,
    }), product.vendingMachines),
  }), stockOverview);

  return {
    t,
    loading: loadingStockOverview,
    data,
    onSubmit,
    register,
    setValue,
    config: getConfig(t, register, setValue),
    errorCode: stockOverviewsError,
  };
};

export const useEditRefillRequest = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const history = useHistory();
  const {
    stockOverview, loadingStockOverview, stockOverviewsError, refillRequest,
  } = useRefillRequestView();
  const { register, handleSubmit, setValue } = useForm();
  const [updateRefillRequest] = useMutation(UPDATE_REFILL_REQUEST, {
    onError: onFormError(t, noop, openBar),
    onCompleted: () => history.push(ROUTES.REFILL),
  });

  const onSubmit = useCallback(handleSubmit(({ date, ...items }) => {
    const data = capReduce((acc, item, key) => {
      if (toNumber(item)) {
        const [productId, vendingMachine] = split('-', key).slice(1, 3);

        const dateTimePickerValue = date[productId];

        const actualDate = isString(dateTimePickerValue)
          ? formatISO(parseISO(dateTimePickerValue))
          : formatISO(date[productId]);

        acc.push({
          productId,
          vendingMachine,
          expDate: actualDate,
          qty: toNumber(item),
        });
      }
      return acc;
    }, [], items);

    if (data.length) {
      updateRefillRequest({
        variables: {
          input: { refillItems: data },
        },
      });
    }
  }), [updateRefillRequest]);

  const data = map((product) => ({
    ...product,
    vendingMachines: map((item) => ({
      ...item,
      id: `${product.id}-${item.id}`,
    }), product.vendingMachines),
  }), stockOverview);

  const isEditAvalable = get(['status'], refillRequest) === REFILL_STATUSES.NEW;

  const errorCode = stockOverviewsError
    || (!isEditAvalable && ERRORS.CANT_EDIT_REFILL_REUEST);

  return {
    t,
    loading: loadingStockOverview,
    data,
    onSubmit,
    register,
    setValue,
    config: getConfig(t, register, setValue),
    errorCode,
  };
};
