import React from 'react';

import { ROUTES, NESTED_ROUTES } from 'configs';
import {
  PageWrapper, LightWrapper, SubNavigationBar,
} from 'components';

import Router from './router';

const SalesPage = () => (
  <PageWrapper>
    <SubNavigationBar
      routes={[
        { to: `${ROUTES.SALES}/${NESTED_ROUTES.OVERVIEW}`, name: 'Overview' },
        { to: `${ROUTES.SALES}/${NESTED_ROUTES.DETAILS}`, name: 'Details' },
      ]}
    />
    <LightWrapper>
      <Router />
    </LightWrapper>
  </PageWrapper>
);

export default SalesPage;
