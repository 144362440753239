import React from 'react';
import { string } from 'prop-types';

import { Container, Title } from './styled';

const HeadInfo = ({ title, value, ...props }) => (
  <Container {...props}>
    <Title>
      {title}
    </Title>
    {value}
  </Container>
);

HeadInfo.propTypes = {
  title: string,
  value: string,
};

HeadInfo.defaultProps = {
  title: '',
  value: '',
};

export default HeadInfo;
