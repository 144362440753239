import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

export const StyledButton = withStyles((theme) => ({
  root: {
    minWidth: '216px',
    maxWidth: '100%',
    minHeight: 48,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.roboto,
    letterSpacing: 0.5,
    boxShadow: 'none',
    whiteSpace: 'nowrap',

    '&.Mui-disabled': {
      color: theme.palette.ghost,
      backgroundColor: theme.palette.lightGray,
      border: 'none',
    },
  },

  containedPrimary: {
    color: theme.palette.white,
    border: 'none',
    borderRadius: 4,
    backgroundColor: theme.palette.green,
    '&:hover': {
      backgroundColor: theme.palette.darkGreen,
      boxShadow: theme.shadow.primaryHover,
    },
    '&:active': {
      backgroundColor: theme.palette.darkGreen,
      boxShadow: theme.shadow.primaryActive,
    },
    '&:focus': {
      backgroundColor: theme.palette.darkGreen,
      boxShadow: theme.shadow.primaryFocus,
    },
  },
  containedSecondary: {
    color: theme.palette.lynch,
    border: `1px solid ${theme.palette.lightGrayBlue}`,
    borderRadius: 3,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.greenBlueDarkened,
      backgroundColor: 'transparent',
      borderColor: theme.palette.green,
      boxShadow: 'none',
    },
    '&:active': {
      color: theme.palette.greenBlueDarkened,
      backgroundColor: theme.palette.lightGreen,
      borderColor: theme.palette.green,
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.grey,
      boxShadow: theme.shadow.secondaryFocus,
    },
  },
  textPrimary: {
    color: theme.palette.greenBlueLightened,
    fontFamily: theme.typography.robotoMedium,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textSecondary: {
    color: theme.palette.grayBlue,
    fontFamily: theme.typography.robotoMedium,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(Button);
