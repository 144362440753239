import { CELL_TYPE_HIGHLIGHTED, CELL_TYPE_ARROW } from 'components/Table/constants';
import { ROUTES } from 'configs';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'companyName',
      label: t('vendor.list.table.name'),
      type: CELL_TYPE_HIGHLIGHTED,
      key: 'companyName',
      sortable: true,
    },
    {
      id: 'contact',
      label: t('vendor.list.table.contact'),
      key: 'contact',
    },
    {
      id: 'contactEmail',
      label: t('vendor.list.table.email'),
      key: 'contactEmail',
    },
    {
      id: 'phone',
      label: t('vendor.list.table.phone'),
      key: 'phone',
    },
    {
      id: 'address',
      label: t('vendor.list.table.address'),
      key: 'address',
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ],
  rowLink: (data) => `${ROUTES.VENDORS}/${data.id}`,
});
