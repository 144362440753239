import { gql } from 'apollo-boost';

export const REFILL_REQUEST = gql`
query {
  ownRefillRequest{
    id: _id
    status
    vendingMachines{
      id: _id
      name
      address
      machineId
      slots{
        id: _id
        numberId
        productItems{
          id: _id
        }
        product{
          id: _id
          name
        }
        refill{
          qty
          product{
            id: _id
            name
          }
        }
      }
    }
  }
}
`;

export const REMOVE_REFILL_REQUEST = gql`
mutation removeRefillRequest ($input: removeRefillRequestInput){
  removeRefillRequest(input: $input)
}
`;

export const VENDING_MACHINES_IDS = gql`
query VendingMachines {
  vendingMachines {
    nodes {
      id: _id
    }
  }
}
`;
