import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Table, ProgressWrapper, Error } from 'components';

import { TableContainer } from './styled';
import { useProductTable } from './hooks';

const ProductTable = ({ categoryId, vendorId }) => {
  const { t } = useTranslation();
  const { loading, error, ...tableProps } = useProductTable(categoryId, vendorId);

  if (loading) return <ProgressWrapper />;
  if (error) return <Error code={error.code} />;

  return (
    <TableContainer>
      {tableProps.data.length ? (
        <Table
          {...tableProps}
        />
      ) : <h1>{t('errors.noItems')}</h1>}
    </TableContainer>
  );
};

ProductTable.propTypes = {
  categoryId: string,
};

ProductTable.defaultProps = {
  categoryId: null,
};

export default ProductTable;
