import styled from 'styled-components';

export const Container = styled.span`
  margin-right: 28px;
  color: ${({ theme }) => theme.palette.lightPurple};
  font-family: ${({ theme }) => theme.typography.roboto};
`;

export const Title = styled.span`
  margin-right: 8px;
  color: ${({ theme }) => theme.palette.purpleGray};
  font-family: ${({ theme }) => theme.typography.roboto};
`;
