import React, { useState } from "react";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';



const SelectMachines = ( props ) => { 

  const [machine, setMachine] = useState('all');


  const handleChange = (event) => {
    setMachine( event.target.value );
    console.log('machine : ', event.target.value);
  }

  props.selectMachine( machine );

  return (
    <div>
      <FormControl style={{width:"150px"}}>
        <InputLabel id="selectLabelId">Vending machine</InputLabel>
        <Select
          labelId="selectLabelId"
          id="selectId"
          value={machine}
          label="Machine"
          onChange={handleChange}
        >
          <MenuItem value='all'> All </MenuItem>         
          { props.foundVms.map( (vendingMachine, index) => <MenuItem key={index} value={vendingMachine}> {vendingMachine} </MenuItem> ) }   
        </Select>
      </FormControl>
    </div>
  );

};

export default SelectMachines;