import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty, noop } from 'lodash/fp';
import {
  string, shape, bool, func,
} from 'prop-types';

import {
  Button,
  ControlsContainer,
  Footer,
  EditPageContent,
  ConfirmPopupButton,
  RouteLeavingGuard,
} from 'components';

const FooterSubmitControlls = ({
  returnLink, submitText, disabled, confirmation, withGuard, isDirty, onSave,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [updated, setUpdated] = useState();

  return (
    <Footer>
      <EditPageContent>
        <ControlsContainer>
          <Button component={Link} to={returnLink} color="secondary">
            {t('common.cancel')}
          </Button>
          {isEmpty(confirmation) ? (
            <Button type="submit" color="primary" onClick={() => setUpdated(true)} disabled={disabled}>
              { submitText || t('common.save')}
            </Button>
          ) : (
            <ConfirmPopupButton color="primary" disabled={disabled} {...confirmation}>
              { submitText || t('common.save')}
            </ConfirmPopupButton>
          )}
          {withGuard && (
          <RouteLeavingGuard
            when={isDirty && !updated}
            shouldBlockNavigation={() => isDirty}
            navigate={(path) => history.push(path)}
            onConfirm={onSave}
          />
          )}
        </ControlsContainer>
      </EditPageContent>
    </Footer>
  );
};

FooterSubmitControlls.propTypes = {
  returnLink: string.isRequired,
  submitText: string,
  disabled: bool,
  confirmation: shape({}),
  withGuard: bool,
  onSave: func,
  isDirty: bool,
};

FooterSubmitControlls.defaultProps = {
  submitText: '',
  disabled: false,
  confirmation: {},
  withGuard: false,
  onSave: noop,
  isDirty: false,
};

export default FooterSubmitControlls;
