import styled from 'styled-components';

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.palette.lightGrayBlue};
  border-radius: 4px;
  padding: 30px 120px;
`;

export const PlaceholderContainer = styled.div`
  width: 334px;
`;

export const BookingConteiner = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px; 
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 29px;
`;

export const InputCaption = styled.p`
  align-self: flex-start;
  font-family: ${({ theme }) => theme.typography.roboto};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.purpleGray};
  margin-top: 5px;

  & span {
    color: ${({ theme }) => theme.palette.grayBlue};
    padding-left: 8px;
  }
`;
