import React from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { convertGqlError } from 'utils';
import { Error, ProgressWrapper } from 'components';

import { getConfig } from './tableConfig';
import { SLOT_BOOKING } from '../queries';
import ViewVendingMachine from './VendingMachine';

const ViewVendingMachineContainer = ({ match }) => {
  const vmId = get(['params', 'id'], match);
  const { t } = useTranslation();
  const {
    loading, error, data,
  } = useQuery(SLOT_BOOKING, {
    fetchPolicy: 'cache-and-network',
    variables: { input: { vmId } },
  });

  if (error) return <Error code={convertGqlError(error)} />;
  if (loading) return <ProgressWrapper />;

  //const { vendingMachine } = data;

  const props = {
    config: getConfig(t, vmId),
    data: get(['productAssignments', 'nodes'], data),
    t,
  };

  return <ViewVendingMachine {...props} />;
};

ViewVendingMachineContainer.propTypes = {
  match: shape({
    id: string,
  }).isRequired,
};

export default ViewVendingMachineContainer;
