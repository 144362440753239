import React from 'react';
import { func, shape } from 'prop-types';
import { noop } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import { ROUTES } from 'configs';

import { TextInput } from 'components';
import { getFieldError } from 'utils/forms';

import {
  AuthForm, StyledHeader, StyledButton, StyledInfo, IconLink, StyledAngleIcon,
} from './styled';

const RecoveryPasswordForm = ({
  t, handleSubmit, register, errors,
}) => (
  <AuthForm onSubmit={handleSubmit}>
    <IconLink to={ROUTES.AUTH}>
      <StyledAngleIcon />
    </IconLink>
    <StyledHeader>{t('auth.recovery-password')}</StyledHeader>
    <TextInput
      type="email"
      label={t('auth.email')}
      name="email"
      register={register}
      error={getFieldError('email', errors)}
    />
    <StyledInfo>{t('auth.recovery-password-info')}</StyledInfo>
    <StyledButton type="submit" width="100%">{t('common.submit')}</StyledButton>
  </AuthForm>
);

RecoveryPasswordForm.propTypes = {
  t: func.isRequired,
  handleSubmit: func,
  register: func,
  errors: shape({}),
};

RecoveryPasswordForm.defaultProps = {
  handleSubmit: noop,
  register: noop,
  errors: {},
};

export default withTranslation()(RecoveryPasswordForm);
