import React, { Fragment } from 'react';
import {
  shape, func, arrayOf, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { map, noop } from 'lodash/fp';

import { ROUTES, REFILL_STATUSES } from 'configs';
import { getDate } from 'utils';
import {
  ContentBox,
  Table,
  Button,
  ControlsContainer,
  ConfirmPopupButton,
  VMTableContainer,
  NoContentPlaceholder,
  HeadContainer,
  InfoContainer,
  StyledHeadInfo,
  DateTimePickerContainer,
  PrintButton,
} from 'components';
import {
  StyledHeaderContainer, StyledVMHeadInfo, PlaceholderContainer, PrintContainer,
} from './styled';

const ViewRefillRequest = ({
  t, data, config, handleDelete, hasVendingMachines, requestStatus,
}) => (
  requestStatus ? (
    <ContentBox>
      <StyledHeaderContainer>
        <h1>{t('vendorRefill.title')}</h1>
        {requestStatus.status === REFILL_STATUSES.NEW && (
          <ControlsContainer>
            <ConfirmPopupButton
              confirmButtonText={t('common.delete')}
              cancelButtonText={t('common.cancel')}
              title={t('vendorRefill.confirmMessage')}
              onConfirm={() => handleDelete(requestStatus.id)}
            >
              {t('common.delete')}
            </ConfirmPopupButton>
            <Button component={Link} to={`${ROUTES.REFILL}/edit`}>
              {t('common.edit')}
            </Button>
          </ControlsContainer>
        )}
      </StyledHeaderContainer>
      <StyledVMHeadInfo title={t('vendorRefill.status')} value={t(`refillRequest.status.${requestStatus.status}`)} />
      {map(({
        product, vendingMachines, overallEjectedSinceLastRefill,
        overallQuantity, overallCapacity, emptyPlaces,
      }) => (
        <Fragment key={product.id}>
          <h2>{product.name}</h2>
          <HeadContainer>
            <InfoContainer>
              <StyledHeadInfo
                title={t('vendorRefill.edit.overallQuantity')}
                value={overallQuantity}
              />
              <StyledHeadInfo
                title={t('vendorRefill.edit.overallCapacity')}
                value={overallCapacity}
              />
              <StyledHeadInfo
                title={t('vendorRefill.edit.emptyPlaces')}
                value={emptyPlaces}
              />
              <StyledHeadInfo
                title={t('vendorRefill.edit.overallEjectedSinceLastRefill')}
                value={overallEjectedSinceLastRefill}
              />
            </InfoContainer>
            <DateTimePickerContainer>
              <StyledHeadInfo
                title={t('vendorRefill.expiryDate')}
                value={getDate(vendingMachines[0].refillRequest.expDate, t)}
              />
            </DateTimePickerContainer>
          </HeadContainer>
          <VMTableContainer>
            <Table
              config={config}
              data={vendingMachines}
            />
            <PrintContainer>
              <PrintButton refillRequestId={requestStatus.id} productId={product.id} />
            </PrintContainer>
          </VMTableContainer>
        </Fragment>
      ), data)}
    </ContentBox>
  ) : (
    <ContentBox>
      <StyledHeaderContainer>
        <h1>{t('vendorRefill.title')}</h1>
        <ControlsContainer>
          {hasVendingMachines && (
            <Button component={Link} to={`${ROUTES.REFILL}/new`}>
              {t('vendorSlots.refill')}
            </Button>
          )}
        </ControlsContainer>
      </StyledHeaderContainer>
      <PlaceholderContainer>
        <NoContentPlaceholder placeholder={t('common.placeholders.refillRequests')} />
      </PlaceholderContainer>
    </ContentBox>
  )
);

ViewRefillRequest.propTypes = {
  data: arrayOf(shape({})),
  config: shape({}).isRequired,
  requestStatus: shape({}),
  t: func.isRequired,
  handleDelete: func,
  hasVendingMachines: bool,
};

ViewRefillRequest.defaultProps = {
  data: [],
  handleDelete: noop,
  hasVendingMachines: true,
  requestStatus: {},
};

export default ViewRefillRequest;
