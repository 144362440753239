import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AngleLeftIcon } from 'static/icons';

import { Button } from 'components';

export const AuthForm = styled.form`
  position: relative;
  box-sizing: border-box;
  width: 480px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 43px 72px 24px;
  border: 1px solid ${({ theme }) => theme.palette.lightGrayBlue};
  border-radius: 4px;
`;

export const StyledHeader = styled.h3`
  margin-bottom: 12px;
`;

export const StyledButton = styled(Button)`
  margin: 20px 0 23px;
`;

export const StyledInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  opacity: 0.7;
  margin: 5px;
`;

export const StyledAngleIcon = styled(AngleLeftIcon)`
  width: 10px;
  height: 16px;
  fill: ${({ theme }) => theme.palette.greenBlueLightened};
`;

export const IconLink = styled(Link)`
  position: absolute;
  left: 48px;
  top: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.polar};
  cursor: pointer;
`;
