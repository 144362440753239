import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import DateFnsUtils from "@date-io/date-fns";

import {
  Row,
  ColumnStart,
  ColumnStop,
  ColumnButton,
} from './styled';

import { Button } from "components";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { InfoTitle } from "components";
/*
allows to specify a date range from YY:MM:DD 00:00:00 to yy:mm:dd 23:59:59
The time component is kept invariant across updates. 
*/
const RangePicker = (props) => {
  const { t } = useTranslation();

  const startDate = props.startDate;
  // force the hh:mm:ss t0 00:00:00 
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  const [selStartDate, setSelStartDate] = useState(startDate);

  const endDate = props.endDate;
  // force the hh:mm:ss t0 23:59:59 
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);

  const [selEndDate, setSelEndDate] = useState(endDate);

  const onSelStartDateChange = (date, value) => {
    // force the hh:mm:ss t0 00:00:00 -> only yy mm dd can be changed
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    setSelStartDate(date);
  };
  const onSelEndDateChange = (date, value) => {
    // force the hh:mm:ss t0 23:59:59 -> only yy mm dd can be changed
    // note that by default, if the date is typed in, the date picker sets the hours to 00:00:00
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    setSelEndDate(date);
  };

  const filter = () => {
    props.onStartDateChange(selStartDate);
    props.onEndDateChange(selEndDate);
  };

  return (
    <div>
      <Row>
        <ColumnStart>
          <InfoTitle>{t("sales.start")}:</InfoTitle>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              className="datepicker"
              disableToolbar
              ampm={false}
              type="hours"
              minutesStep={60}
              views={["date"]}
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              id="start-datetime-picker-inline"
              value={selStartDate}
              onChange={onSelStartDateChange}
              maxDate={props.startOfToday}
            />
          </MuiPickersUtilsProvider>
        </ColumnStart>
        <ColumnStop>
          <InfoTitle>{t("sales.end")}:</InfoTitle>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              className="datepicker"
              disableToolbar
              ampm={false}
              type="hours"
              minutesStep={60}
              views={["date"]}
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              id="end-datetime-picker-inline"
              value={selEndDate}
              onChange={onSelEndDateChange}
              maxDate={props.endOfToday}
            />
          </MuiPickersUtilsProvider>
        </ColumnStop>
        <ColumnButton>
          <InfoTitle></InfoTitle>
          <Button onClick={filter}>{t("sales.filter")}</Button>
        </ColumnButton>
        </Row>
      </div>
  );
};

export default RangePicker;
