import { CELL_TYPE_DATE } from "components/Table/constants";

export const getConfig = (t) => ({
  headers: [
    {
      id: 'event',
      label: t('machineStatus.view.table.event'),
      key: 'event',
      sortable: false,
    },
    {
      id: 'alarms',
      label: t('machineStatus.view.table.alarms'),
      key: 'alarms',
      sortable: false,
    },
    {
      id: 'description',
      label: t('machineStatus.view.table.description'),
      key: 'description',
      sortable: false,
    },
    {
      id: 'created_at',
      type: CELL_TYPE_DATE,
      label: t('machineStatus.view.table.created_at'),
      key: 'createdAt',
      sortable: false,
    },
    {
      id: 'cleared_at',
      type: CELL_TYPE_DATE,
      label: t('machineStatus.view.table.cleared_at'),
      key: 'clearedAt',
      sortable: false,
    },
    {
      id: 'pending',
      label: t('machineStatus.view.table.pending'),
      key: 'pending',
      sortable: false,
    },
  ],
});
