import React from 'react';
import { get } from 'lodash/fp';
import { shape } from 'prop-types';
import { ROUTES } from 'configs';
import { useTranslation } from 'react-i18next';

import { ProgressWrapper, Error } from 'components';
import { getPictureUrl } from 'utils';
import { useEditVendor } from './hooks';

import EditVendor from './Vendor';


const VendorEditContainer = ({ match }) => {
  const { t } = useTranslation();
  const id = get(['params', 'id'], match);

  const {
    register, setValue, errors, handleSubmit, loading, data, onEditVendor, graphError, error,
  } = useEditVendor(id);

  if (loading) return <ProgressWrapper />;

  if (error) {
    return (
      <Error
        code={error.code}
        props={{ to: ROUTES.VENDORS, linkText: t('vendor.common.return-text') }}
      />
    );
  }

  const props = {
    t,
    register,
    handleSubmit,
    setValue,
    errors,
    graphError,
    onSave: onEditVendor,
    defaultValues: data ? data.account : {},
    picture: data.account.picture ? getPictureUrl(data.account.picture) : null,
  };

  return <EditVendor {...props} />;
};

VendorEditContainer.propTypes = {
  match: shape({}).isRequired,
};

export default VendorEditContainer;
