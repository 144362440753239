import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'configs';

import MachineStatusListContainer from './List';
import ViewVendingMachineStatusContainer from './View';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.MACHINE_STATUS}/:id`}>
      {(props) => <ViewVendingMachineStatusContainer {...props} />}
    </Route>
    <Route path={ROUTES.MACHINE_STATUS}>
      <MachineStatusListContainer />
    </Route>
  </Switch>
);

export default Router;
