/* eslint-disable max-len */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Error, ProgressWrapper } from 'components';
import { GET_HISTORY_DATA } from './queries';
import { useQuery } from '@apollo/react-hooks';
import { convertGqlError } from 'utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { TableContainer, HeadContainer, StyledHeadInfo, InfoContainer } from 'components';
import { ListContainer, HeaderContainer } from './styled';
import { StyledTableCell } from '../../../../src/components/Table/styled';


// Expand / collaps states
const CLOSED      = 0;
const OPENED      = 1;
const INIT        = 2;
// Expand / collaps stuff
var currentStates = [];
var enableClick   = false;


// Forces signing on a number, returned as a string
function getSignedNumber(theNumber) {
  if (theNumber > 0) {
    return "+" + theNumber;
  }
  else {
    return theNumber.toString();
  }
}


var twoWeeksAgo = new Date(Date.now() - 12096e5  ); // 12096e5 == 2 weeks
//twoWeeksAgo.setMonth(0) // TMP for testing
twoWeeksAgo.setHours(0)
twoWeeksAgo.setMinutes(0)
twoWeeksAgo.setSeconds(0)

var endOfToday = new Date();
endOfToday.setHours(23)
endOfToday.setMinutes(59)
endOfToday.setSeconds(59)

// temporary fix to have the just after midnight refill being shown on the previous date
var endOfTodayTemp = new Date(Date.now() + 864e5);
endOfTodayTemp.setHours(1)
endOfTodayTemp.setMinutes(59)
endOfTodayTemp.setSeconds(59)

function daysLater(d, d_ref) {
  const diff = d_ref.getTime() - d.getTime()
  const d_diff = diff / (24 * 60 * 60 * 1000)
//  console.log('time diff', diff)
//  console.log('to round ', d_diff)
  return Math.floor(d_diff)
}

function NDaysAgoLabel(d_ref, N, weekdays) {
  var d = new Date()
  d.setDate(d_ref.getDate() - N)
  return weekdays[d.getDay()] + ' ' + d.getDate() + '.' + (d.getMonth()+1)
}

function outExpiredDates(expireDates, size) {
  let retval = '';
  if (expireDates.length > 0) {
    const dateOption = { dateStyle: 'medium' };
    let currentExpireDate = expireDates[0];
    let currentExpireDateCount = 0;
    let expireDateItemCount = 0;
    expireDates.forEach((expire) => {
      if (currentExpireDate.getTime() === expire.getTime()) {
        currentExpireDateCount += 1;
      } else {
        if (expireDateItemCount < size) {
          retval += `${currentExpireDateCount}x${currentExpireDate.toLocaleDateString('de-DE', dateOption)}\n`;
        } else if (expireDateItemCount === size) {
          retval += ' . . . ;-)\n';
        }
        currentExpireDate = expire;
        currentExpireDateCount = 1;
        expireDateItemCount += 1;
      }
    });
    if ((currentExpireDateCount > 0) && (expireDateItemCount < size)) {
      retval += `${currentExpireDateCount}x${currentExpireDate.toLocaleDateString('de-DE', dateOption)}\n`;
    }
  }
  return retval;
}


const HistoryTable = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
 
  var expansionState = open;
  
  useEffect(() => {
    setOpen(props.expandState)
  }, [props.expandState]);
 

  const handleClick = ( state ) => {
    if( state === false ){
      props.updateExpandStates( props.idx1, props.idx2, CLOSED, true );
    }else{
      props.updateExpandStates( props.idx1, props.idx2, OPENED, true );
    }
    setOpen(state)
  }


  return (
    <React.Fragment>
      <TableRow 
            key={props.row.machineId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <StyledTableCell align="center">{props.row.machineId}</StyledTableCell>
        <StyledTableCell align="center">{props.row.name}</StyledTableCell>
        <StyledTableCell align="center">{props.row.address}</StyledTableCell>
        <StyledTableCell align="center">{props.row.actualQuantity}/{props.row.capacity}</StyledTableCell>
        <StyledTableCell align="center">{props.row.ejectedSinceLastRefill}</StyledTableCell>
        <StyledTableCell align="center">
          {outExpiredDates(props.row.expireDates, 5)}
        </StyledTableCell>
        <TableCell align="center">
          <IconButton className="menu" onClick={() => handleClick(!open)}> 
            { expansionState ? <ExpandLess color="primary" fontSize="large"/> : <ExpandMore color="primary" fontSize="large"/>}
          </IconButton>
        </TableCell>
      </TableRow>      
      <TableRow>
       <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}> 
        <Collapse in={ expansionState } timeout="auto" unmountOnExit>       
            <Box sx={{ margin: 1 }}>
              <br></br>
              <br></br>
              <h2>{t('history.title')}</h2>      
              <Table sx={{ minWidth: 650 }} aria-label="sales table">     
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    {props.headers.map((header) => (
                      <TableCell key={header} align="center">{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              
                <TableBody>
                  <TableRow
                    key={'sales'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{t('history.sales')}</TableCell>
                    {props.sales.map((value, index) => (
                      <TableCell key={index} align="center">{value}</TableCell>
                    ))}
                  </TableRow>

                  <TableRow
                    key={'stock'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{t('history.stock')}</TableCell>
                    {props.stock.map((value, index) => (
                      <TableCell key={index} align="center">{value}</TableCell>
                    ))}
                  </TableRow>

                  <TableRow
                    key={'refill'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{t('history.refill')}</TableCell>
                    {props.refill.map((value, index) => (
                      <TableCell key={index} align="center">{getSignedNumber(value)}</TableCell>
                    ))}
                  </TableRow>

                  <TableRow
                    key={'removed'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{t('history.removed')}</TableCell>
                    {props.removed.map((value, index) => (
                      <TableCell key={index} align="center">{value}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>                 
              </Table>
              <br></br>
              <br></br>
            </Box>                  
          </Collapse> 
        </TableCell>  
      </TableRow>               
    </React.Fragment>
  )
};


const SalesAndRefillHistoryView = () => {
  const { t }                           = useTranslation();
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState]   = useState({});
  const [init, setInit]                 = useState(false);
  
  const { loading, error, data } = useQuery(GET_HISTORY_DATA,
    { variables: { startDate: twoWeeksAgo, endDate: endOfTodayTemp }, fetchPolicy: 'no-cache', });

 // console.log('data', data)

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;
  const {
    stockOverview, refills, salesStatistics, vendingMachines,
  } = data;
 // console.log('stockOverview', stockOverview)
 // console.log('refills', refills)
 // console.log('salesStatistics', salesStatistics)
 // console.log('vendingMachines', vendingMachines);


  const handleClickTopRow = ( idx ) => {
    enableClick = false;
    clickHandling( idx )
  }

  const handleClickSingleRow = ( idx ) => {
    clickHandling( idx )
  }

  const clickHandling = ( idx ) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(idx);

    let obj = {};
    isRowExpanded ? (obj[idx] = false) :  (obj[idx] = true);
    setExpandState(obj);
 
    const newExpandedRows = isRowExpanded ? currentExpandedRows.filter(id => id !== idx) : currentExpandedRows.concat(idx);
    setExpandedRows(newExpandedRows);

    console.log('isRowExpanded', isRowExpanded); 
  }

  function initExpandStates( idx1, idx2 ){
    if( idx2 == 0 ){
      currentStates[idx1] = [ INIT ] ;
    }else{
      currentStates[idx1].push( INIT );
    }
  }

  if( init === false ){
    data['stockOverview'].nodes.map(( stock_entry, idx1 ) => (
      stock_entry.vendingMachines.map(( row, idx2 ) => (      
        initExpandStates( idx1, idx2 )
      ))
    ))
    setInit(true)
  }

  function updateExpandStates( idx1, idx2, state, valid ) {
    let countOpen   = 0;
    let countClosed = 0;

    enableClick = valid;

    if ( enableClick === true ){
      currentStates[idx1][idx2] = state ;
      for( let i=0; i<currentStates[idx1].length; i++){
        if( currentStates[idx1][i] === OPENED ){
          countOpen++;
        }
        if( currentStates[idx1][i] === CLOSED ){
          countClosed++;
        }
      }
      if( currentStates[idx1].length == countOpen ){
        console.log('all open'); 
        for( let i=0; i<currentStates[idx1].length; i++){
           currentStates[idx1][i] = INIT;
        }
        handleClickSingleRow(idx1)
      }      
      if( currentStates[idx1].length == countClosed ){  
        console.log('all closed');     
        for( let i=0; i<currentStates[idx1].length; i++){
          currentStates[idx1][i] = INIT;
        }
        handleClickSingleRow(idx1)
      }
    }
  }


  var refillsDict = {}
  refills.nodes.forEach(e => {
    if (!(e.product._id in refillsDict))
      refillsDict[e.product._id] = {}
    if (!(e.vmId in refillsDict[e.product._id]))
      refillsDict[e.product._id][e.vmId] = { stock: Array(14).fill(''), refill: Array(14).fill(''), removed: Array(14).fill(''), }

    e.refills.forEach(re => {
   //   console.log('refill date', re.refillDate, 'new stock', re.newStock, 'refilled', re.refilled, 'removed', re.removed)
      var date = new Date(re.refillDate)
      const d = daysLater(date, endOfTodayTemp)
  //    console.log('days later', d)
      if (d < 14) { // discard dates which are older than 14 days
        // for refilled and removed, values of same day have to be summed up
        if (refillsDict[e.product._id][e.vmId]['refill'][d] == ''){
          refillsDict[e.product._id][e.vmId]['refill'][d] = re.refilled
        }
        else {
          refillsDict[e.product._id][e.vmId]['refill'][d] = refillsDict[e.product._id][e.vmId]['refill'][d] + re.refilled
        }
        if (refillsDict[e.product._id][e.vmId]['removed'][d] == '') {

          refillsDict[e.product._id][e.vmId]['removed'][d] = -re.removed
        }
        else {
          refillsDict[e.product._id][e.vmId]['removed'][d] = refillsDict[e.product._id][e.vmId]['removed'][d] - re.removed
        }
        // for the pre refill stock we can take latest newStock and calculate it based on all refills and removals
        refillsDict[e.product._id][e.vmId]['stock'][d] = re.newStock - refillsDict[e.product._id][e.vmId]['refill'][d] - refillsDict[e.product._id][e.vmId]['removed'][d]
      }
    })

  })

  var salesDict = {}
  salesStatistics.nodes.forEach(salesStat => {

    if (!(salesStat.product._id in salesDict))
      salesDict[salesStat.product._id] = {}
    if (!(salesStat.vmId in salesDict[salesStat.product._id]))
      salesDict[salesStat.product._id][salesStat.vmId] = { sales: Array(14).fill(0), }


    salesStat.stat.forEach(stat => {
    //  console.log('date', stat.date, 'sales', stat.sales)
      var date = new Date(stat.date)
      const d = daysLater(date, endOfToday)
   //   console.log('days later', d)
      if (d < 14) { // discard dates which are older than 14 days
        salesDict[salesStat.product._id][salesStat.vmId]['sales'][d] = stat.sales
      }
      
     // else {
     //   console.log('tmp putting sales to -5')
     //   salesDict[salesStat.product._id][salesStat.vmId]['sales'][5] = stat.sales // TMP: for test purposes
     // }
      
    })
  })

  // Extend stock overview with empty expireDates array.
  stockOverview.nodes.forEach((stockItem, stockIndex) => (
    stockItem.vendingMachines.forEach((vm, vmIndex) => {
      stockOverview.nodes[stockIndex].vendingMachines[vmIndex].expireDates = [];
    })
  ));


  // Process the expired dates.
  vendingMachines.nodes.forEach((vendingMachine) => {
    vendingMachine.slots.forEach((slot) => {
      if ((slot.productItems.length > 0) && (slot.product !== null) && (slot.product !== undefined)) {
        const productIndex = stockOverview.nodes.findIndex((stockItem) => (slot.product.id === stockItem.product.id));
        if (productIndex >= 0) {
          const vmIndex = stockOverview.nodes[productIndex].vendingMachines.findIndex((vmItem) => (vmItem.id === vendingMachine.id));
          if (vmIndex >= 0) {
            const { expireDates } = stockOverview.nodes[productIndex].vendingMachines[vmIndex];
            slot.productItems.forEach((productItem) => {
              expireDates.push(new Date(productItem.expDate));
            });
            stockOverview.nodes[productIndex].vendingMachines[vmIndex].expireDates = expireDates.sort((left, right) => (left.getTime() - right.getTime()));
          }
        }
      }
    });
  });

  //console.log('refillsDict', refillsDict)

  //console.log('salesDict', salesDict)

  const weekdays = [t('history.Sun'), t('history.Mon'), t('history.Tue'), t('history.Wed'), t('history.Thu'), t('history.Fri'), t('history.Sat')]

  const date_headers = [t('history.today')];
  for (let i = 1; i < 14; i++) {
    date_headers.push(NDaysAgoLabel(endOfToday, i, weekdays))
  }



  const empty_sales = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const empty_days =
    ['', '', '', '', '', '', '', '', '', '', '', '', '', ''];

  return (
    <div>
      <br></br>
      <br></br>
      <h1>{t('history.title')}</h1>
      {stockOverview.nodes.map((stock_entry, idx1) => (
        <ListContainer key={stock_entry.id}>
          <br></br>
          <HeaderContainer>
            <h1>{stock_entry.product.name}</h1>          
          </HeaderContainer>

          <HeadContainer>
            <InfoContainer>
              <StyledHeadInfo
                title={t('history.overallQuantity')}
                value={stock_entry.overallQuantity}
              />  
              <StyledHeadInfo
                title={t('history.overallCapacity')}
                value={stock_entry.overallCapacity}
              />
              <StyledHeadInfo
                title={t('history.overallEjectedSinceLastRefill')}
                value={stock_entry.overallEjectedSinceLastRefill}
              />
            </InfoContainer>
            <IconButton className="menu" onClick={event => handleClickTopRow( idx1 )}> 
              { expandState[idx1] ? <ExpandLess color="primary" fontSize="large"/> : <ExpandMore color="primary" fontSize="large"/> }
            </IconButton>
          </HeadContainer>
 
          <TableContainer>      
            <Table sx={{ minWidth: 650 }} aria-label="sales table">     
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('history.machine')}</StyledTableCell>
                  <StyledTableCell align="center">{t('history.machineId')}</StyledTableCell>
                  <StyledTableCell align="center">{t('history.address')}</StyledTableCell>
                  <StyledTableCell align="center">{t('history.stock_capacity')}</StyledTableCell>
                  <StyledTableCell align="center">{t('history.ejectedSinceLastRefill')}</StyledTableCell>
                  <StyledTableCell width={100} align="center">{t('history.expiring_in')}</StyledTableCell>
                  <StyledTableCell align="center">{t('history.history')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stock_entry.vendingMachines.map((row, idx2) => (   
                  <HistoryTable
                    row={row}
                    idx1={idx1}
                    idx2={idx2}
                    updateExpandStates={updateExpandStates}
                    expandState={ expandState[idx1] } 
                    headers={date_headers}
                    sales={
                      ((stock_entry.product.id in salesDict) && (row.id in salesDict[stock_entry.product.id])) ?
                        salesDict[stock_entry.product.id][row.id]['sales']
                        :
                        empty_sales
                    }
                    stock={
                      ((stock_entry.product.id in refillsDict) && (row.id in refillsDict[stock_entry.product.id])) ?
                        refillsDict[stock_entry.product.id][row.id]['stock']
                        :
                        empty_days
                    }
                    refill={
                      ((stock_entry.product.id in refillsDict) && (row.id in refillsDict[stock_entry.product.id])) ?
                        refillsDict[stock_entry.product.id][row.id]['refill']
                        :
                        empty_days
                    }
                    removed={
                      ((stock_entry.product.id in refillsDict) && (row.id in refillsDict[stock_entry.product.id])) ?
                        refillsDict[stock_entry.product.id][row.id]['removed']
                        :
                        empty_days
                    }>
                  </HistoryTable>   
                ))}    
              </TableBody>
            </Table>            
          </TableContainer>          
        </ListContainer>
      ))}
    </div>
  )
};

export default SalesAndRefillHistoryView;
