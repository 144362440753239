import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ROUTES, NESTED_ROUTES } from 'configs';
import ViewRequestContainer from './RefillRequests/View';
import RequestListContainer from './RefillRequests/List';
import VendingMachineListContainer from './StockManagement/List';
import ViewVendingMachineContainer from './StockManagement/Edit';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}/:id`}>
      {(props) => (
        <ViewRequestContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}`}>
      {(props) => (
        <RequestListContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}/:id`}>
      {(props) => (
        <ViewVendingMachineContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}`}>
      {(props) => (
        <VendingMachineListContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.STOCK}`}>
      {(props) => <Redirect to={`${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}`} {...props} />}
    </Route>
  </Switch>
);

export default Router;
