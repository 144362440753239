import React from 'react';
import { arrayOf, string } from 'prop-types';
import { Route } from 'react-router-dom';
import { includes, intersection } from 'lodash/fp';
import { useQuery } from '@apollo/react-hooks';

import { ERRORS } from 'configs';

import { Error } from 'components';

import { USER_ROLE } from './queries';

const PrivateRoute = ({ roles = [], availableGroups = [],...rest }) => {
  const { data } = useQuery(USER_ROLE);

  if (!roles || includes(data.role, roles) || (intersection(availableGroups, data.availableGroups).length > 0 )) {
    return (<Route {...rest} />);
  }
  return (
    <Error
      code={ERRORS.NOT_FOUND}
    />, availableGroups = []
  );
};

PrivateRoute.propTypes = {
  roles: arrayOf(string),
  availableGroups: arrayOf(string)
};

PrivateRoute.defaultProps = {
  roles: null,
  availableGroups: [],
};

export default PrivateRoute;
