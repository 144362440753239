
import React, { useState, useEffect } from 'react';
import FormElementContainer, { BaseInput, OutlineInput, useInput } from 'components/BaseInput';
import {
  compose, map, join, filter, noop,
} from 'lodash/fp';
import {
  array, string, shape, func, bool,
} from 'prop-types';
import { capMap } from 'utils/lodash';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { StyledSelect } from '../Select/styled';

import {
  StyledBadgeContainer, StyledCheckbox, Container, StyledChip,
} from './styled';

const MultipleSelect = ({
  values, defaultValue, label, error, errorText, register,
  setValue, required, name,
}) => {
  const [value, setSelectValue] = useState(defaultValue || []);

  useEffect(() => {
    register({ name });
    setValue(name, value);
  }, [register, name, setValue, value]);

  const { inputErrorText } = useInput('', errorText, error);

  return (
    <Container>
      <FormElementContainer
        name={name}
        label={label}
        errorText={inputErrorText}
        required={required}
      >
        <StyledSelect
          id={name}
          name={name}
          multiple
          value={value}
          onChange={(e) => setSelectValue(e.target.value)}
          input={<BaseInput />}
          renderValue={() => compose(
            join(', '),
            map((item) => values[item]),
          )(value)}
        >
          {capMap((item, key) => (
            <MenuItem key={key} value={key}>
              <StyledCheckbox color="primary" checked={value.indexOf(key) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ), values)}
        </StyledSelect>
      </FormElementContainer>
      <StyledBadgeContainer>
        {map((item) => (
          <StyledChip
            key={item}
            label={values[item]}
            onDelete={() => setSelectValue(filter((val) => val !== item, value))}
          />
        ), value)}
      </StyledBadgeContainer>
    </Container>
  );
};

MultipleSelect.propTypes = {
  values: shape({}),
  defaultValue: array,
  label: string,
  error: string,
  errorText: string,
  register: func,
  setValue: func,
  name: string.isRequired,
  required: bool,
};

MultipleSelect.defaultProps = {
  values: {},
  defaultValue: '',
  label: '',
  error: '',
  errorText: '',
  required: false,
  register: noop,
  setValue: noop,
};

export default MultipleSelect;
