import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { map } from 'lodash';

import { SubNavigationContainer, StyledSubNavLink } from './styled';

import { isLinkActive } from './util';

const SubNavigationBar = ({ routes }) => (
  <SubNavigationContainer>
    {map(routes, ({ to, name }) => (
      <StyledSubNavLink isActive={isLinkActive(to)} to={to} key={to}>{name}</StyledSubNavLink>
    ))}
  </SubNavigationContainer>
);

SubNavigationBar.propTypes = {
  routes: arrayOf(shape({
    to: string,
    name: string,
  })).isRequired,
};

export default SubNavigationBar;
