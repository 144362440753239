import { gql } from 'apollo-boost';

// startDate: "2020-11-01T01:00:00.857Z",
// endDate: "2021-12-30T23:01:16.857Z",

export const GET_SALES_DATA = gql`
  query SalesDataQuery($startDate : String!, $endDate : String!){
  salesData(input: {
    startDate : $startDate,
    endDate : $endDate,
    status: [ "exception", "expired", "completed"]

  }) {
    products {
      status
      reservedAt
      ejectedAt
      reservedBy {
        name
        role
        _id
      }
      product {
        productId
        vendorId
        vendorName
        name
        vmId
        machineId
        amount
        nominalPrice
        salesPrice
        currency
        discountType
        discountInfo
        status
      }
    }
  }
}

`;

export const GET_HISTORY_DATA = gql`
query HistoryQuery($startDate : String!, $endDate : String){
  stockOverview {
    nodes {
      id: _id
      overallCapacity
      overallQuantity
      overallEjectedSinceLastRefill
      emptyPlaces
      product {
        id: _id
        name
      }
      vendingMachines {
        id: _id
        address
        capacity
        actualQuantity
        ejectedSinceLastRefill
        name
        machineId
      }
    }
  }  
  refills(input:{
    startDate : $startDate,
    endDate : $endDate
  }){
    nodes {
      vmId
      product { _id, name }
      refills {
        refillDate
        newStock
        removed
        refilled
        emptySince
      }
    }
  }

  salesStatistics(input:{
    timeZone:"Europe/Berlin"
    startDate: $startDate,
    endDate: $endDate
    }){
    nodes {
    vmId
    product { _id, name }
    stat {
    date
    sales
    failedEjects
    }
    }
    }

    vendingMachines {
      nodes {
        id: _id
        machineId
        slots {
          product {
            id: _id
          }
          productItems {
            id: _id
            expDate
            status
          }
        }
      }
    }
}
`;
