import styled from 'styled-components';

import { TableContainer } from 'components/Wrappers';
import { HeadInfo, BackLinkHeaderContainer } from 'components';

export const StyledTableContainer = styled(TableContainer)`
  margin-bottom: 75px;
`;

export const StyledHeadInfo = styled(HeadInfo)`
  display: inline-block;
`;

export const HeadInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBackLinkHeaderContainer = styled(BackLinkHeaderContainer)`
  margin-bottom: 54px;
`;

export const DateTimePickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  padding-right: 14px;
  color: ${({ theme }) => theme.palette.purpleGray};
  font-family: ${({ theme }) => theme.typography.roboto};
`;

export const HeaderContainer = styled.div`
  height: 56px;
  display: flex;
  padding: 0 5px;
  margin-top: 8px;
  margin-bottom: 6px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.lightGray};
`;
