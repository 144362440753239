import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { get, orderBy } from 'lodash/fp';

import { ROUTES } from 'configs';
import { Error, ProgressWrapper } from 'components';
import ProductList from './List';

import { GET_CATEGORIES } from '../queries';

const allCategory = {
  id: null,
  name: 'All',
};

const ListContainer = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_CATEGORIES);

  if (loading) return <ProgressWrapper />;

  if (error) {
    return (
      <Error
        code={get(['graphQLErrors', '0', 'extensions', 'code'], error)}
        props={{ to: ROUTES.PRODUCTS, linkText: t('product.common.return-text') }}
      />
    );
  }

  const listProps = {
    categories: [allCategory, ...orderBy('name', 'asc', data.tags.nodes)],
  };

  return (<ProductList {...listProps} />);
};

export default ListContainer;
