import React from 'react';

import { ProgressWrapper, Error } from 'components';

import Request from './Request';

import { useEditRefillRequest } from './hooks';

const RequestCreateContainer = () => {
  const {
    t, loading, data, config, onSubmit, errorCode, register, setValue,
  } = useEditRefillRequest();

  if (errorCode) return <Error code={errorCode} />;
  if (loading) return <ProgressWrapper />;

  return (
    <Request
      onSubmit={onSubmit}
      t={t}
      data={data}
      config={config}
      register={register}
      setValue={setValue}
    />
  );
};

export default RequestCreateContainer;
