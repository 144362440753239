import { gql } from 'apollo-boost';

export const SLOT_BOOKING_LIST = gql`
  query VendingMachines {
    vendingMachines {
      nodes {
        id: _id
        machineId
        name
        address
        stagedSlots {
          numberId
          vendor {
            id: _id
            companyName
          }
        }
      }
    }
  }
`;

export const SLOT_BOOKING = gql`
query productAssignments ($input: ProductAssignmentsInput!){
  productAssignments(input: $input) {
    nodes {
      id: _id
      machineId
      name
      address
      slots {
        id: _id
        numberId
        slotParams {
          slotType
          height
          width
          depth
          capacity
        }
        active {
          id: _id
          bookedSlot
          productItems
          productId
          product{
            id: _id
            name
          }
          vendorId
          vendor {
            companyName
          }
        }
        staged {
          id: _id
          vendorId
          vendor {
            companyName
          }
        }
        planned {
          _id
          vendorId
          vendor {
            companyName
          }
        }
      }
    }
  }
}
`;

export const VENDING_MACHINE = gql`
  query vendingMachine ($input: VendingMachineInput!){
    vendingMachine(input: $input) {
      id: _id
      machineId
      name
      address
      stagedSlots {
        id: _id
        numberId
        vendor {
          id:_id
          companyName
        }
        product {
          id: _id
          name
        }
        slotParams {
          slotType
          }
      }
    }
  }
`;

export const SLOT = gql`
  query slot ($input:SlotInput!) {
    slot(input:$input) {
      id: _id
      numberId
      vendor {
        id:_id
        companyName
      }
      product {
        name
      }
      vendingMachine {
        id:_id
        machineId
        name
        address
      }
      refill {
        qty        
      }
      slotParams {
        slotType
        height
        width
        depth
        capacity
      }
    }
  }
`;

export const BOOK_SLOT = gql`
  mutation newBookSlotForVendor($input: bookSlotForVendorInput!) {
    newBookSlotForVendor(input: $input) {
      id: _id
    }
  }
`;

export const CLEAR_SLOT_BOOKING = gql`
  mutation clearSlotBooking($input: clearSlotBookingInput!) {
    clearSlotBooking(input: $input) {
      id: _id
    }
  }
`;

export const REFILL_TEQUESTS_STATUSES = gql`
  query {
    refillRequests {
      nodes {
        id: _id
        status
      }
    }
  }
`;
