import MUIRichTextEditor from 'mui-rte';
import { withStyles } from '@material-ui/core';

export const StyledTextEditor = withStyles((theme) => ({
  root: {
    minHeight: 678,
    height: '100%',
    width: '100%',
    fontSize: 40,
    border: ({ withBorder }) => (withBorder ? `1px solid ${theme.palette.lightGrayBlue}` : null),
  },
  editorContainer: {
    height: 600,
    boxSizing: 'border-box',
    padding: 15,
    color: theme.palette.lightPurple,
    fontFamily: theme.typography.roboto,
    fontSize: 15,
    letterSpacing: -0.12,
    lineHeight: '24px',
    cursor: 'text',

    '& .DraftEditor-root': {
      '& .DraftEditor-editorContainer': {
        '& .public-DraftEditor-content': {
          height: 600,
        },
      },
    },
  },
}))(MUIRichTextEditor);
