import React from 'react';
import { shape } from 'prop-types';
import { getOr } from 'lodash/fp';

import { ProgressWrapper, Error } from 'components';

import { useEditSlot } from './hooks';
import EditSlot from './Slot';


const SlotEditContainer = ({ match }) => {
  const { vmId, slotId } = getOr({}, ['params'], match);

  const { loading, error, ...props } = useEditSlot(vmId, slotId);

  if (loading) return <ProgressWrapper />;
  if (error) return <Error code={error.code} />;

  return <EditSlot {...props} />;
};

SlotEditContainer.propTypes = {
  match: shape({}),
};

SlotEditContainer.defaultProps = {
  match: {},
};

export default SlotEditContainer;
