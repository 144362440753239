import React from 'react';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';
import { get } from 'lodash/fp';

import { ProgressWrapper, Error } from 'components';

import { useRefillRequest } from './hooks';
import { getConfig } from './tableConfig';
import { getTotalProducts } from './utils';
import ViewRequest from './Request';

const ViewRequestContainer = ({ match }) => {
  const { t } = useTranslation();
  const id = get(['params', 'id'], match);
  const { loading, error, data } = useRefillRequest(id);

  if (loading) return <ProgressWrapper />;
  if (error) return <Error code={error.code} />;

  const props = {
    data: get(['refillRequest'], data),
    totalProducts: getTotalProducts(data),
    config: getConfig(t),
    t,
    id,
  };

  return (
    <ViewRequest {...props} />
  );
};

ViewRequestContainer.propTypes = {
  match: shape({}).isRequired,
};

export default ViewRequestContainer;
