import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { ROUTES } from 'configs';

import ProductAssignment from './Edit';

const Router = () => (
  <Switch>
    <Route path={ROUTES.PRODUCT_ASSIGNMENT}>
      <ProductAssignment />
    </Route>
  </Switch>
);

export default Router;
