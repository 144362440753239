import { gql } from 'apollo-boost';

export const GET_CATEGORIES = gql`
    query Tags{
        tags {
            nodes {
                id: _id
                name
            }
        }
    }
`;

export const CREATE_PRODUCT_TYPE = gql`
  mutation CreateProduct($input:CreateProductInput!){
    createProduct(input: $input) {
      product {
        id: _id
        name
        vendor {
          companyName
        }
        category {
          name
        }
        price
        vat
        discountPrice
        discountTime
      }
    }
  }
`;

export const GET_PRODUCT_TYPE_LIST = gql`
  query Products($categoryId: ID = null, $sortBy: ProductSortByField = name, $sortOrder: SortOrder = asc, $offset: Int = 0, $first: ConnectionLimitInt = 10, $vendorId: ID = null) {
    products(categoryId: $categoryId, sortBy: $sortBy, sortOrder: $sortOrder, offset: $offset, first: $first, vendorId: $vendorId) {
      nodes {
        id: _id
        name
        vendor {
          companyName
        }
        category {
          name
        }
        price
        discountPrice
        discountTime
        picture {
          signature
          pattern
          encodedUrl
          enlarge
          extension
          resizingType
          gravity
          width
          height
        }
      }
      totalCount
    }
  }
`;

export const PRODUCT = gql`  
  query Product($id: ID!) {
    product(productId: $id) {
      productId: _id
      title
      name
      description
      category {
        _id
        name
      }
      vendor {
        _id
        companyName
      }
      picture {
        signature
        pattern
        encodedUrl
        enlarge
        extension
        resizingType
        gravity
        width
        height
      }
      width
      height
      depth
      currency
      package
      price
      vat
      discountPrice
      discountTime
      originCountry
      quality
      shelfLife
      barcode
      storageRequirements
      unit
      weight
      ingredientList
      propertyList
      nutritionalInfo
      allergenList
      biologicalSpecies
      additionalFields {
        name
        value
      }
      slotTypes
      dropHeight
    }
  }
`;

export const DELETE_PRODUCT = gql`
    mutation RemoveProduct($input: RemoveProductInput!) {
        removeProduct(input: $input) {
            product {
                _id
            }
        }
    }
`;

export const UPDATE_PRODUCT_TYPE = gql`
  mutation UpdateProduct($input:UpdateProductInput!){
    updateProduct(input: $input) {
      product {
        productId: _id
        title
        name
        description
        category {
          _id
          name
        }
        vendor {
          _id
          companyName
        }
        picture {
          signature
          extension
          encodedUrl
        }
        width
        height
        depth
        package
        currency
        price
        vat
        discountPrice
        discountTime
        originCountry
        quality
        shelfLife
        barcode
        storageRequirements
        unit
        weight
        ingredientList
        propertyList
        nutritionalInfo
        allergenList
        biologicalSpecies
      }
    }
  }
`;
