import React from 'react';
import { string, shape, func } from 'prop-types';
import { noop } from 'lodash/fp';
import { Select } from 'components';

import { SelectContainer } from './styled';

const InputCell = ({
  values, value, name, register, setValue,
}) => (
  <SelectContainer>
    <Select
      name={name}
      variant="outlined"
      values={values}
      displayEmpty
      defaultValue={value}
      register={register}
      setValue={setValue}
    />
  </SelectContainer>
);

InputCell.propTypes = {
  value: string,
  values: shape({}),
  name: string.isRequired,
  register: func,
  setValue: func,
};

InputCell.defaultProps = {
  value: '',
  values: {},
  register: noop,
  setValue: noop,
};

export default InputCell;
