import styled from 'styled-components';

export const LabelWrapper = styled.label`
  width: ${({ width }) => width || '162px'};
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextButton = styled.span`
  margin: 5px;
  font-family: ${({ theme }) => theme.typography.robotoMedium};
  color: ${({ theme }) => theme.palette.grayBlue};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  &[name = "upload"] {
    color: ${({ theme }) => theme.palette.greenBlueLightened};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.lynch};
  }
`;

export const TipMessage = styled.p`
  height: 24px;
  color: ${({ theme }) => theme.palette.grayBlue};
  font-family: ${({ theme }) => theme.typography.roboto};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
`;
export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.outrageousOrange};
  font-family: ${({ theme }) => theme.typography.roboto};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
`;


export const Separator = styled.span`
  color: ${({ theme }) => theme.palette.lightGrayBlue};
`;
