import 'date-fns';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { string } from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styled';


const DateTimePicker = ({
  defaultValue, register, setValue, name,
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const classes = useStyles();
  const { t } = useTranslation();

  let nextDay = new Date();
  nextDay.setDate(nextDay.getDate()+1);

  useEffect(() => {
    register({ name });
    setValue(name, selectedDate);
  }, [register, name, setValue, selectedDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        className={classes.root}
        disableToolbar
        ampm={false}
        type="hours"
        minutesStep={60}
        views={['date', 'hours']}
        variant="inline"
        format="dd/MM/yyyy HH:mm"
        minDateMessage={t('vendorRefill.invalidDate')}
        maxDateMessage={t('vendorRefill.invalidYear')}
        inputVariant="outlined"
        id="datetime-picker-inline"
        value={selectedDate}
        onChange={setSelectedDate}
        minDate={nextDay}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePicker;

DateTimePicker.propTypes = {
  defaultValue: string,
};

DateTimePicker.defaultProps = {
  defaultValue: null,
};
