import React from 'react';
import { arrayOf, func, shape } from 'prop-types';

import { ContentBox, HeaderContainer, TableContainer, Table } from 'components';

const MachineStatusList = ({ t, config, data }) => (
  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('machineStatus.list.title')}</h1>
      </HeaderContainer>
    </ContentBox>
    <TableContainer>
      <Table config={config} data={data} />
    </TableContainer>
  </>
);

MachineStatusList.propTypes = {
  config: shape({}),
  data: arrayOf(shape({})),
  t: func.isRequired,
};

MachineStatusList.defaultProps = {
  data: [],
  config: {},
};

export default MachineStatusList;
