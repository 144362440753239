import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

const placeholderHidden = { opacity: '0' };
const placeholderVisable = { opacity: '.55' };

export const BaseInput = withStyles((theme) => ({
  root: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.frenchGray}`,
    bottom: '-50%',
    width: '100%',
  },
  input: {
    width: '100%',
    color: theme.palette.purpleBlue,
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    position: 'relative',
    padding: '2px 26px 2px 0px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.typography.roboto,
    '&::placeholder': placeholderHidden,

    '&:focus::placeholder': placeholderVisable,
  },
  error: {
    borderBottom: `1px solid ${theme.palette.outrageousOrange}`,
  },
}))(InputBase);

export const BaseInputMultiline = withStyles((theme) => ({
  root: {
    bottom: '-20%',
    marginTop: '20px',
  },
}))(BaseInput);

export const OutlineInput = withStyles((theme) => ({
  root: {
    borderBottom: 'none',
    bottom: '-25%',
  },
  input: {
    backgroundColor: theme.palette.lightGray,
    padding: '2px 0',
    '&::placeholder': placeholderVisable,
  },
}))(BaseInput);

export const StyledLabel = withStyles((theme) => ({
  error: {
    color: `${theme.palette.outrageousOrange} !important`, // override problems
  },
}))(InputLabel);

export const StyledFormControl = withStyles((theme) => ({
  root: {
    height: '50px',
    marginBottom: '20px',
    '& .Mui-focused': {
      color: theme.palette.lynch,
    },
    width: '100%',
  },
}))(FormControl);

export const StyledFormControlMultiline = withStyles((theme) => ({
  root: {
    marginBottom: '20px',
    '& .Mui-focused': {
      color: theme.palette.lynch,
    },
    width: '100%',
  },
}))(FormControl);

export const OutlineFormContainer = withStyles((theme) => ({
  root: {
    height: '40px',
    marginBottom: '0',
    border: `1px solid ${theme.palette.lightGrayBlue}`,
    borderRadius: '4px',
    padding: '0 16px',
    backgroundColor: theme.palette.lightGray,
  },
}))(StyledFormControl);

export const StyledError = styled.p`
  color: ${({ theme }) => theme.palette.outrageousOrange};
  font-size: 12px;
  position: relative;
  line-height: 12px;
  bottom: -50%;
  margin: 5px 0;
`;

