import React from 'react';
import { func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { noop, get } from 'lodash/fp';

import MUIRichTextEditor from 'mui-rte';

import { ROUTES } from 'configs';

import { capMap } from 'utils';

import { ImageInputPlaceholder } from 'static/images';

import {
  Snackbar,
  BackLink,
  Button,
  ConfirmPopupButton,
  ImageBox,
  InfoBlock,
  ContentBox,
  BackLinkHeaderContainer,
  ControlsContainer,
  ContentContainer,
  InfoTitle,
} from 'components';

import {
  InfoContainer,
  DescriptionContainer,
  DescriptionTitle,
} from './styled';

const ViewVendor = ({
  t,
  id,
  companyName,
  picture,
  companyInfo,
  contactPerson,
  landingDescription,
  gqlError,
  handleDelete,
  handleFetch,
  handleClearFailedReservations
}) => (
  <>
    <Snackbar
      message={t([`beErrors.${get(['code'], gqlError)}`, 'beErrors.global'])}
      isOpen={!!gqlError}
      type="alert"
    />
    <BackLink to={`${ROUTES.VENDORS}`} />
    <ContentBox>
      <BackLinkHeaderContainer>
        <h1>{companyName}</h1>
        <ControlsContainer>
          <Button onClick={handleClearFailedReservations}>{t('vendor.view.clearFailedTransactions')}</Button>
          <Button onClick={handleFetch}>{t('vendor.view.fetch')}</Button>
          <ConfirmPopupButton
            confirmButtonText={t('common.delete')}
            cancelButtonText={t('common.cancel')}
            title={t('vendor.view.confirmMessage')}
            onConfirm={handleDelete}
            disabled={true}
          >
            {t('vendor.view.deleteProfile')}
          </ConfirmPopupButton>
          <Button component={Link} to={`${ROUTES.VENDORS}/${id}/edit`}>
            {t('vendor.view.editProfile')}
          </Button>
        </ControlsContainer>
      </BackLinkHeaderContainer>
      <ContentContainer>
        <ImageBox src={picture} placeholder={ImageInputPlaceholder} />
        <InfoContainer>
          {capMap((value, key) => (
            <InfoBlock
              key={key}
              label={t(`vendor.common.${key}`)}
              text={value}
            />
          ))(companyInfo)}
          <InfoTitle>{t('vendor.common.contactPerson')}</InfoTitle>
          {capMap((value, key) => (
            <InfoBlock
              key={key}
              label={t(`vendor.common.${key}`)}
              text={value}
            />
          ))(contactPerson)}
        </InfoContainer>
        {landingDescription && (
          <DescriptionContainer>
            <DescriptionTitle>
              {t('vendor.common.description')}
            </DescriptionTitle>
            <MUIRichTextEditor
              value={landingDescription}
              toolbar={false}
              readOnly
            />
          </DescriptionContainer>
        )}
      </ContentContainer>
    </ContentBox>
  </>
);

ViewVendor.defaultProps = {
  id: '',
  companyName: '',
  picture: '',
  companyInfo: [],
  contactPerson: [],
  landingDescription: '',
  gqlError: null,
  handleDelete: noop,
  handleFetch: noop,
  handleClearFailedReservations: noop
};

ViewVendor.propTypes = {
  t: func.isRequired,
  id: string,
  companyName: string,
  picture: string,
  companyInfo: shape({
    address: string,
    vatNumber: string,
    iban: string,
    companyEmail: string,
    website: string,
    description: string,
  }),
  contactPerson: shape({
    firstName: string,
    lastName: string,
    phone: string,
    contactEmail: string,
  }),
  landingDescription: string,
  gqlError: shape({}),
  handleDelete: func,
  handleFetch: func,
  handleClearFailedReservations: func
};

export default ViewVendor;
