import { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  compose, reduce, isObject, trim, isString, includes,
} from 'lodash/fp';
import { capMap } from 'utils/lodash';
import { useTranslation } from 'react-i18next';
import { uploadClient } from 'apolloClient';
import { gql } from 'apollo-boost';

import { ROUTES } from 'configs';
import { useVendorsNames, useSnackBar, useRefetchAndRedirect } from 'hooks';
import {
  onFormError, convertGqlError, prepareDataById, capReduce,
} from 'utils';

import {
  GET_CATEGORIES,
  CREATE_PRODUCT_TYPE,
  PRODUCT,
  UPDATE_PRODUCT_TYPE,
  GET_PRODUCT_TYPE_LIST,
} from '../queries';
import { useProductTypeForm } from './form';

const USER = gql`
  query IsUserLoggedIn {
    role @client(always: true)
    id @client(always: true)
    companyName @client(always: true)
  }
`;

const transformCustomFields = reduce((acc, field) => (
  { ...acc, [field.name]: field.value }
), {});

const setInput = (formInput) => capReduce((acc, item, key) => {
  const value = isObject(item) ? item : trim(item);

  if (key === 'picture') {
    if (!isString(item)) {
      acc[key] = item;
    }
    return acc;
  }
  if (key === 'additionalFields') {
    acc[key] = capMap((field, name) => ({ name, value: field }), value);
    return acc;
  }
  if (value) {
    acc[key] = value;
  } else {
    acc[key] = null;
  }

  return acc;
}, {}, formInput);

const populateAdditionalFields = (form) => {
  if (!form.additionalFields) {
    // eslint-disable-next-line no-param-reassign
    form.additionalFields = [];
  }
  return form;
};

const prepareInput = compose(
  populateAdditionalFields,
  setInput,
);

const maskInput = (product, categories) => {
  if (categories && product.categoryId && categories[product.categoryId]) {
    switch (categories[product.categoryId].toLowerCase()) {
      case 'non-food':
        // eslint-disable-next-line no-param-reassign
        product = {...product,
          shelfLife: '0',
          storageRequirements: null,
          ingredientList: null,
          quality: null,
          originCountry: null,
          propertyList: null,
          nutritionalInfo: null,
          allergenList: null,
          biologicalSpecies: null,
        }
        break;
      default : 
        break;
    }
  }
  return product;
}

export const useCategories = () => {
  const { loading, error, data } = useQuery(GET_CATEGORIES);

  const categories = prepareDataById(['tags', 'nodes'], 'name', data);

  return {
    loadingCategories: loading,
    errorCategories: error && convertGqlError(error).code,
    categories,
  };
};

export const useProduct = (id, setValue) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { error } = useQuery(PRODUCT, {
    variables: { id },
    onCompleted: ({ product }) => {
      const productData = capReduce((acc, item, key) => {
        if (key === 'additionalFields') {
          acc[key] = transformCustomFields(item);
        } else if ((isObject(item)) && !includes(key, ['picture', 'slotTypes'])) {
          acc[`${key}Id`] = item._id;
        } else {
          acc[key] = item;
        }
        return acc;
      }, {}, product);

      if ((product.vendor !== undefined) && isObject(product.vendor)) {
        productData.companyName = product.vendor.companyName || '';
      } else {
        productData.companyName = '';
      }

      setValue(capMap((value, key) => ({ [key]: value }), productData));
      setData(productData);
      setLoading(false);
    },
  });

  if (!data.dropHeight) {
    data.dropHeight = 'default';
  }

  return {
    productData: data,
    productError: error,
    productLoading: loading,
  };
};

export const useCreateProductType = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const { loadingVendors, errorVendors, vendors } = useVendorsNames();
  const { loadingCategories, categories } = useCategories();
  const { handleSubmit, setError, ...form } = useProductTypeForm();
  const { data: { role, id, companyName } } = useQuery(USER);

  if (!loadingVendors && (errorVendors !== undefined) && (role === 'vendor')) {
    vendors[id] = companyName;
  }

  const [refetchAndRedirect, { loading: loadingRefetch }] = useRefetchAndRedirect(
    GET_PRODUCT_TYPE_LIST, ROUTES.PRODUCTS,
  );

  const [createProductType] = useMutation(CREATE_PRODUCT_TYPE, {
    onCompleted: (data) => {
      openBar(t('product.common.createMessage', { name: data.createProduct.product.name }));
      refetchAndRedirect();
    },
    onError: onFormError(t, setError, openBar),
    client: uploadClient,
    context: {
      headers: { authorization: localStorage.getItem('token') },
    },
  });

  const onSave = handleSubmit((formInput) => {
    createProductType({
      variables: {
        input: maskInput(prepareInput(formInput), categories),
      },
    });
  });

  return {
    ...form,
    loading: loadingVendors || loadingCategories || loadingRefetch,
    vendors,
    categories,
    onSave,
    t,
  };
};

export const useEditProductType = (id) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const { loadingVendors, errorVendors, vendors } = useVendorsNames();
  const { loadingCategories, categories } = useCategories();
  const {
    handleSubmit, setError, setValue, ...form
  } = useProductTypeForm();
  const { productLoading, productData } = useProduct(id, setValue);

  const [refetchAndRedirect, { loading: loadingRefetch }] = useRefetchAndRedirect(
    GET_PRODUCT_TYPE_LIST, ROUTES.PRODUCTS,
  );

  if (!loadingVendors && (errorVendors !== undefined)) {
    vendors[productData.vendorId] = productData.companyName;
  }

  const [updateProduct] = useMutation(UPDATE_PRODUCT_TYPE, {
    onCompleted: () => refetchAndRedirect(),
    onError: onFormError(t, setError, openBar),
    client: uploadClient,
    context: {
      headers: { authorization: localStorage.getItem('token') },
    },
  });

  const onSave = useCallback(handleSubmit((formInput) => {
    updateProduct({
      variables: {
        input: {
          productId: id,
          product: maskInput(prepareInput(formInput), categories),
        },
      },
    });
  }),
  [id, handleSubmit, updateProduct]);

  return {
    ...form,
    productData,
    setValue,
    loading: productLoading || loadingVendors || loadingCategories || loadingRefetch,
    vendors,
    categories,
    onSave,
    t,
  };
};
