import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { ROUTES } from 'configs';

import ListContainer from './List';
import SlotEditContainer from './Edit';
import ViewVendingMachineContainer from './View';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.SLOT_BOOKING}/:vmId/edit/:slotId`}>
      {(props) => (
        <SlotEditContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.SLOT_BOOKING}/:id`}>
      {(props) => (
        <ViewVendingMachineContainer {...props} />
      )}
    </Route>
    <Route path={`${ROUTES.SLOT_BOOKING}/`}>
      <ListContainer />
    </Route>
  </Switch>
);

export default Router;
