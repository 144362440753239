import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageWrapper, Button } from 'components';

import { ErrorHeading, ErrorDescription } from './styled';

const NotFound = ({ to, linkText }) => {
  const { t } = useTranslation();

  return (
    <PageWrapper centered>
      <ErrorHeading>{t('errors.not-found.code')}</ErrorHeading>
      <ErrorDescription>{t('errors.not-found.message')}</ErrorDescription>
      <Button color="secondary" component={Link} to={to}>{linkText}</Button>
    </PageWrapper>
  );
};

NotFound.propTypes = {
  to: string,
  linkText: string,
};

NotFound.defaultProps = {
  to: '/',
  linkText: 'Return',
};

export default NotFound;
