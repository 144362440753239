import React from 'react';
import {
  shape, func, string,
} from 'prop-types';

import { ROUTES } from 'configs';
import {
  ContentBox,
  BackLink,
  StyledForm,
  FooterSubmitControlls,
} from 'components';

import ProductForm from './ProductForm';

import { FormContainer, StyledHeader } from './styled';

const EditProduct = ({
  categories, vendors, register, setValue, errors, onSave, t, productData, picture,
}) => (
  <StyledForm onSubmit={onSave}>
    <BackLink to={ROUTES.PRODUCTS} />
    <ContentBox>
      <StyledHeader>{t('product.edit.title')}</StyledHeader>
      <FormContainer>
        <ProductForm
          t={t}
          errors={errors}
          vendors={vendors}
          categories={categories}
          register={register}
          setValue={setValue}
          productData={productData}
          picture={picture}
        />
      </FormContainer>
    </ContentBox>
    <FooterSubmitControlls returnLink={ROUTES.PRODUCTS} />
  </StyledForm>
);

EditProduct.propTypes = {
  categories: shape({}),
  vendors: shape({}),
  errors: shape({}),
  productData: shape({}),
  register: func.isRequired,
  setValue: func.isRequired,
  onSave: func.isRequired,
  t: func.isRequired,
  picture: string,
};

EditProduct.defaultProps = {
  categories: {},
  vendors: {},
  errors: {},
  productData: {},
  picture: '',
};

export default EditProduct;
