import React from 'react';
import { func, shape } from 'prop-types';
import { noop } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import { TextInput } from 'components';
import { getFieldError } from 'utils/forms';

import { AuthForm, StyledHeader, StyledButton } from './styled';

const CreatePasswordForm = ({
  t, handleSubmit, register, errors,
}) => (
  <AuthForm onSubmit={handleSubmit}>
    <StyledHeader>{t('auth.create-password')}</StyledHeader>
    <TextInput
      type="password"
      label={t('auth.new-password')}
      name="password"
      register={register}
      error={getFieldError('password', errors)}
    />
    <TextInput
      type="password"
      label={t('auth.confirm-password')}
      name="confirm-password"
      register={register}
      error={getFieldError('confirm-password', errors)}
    />
    <StyledButton type="submit" width="100%">{t('auth.change-password')}</StyledButton>
  </AuthForm>
);

CreatePasswordForm.propTypes = {
  t: func.isRequired,
  handleSubmit: func,
  register: func,
  errors: shape({}),
};

CreatePasswordForm.defaultProps = {
  handleSubmit: noop,
  register: noop,
  errors: {},
};

export default withTranslation()(CreatePasswordForm);
