import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#59D8B3',
    },
    frenchGray: '#B7BBC4',
    greenBlue: '#47D6AC',
    greenBlueLightened: '#59D8B3',
    green: '#49E4B7',
    darkGreen: '#50D4AD',
    lightGreen: 'rgba(73,228,183,0.1)',
    greenBlueDarkened: '#4DCBA6',
    polar: '#E8FAF5',
    darkGrey: '#D7DCE6',
    purpleGray: '#868398',
    purpleBlue: '#3C3463',
    grayBlue: '#A7B0CC',
    lightPurple: '#465171',
    darkPurple: 'rgba(70,81,113, .7)',
    darkPurpleTransparent: 'rgba(70,81,113, .4)',
    lightBlue: '#DCDEE4',
    lightGrayBlue: '#D4DAED',
    lightBlueTransparent: 'rgba(230,232,238,0.42)',
    white: '#FFFFFF',
    lightGray: '#FAFBFD',
    ghost: 'C2C7D5',
    periwinkleGray: '#DAE1F0',
    lynch: '#677396',
    waterBlue: '#DBE2F2',
    outrageousOrange: '#FF5438',
    yellow: '#FDDF40',
  },
  shadow: {
    // TODO: change names to match colors
    primaryHover: '0 2px 4px 0 rgba(43,140,112,0.3)',
    primaryActive: '0 2px 4px 0 rgba(43,140,112,0.2)',
    primaryFocus: '0 0 6px 0 #00FFB4',
    secondaryFocus: '0 0 4px 0 #3BFFC5',
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '"Helvetica Neue"',
      'arial',
      'sans-serif',
    ].join(','),
    roboto: [
      'Roboto',
      '"Helvetica Neue"',
      'arial',
      'sans-serif',
    ].join(','),
    robotoMedium: [
      'Roboto Medium',
      '"Helvetica Neue"',
      'arial',
      'sans-serif',
    ].join(','),
    OpenSansSemiBold: [
      'Open Sans SemiBold',
      '"Helvetica Neue"',
      'arial',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
