import React from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash/fp';
import {
  bool, func,
} from 'prop-types';

import { ConfirmDialog } from 'components';
import { useRouteLeavingGuard } from './hooks';


const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const {
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    handleLeaveNavigationClick,
    closeModal,
    modalVisible,
  } = useRouteLeavingGuard(shouldBlockNavigation, onConfirm, navigate);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmDialog
        title={t('common.leavePageWarning')}
        cancelButtonText={t('common.stay')}
        confirmButtonText={t('common.save')}
        leaveButtonText={t('common.leave')}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
        onLeave={handleLeaveNavigationClick}
        open={modalVisible}
      />
    </>
  );
};

RouteLeavingGuard.propTypes = {
  when: bool,
  navigate: func,
  onConfirm: func,
  shouldBlockNavigation: func,
};

RouteLeavingGuard.defaultProps = {
  when: false,
  navigate: noop,
  onConfirm: noop,
  shouldBlockNavigation: noop,
};

export default RouteLeavingGuard;
