import { reject } from 'lodash/fp';

import { CELL_TYPE_HIGHLIGHTED, CELL_TYPE_ARROW, CELL_TYPE_IMAGE } from 'components/Table/constants';
import { ROUTES } from 'configs';
import { confirmPagination } from './pagination';

export const getConfig = (t, role) => {
  const headers = [
    {
      id: 'picture',
      key: 'picture',
      type: CELL_TYPE_IMAGE,
    },
    {
      id: 'name',
      label: t('product.list.table.name'),
      type: CELL_TYPE_HIGHLIGHTED,
      key: 'name',
      sortable: true,
    },
    {
      id: 'vendor',
      label: t('product.list.table.vendor'),
      key: ['vendor', 'companyName'],
    },
    {
      id: 'category',
      label: t('product.list.table.category'),
      key: ['category', 'name'],
    },
    {
      id: 'price',
      label: t('product.list.table.price'),
      key: 'price',
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ];

  return {
    rowLink(data) { confirmPagination(); return `${ROUTES.PRODUCTS}/${data.id}`; },
    headers: role === 'admin'
      ? headers
      : reject(({ id }) => id === 'vendor', headers),
  };
};
