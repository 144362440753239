import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { ROUTES } from 'configs';

import { useSnackBar } from 'hooks';
import { convertGqlError } from 'utils/graphQL';

import { DELETE_PRODUCT, PRODUCT, GET_PRODUCT_TYPE_LIST } from '../queries';
import { getProductData } from './utils';

const useProductDelete = (id) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { openBar } = useSnackBar();

  const [requestDeletion, { loading, error }] = useMutation(
    DELETE_PRODUCT,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: [{ query: GET_PRODUCT_TYPE_LIST }],
      awaitRefetchQueries: true,
      variables: { input: { productId: id } },
      onCompleted: () => {
        openBar(t('product.view.deleteMessage'));
        history.push(`${ROUTES.PRODUCTS}`);
      },
      onError: (gqlError) => {
        const { code } = convertGqlError(gqlError);
        openBar(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
      },
    },
  );

  const handleDelete = useCallback(requestDeletion, [requestDeletion]);

  return { handleDelete, loading, error: convertGqlError(error) };
};


const useProduct = (id) => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(PRODUCT, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });


  return { loading, error: convertGqlError(error), data: getProductData(t)(data) };
};


export const useProductView = (id) => {
  const { handleDelete, loading: deleteLoading } = useProductDelete(id);
  const { loading: productLoading, error: productError, data } = useProduct(id);

  return {
    data,
    loading: productLoading || deleteLoading,
    pageError: productError,
    handleDelete,
  };
};
