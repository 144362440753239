import React, { Fragment } from 'react';
import { map, isEmpty, noop } from 'lodash/fp';
import {
  arrayOf, shape, func,
} from 'prop-types';

import {
  ContentBox,
  HeaderContainer,
  Table,
  VMTableContainer,
  VMHeadInfo,
  NoContentPlaceholder,
} from 'components';

const ProductAssignment = ({
  data, config, t,
}) => { 
  
  // data contains a list of machines, each machine has slots. Sort the slots according to number id
  map((machine) => {
    machine.slots.sort((a,b) => (a.numberId > b.numberId) ? 1 : ((b.numberId > a.numberId) ? -1 : 0))
  },data)

  

  return (

  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('vendorAssign.title')}</h1>
      </HeaderContainer>
    </ContentBox>
    {map((machine) => (
      <Fragment key={machine.machineId}>
        <ContentBox>
          <h2>{machine.name}</h2>
          <VMHeadInfo
            title={t('common.address')}
            value={machine.address}
          />
        </ContentBox>
        <VMTableContainer>
          <Table
            config={config}
            data={machine.slots}
          />
        </VMTableContainer>
      </Fragment>
    ), data)}
    {isEmpty(data) && (
    <NoContentPlaceholder placeholder={t('common.placeholders.bookedSlots')} />
    )}
  </>
);
};


ProductAssignment.propTypes = {
  data: arrayOf(shape({})),
  config: shape({}).isRequired,
  t: func.isRequired,
  onSubmit: func,
};

ProductAssignment.defaultProps = {
  data: [],
  onSubmit: noop,

};

export default ProductAssignment;
