import React from 'react';
import { arrayOf, func, shape } from 'prop-types';

import {
  ContentBox, HeaderContainer, TableContainer, Table,
} from 'components';

const SlotList = ({ t, config, data }) => (
  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('slot.list.title')}</h1>
      </HeaderContainer>
    </ContentBox>
    <TableContainer>
      <Table
        config={config}
        data={data}
      />
    </TableContainer>
  </>
);

SlotList.propTypes = {
  config: shape({}),
  data: arrayOf(shape({})),
  t: func.isRequired,
};

SlotList.defaultProps = {
  data: [],
  config: {},
};


export default SlotList;
