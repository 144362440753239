import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_RECEIPT } from "./queries";
import { Error, ProgressWrapper } from "components";
import { convertGqlError } from "utils";
import { get } from 'lodash/fp';

export const Receipt = ({ match }) => {
  const pickupCode = get(['params', 'pickupCode'], match);
  console.log(pickupCode);

  const { loading, error, data } = useQuery(GET_RECEIPT, {
    variables: { pickupCode: pickupCode }
  });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;

  const base64Png = data.receipt;
  console.log(base64Png);

  return (<><img src={`data:image/png;base64,${base64Png}`}/></>)
}