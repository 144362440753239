import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash/fp';

import { convertGqlError, prepareDataById } from 'utils';

import {
  OWN_REFILL_REQUEST,
  VENDORS_NAMES_LIST,
  VENDING_MACHINE_LIST,
  PRODUCT_TYPE_LIST,
  USER,
  VENDING_MACHINE,
  STOCK_OVERVIEW,
  STOCK_OVERVIEW_PER_MACHINE,
  REFILL_REQUEST_VIEW,
  PRODUCT_ASSIGNMENTS
} from './queries';

import { useClearFailedReservations } from '../../pages/Vendors/View/hooks';

export const useUser = () => {
  const { data: { role, id } } = useQuery(USER);

  return {
    user: {
      id,
      role,
    },
  };
};

export const useVendorsNames = () => {
  const { loading, error, data } = useQuery(VENDORS_NAMES_LIST);

  const vendors = prepareDataById(['accounts', 'nodes'], 'companyName', data);

  return {
    loadingVendors: loading,
    errorVendors: error && convertGqlError(error).code,
    vendors,
  };
};

export const useVendingMachines = () => {
  const { loading, error, data } = useQuery(VENDING_MACHINE_LIST, {
    fetchPolicy: 'network-only',
  });

  return {
    loadingVendingMachines: loading,
    vendingMachinesError: error && convertGqlError(error).code,
    vendingMachines: get(['vendingMachines', 'nodes'], data),
  };
};

export const useProductAssignments = () => {
  const { loading, error, data } = useQuery(PRODUCT_ASSIGNMENTS, {
    fetchPolicy: 'no-cache',
  });

  return {
    loadingProductAssignments: loading,
    productAssignmentsError: error && convertGqlError(error).code,
    productAssignments: get(['productAssignments', 'nodes'], data),
  };
};

export const useStockOverview = () => {
  const { loading, error, data } = useQuery(STOCK_OVERVIEW, {
    fetchPolicy: 'no-cache',
  });

  return {
    loadingStockOverview: loading,
    stockOverviewError: error && convertGqlError(error).code,
    stockOverview: get(['stockOverview', 'nodes'], data),
  };
};

export const useStockOverviewPerMachine = () => {
  const { loading, error, data } = useQuery(STOCK_OVERVIEW_PER_MACHINE, {
    fetchPolicy: 'no-cache',
  });

  const {
    handleClearFailedReservations,
    loading: clearLoading,
    error: clearError
  } = useClearFailedReservations(null);

  return {
    loadingStockOverviewPerMachine: loading || clearLoading,
    stockOverviewPerMachineError: (error && convertGqlError(error).code) || clearError,
    stockOverviewPerMachine: get(['stockOverviewPerMachine', 'nodes'], data),
    handleClearFailedReservations
  };
};

export const useRefillRequestView = () => {
  const {
    loading, error, data, refetch, networkStatus,
  } = useQuery(REFILL_REQUEST_VIEW, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  return {
    loadingStockOverview: loading,
    stockOverviewError: error && convertGqlError(error).code,
    stockOverview: get(['refillRequestView', 'nodes'], data),
    refillRequest: get(['refillRequestView', 'refillRequest'], data),
    refetch,
    networkStatus,
  };
};

export const useVendingMachine = (id) => {
  const { loading, error, data } = useQuery(VENDING_MACHINE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        id,
      },
    },
  });

  return {
    loadingVendingMachine: loading,
    vendingMachineError: error && convertGqlError(error).code,
    vendingMachine: get(['vendingMachine'], data),
  };
};

export const useProducts = () => {
  const { user: { id, role } } = useUser();

  const { loading, error, data } = useQuery(PRODUCT_TYPE_LIST, {
    variables: {
      vendorId: role === 'vendor' ? id : null,
    },
  });

  return {
    loadingProducts: loading,
    productsError: error && convertGqlError(error).code,
    products: get(['products', 'nodes'], data),
  };
};

export const useRefetchAndRedirect = (query, page, options = {}) => {
  const history = useHistory();

  const [refetchAndRedirect, info] = useLazyQuery(query, {
    fetchPolicy: 'network-only',
    onCompleted: () => history.push(page),
    ...options,
  });

  return [refetchAndRedirect, info];
};

export const useOwnRefillRequest = () => {
  const { loading, error, data } = useQuery(OWN_REFILL_REQUEST, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    loading,
    error: error && convertGqlError(error),
    data,
  };
};
