import { gql } from 'apollo-boost';

export const STOCK_MANAGEMENT_LIST = gql`
  query VendingMachines {
    vendingMachines {
      nodes {
        id: _id
        machineId
        name
        address
        lastRefillUpdate
        slots {
          numberId
          vendor {
            id: _id
            companyName
          }
        }
      }
    }
  }`;

export const VENDING_MACHINE = gql`
  query vendingMachine ($input: VendingMachineInput!){
    vendingMachine(input: $input) {
      id: _id
      machineId
      name
      address
       slots {
         id: _id
         numberId
         vendor {
           id:_id
           companyName
         }
         product {
           name
         }
         productItems {
           id: _id    
         }
         refill {
           qty
           product {
             _id
             name
           }
         }
       }
    }
  }
`;

export const UPDATE_VENDING_MACHINE = gql`
  mutation UpdateStock($input: updateStockInput!){
    updateStock(input: $input) {
      _id
      machineId
      name
      address
      slots {
        _id
        refill {
          qty
          product {
            _id
            name
          }
        }
      }
    }
  }
`;
