import React from 'react';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash/fp';

import { ROUTES } from 'configs';
import { ProgressWrapper, Error } from 'components';

import { getPictureUrl } from 'utils';
import { useVendorView } from './hooks';
import ViewVendor from './Vendor';

const ViewVendorContainer = ({ match }) => {
  const { t } = useTranslation();

  const id = get(['params', 'id'], match);
  const { data, loading, pageError, gqlError, handleDelete, handleFetch, handleClearFailedReservations } =
    useVendorView(id);

  if (loading) return <ProgressWrapper />;
  if (pageError) {
    return (
      <Error
        code={pageError.code}
        props={{ to: ROUTES.VENDORS, linkText: t('vendor.common.return-text') }}
      />
    );
  }

  return (
    <ViewVendor
      {...data}
      t={t}
      picture={data.picture ? getPictureUrl(data.picture) : null}
      gqlError={gqlError}
      handleDelete={handleDelete}
      handleFetch={handleFetch}
      handleClearFailedReservations={handleClearFailedReservations}
    />
  );
};

ViewVendorContainer.propTypes = {
  match: shape({}).isRequired,
};

export default ViewVendorContainer;
