import { useReducer } from 'react';
import {
  reduce, reject, eq, keys,
} from 'lodash/fp';

function reducer(inputNames, { type, payload }) {
  switch (type) {
    case 'add': {
      const { newName } = payload;

      if (inputNames.includes(newName) || !newName) {
        return inputNames;
      }
      return inputNames.concat(newName);
    }
    case 'remove': {
      return reject(eq(payload.oldName), inputNames);
    }
    case 'edit': {
      const { oldName, newName } = payload;

      if (!newName || eq(oldName, newName) || inputNames.includes(newName)) {
        return inputNames;
      }

      return reduce((acc, name) => (
        [...acc, name === oldName ? newName : name]
      ), [], inputNames);
    }
    default:
      return inputNames;
  }
}

export const useCustomInputs = (defaultValues = {}) => {
  const [inputNames, dispatch] = useReducer(reducer, keys(defaultValues));

  return {
    inputNames,
    handleAdd: (newName) => dispatch({ type: 'add', payload: { newName } }),
    handleRemove: (oldName) => () => dispatch({ type: 'remove', payload: { oldName } }),
    handleEdit: (oldName) => (newName) => dispatch({ type: 'edit', payload: { oldName, newName } }),
  };
};
