import React from 'react';

import { ROUTES, NESTED_ROUTES } from 'configs';
import {
  PageWrapper, LightWrapper, SubNavigationBar,
} from 'components';

import Router from './router';

const StockPage = () => (
  <PageWrapper>
    <SubNavigationBar
      routes={[
        { to: `${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}`, name: 'Refill Requests' },
        { to: `${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}`, name: 'Stock Management' },
      ]}
    />
    <LightWrapper>
      <Router />
    </LightWrapper>
  </PageWrapper>
);

export default StockPage;
