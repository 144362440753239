import {
  pick,
  get,
  omitBy,
  isNil,
  compose,
  getOr,
} from 'lodash/fp';

import { invokeIfArgExists } from 'utils/graphQL';

export const getVendorData = invokeIfArgExists(compose([
  (vendor) => ({
    id: vendor.id,
    companyName: vendor.companyName,
    picture: vendor.picture,
    companyInfo: pick(['address', 'vatNumber', 'iban', 'companyEmail', 'website', 'description'], vendor),
    contactPerson: pick(['firstName', 'lastName', 'phone', 'contactEmail'], vendor),
    landingDescription: getOr( '', 'landing.description' , vendor),
  }),
  omitBy(isNil),
  get('account'),
]));
