import React from 'react';
import { arrayOf, func, shape } from 'prop-types';

import {
  ContentBox, HeaderContainer, TableContainer, Table,
} from 'components';

const VendingMachineList = ({ t, config, data }) => (
  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('stock.stockManagement.title')}</h1>
      </HeaderContainer>
    </ContentBox>
    <TableContainer>
      <Table
        config={config}
        data={data}
      />
    </TableContainer>
  </>
);

VendingMachineList.propTypes = {
  config: shape({}),
  data: arrayOf(shape({})),
  t: func.isRequired,
};

VendingMachineList.defaultProps = {
  data: [],
  config: {},
};


export default VendingMachineList;
