import React from 'react';
import { func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { curry } from 'lodash/fp';

import { getFieldError } from 'utils/forms';
import { ImageInputPlaceholder } from 'static/images';
import {
  ImageInput,
  TextInput,
  ColumnInputs,
  RowInputs,
  StyledFormHeadline,
} from 'components';
import {
  FormContainer,
  RowContainer,
} from './styled';

import { FIELDS } from './form';

const getFormInput = curry((register, t, errors, name, {
  required,
  maxLength,
  placeholder,
}) => (
  <TextInput
    required={required}
    register={register}
    label={t(`vendor.common.${name}`)}
    error={getFieldError(name, errors)}
    name={name}
    maxLength={maxLength}
    placeholder={placeholder}
  />
));

const VendorForm = ({
  register, setValue, errors, picture,
}) => {
  const { t } = useTranslation();

  const input = getFormInput(register, t, errors);
  return (
    <FormContainer>
      <ImageInput
        name={FIELDS.PICTURE}
        picture={picture}
        register={register}
        setValue={setValue}
        placeholder={ImageInputPlaceholder}
      />
      <ColumnInputs>
        {input(FIELDS.COMPANY_NAME, { required: true })}
        {input(FIELDS.ADDRESS, { required: true })}
        {input(FIELDS.REFIL_ADDRESS, {})}
        {input(FIELDS.VAT, { required: true, maxLength: 15 })}
        {input(FIELDS.IBAN, { required: true, maxLength: 32 })}
        {input(FIELDS.COMPANY_EMAIL, { required: true, placeholder: 'example@example.com' })}
        {input(FIELDS.URL, { placeholder: 'https://www.example.com' })}
        {input(FIELDS.DESCRIPTION, { required: true})}
        <StyledFormHeadline>
          {t('vendor.common.contactPerson')}
        </StyledFormHeadline>
        <RowInputs>
          <RowContainer>
            {input(FIELDS.FIRST_NAME, { required: true })}
          </RowContainer>
          <RowContainer>
            {input(FIELDS.LAST_NAME, { required: true })}
          </RowContainer>
        </RowInputs>
        {input(FIELDS.PHONE, { required: true })}
        {input(FIELDS.CONTACT_EMAIL, { required: true, placeholder: 'example@example.com' })}
      </ColumnInputs>
    </FormContainer>
  );
};

VendorForm.propTypes = {
  register: func.isRequired,
  setValue: func.isRequired,
  errors: shape({}),
  picture: string,
};

VendorForm.defaultProps = {
  errors: {},
  picture: null,
};

export default VendorForm;
