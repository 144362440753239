export const getSlotTypes = (t) => ({
  belt: t('slotTypes.belt'),
  doubleBelt: t('slotTypes.doubleBelt'),
  spiral: t('slotTypes.spiral'),
  doubleSpiral: t('slotTypes.doubleSpiral'),
  beltWide: t('slotTypes.beltWide'),
  tripleBelt: t('slotTypes.tripleBelt'),
  beltWide3: t('slotTypes.beltWide3'),
  beltWide3half: t('slotTypes.beltWide3half'),
});
