import React from 'react';
import { noop } from 'lodash/fp';
import {
  string, shape, func, bool, arrayOf,
} from 'prop-types';
import { AssignSelect } from 'components';
import { SelectContainer } from './styled';

const AssignCell = ({
  values, defaultValue, name, onSubmit, assignedSlots, resetComplete, loading,
}) => (
  <SelectContainer>
    <AssignSelect
      name={name}
      variant="outlined"
      values={values}
      displayEmpty
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      assignedSlots={assignedSlots}
      resetComplete={resetComplete}
      loading={loading}
    />
  </SelectContainer>
);

AssignCell.propTypes = {
  defaultValue: string,
  values: shape({}),
  name: string.isRequired,
  onSubmit: func,
  assignedSlots: arrayOf(string),
  resetComplete: func,
  loading: bool,
};

AssignCell.defaultProps = {
  defaultValue: '',
  values: {},
  onSubmit: noop,
  assignedSlots: [],
  resetComplete: noop,
  loading: false,
};

export default AssignCell;
