import {
  wrap,
  get,
  isNil,
  curry,
  map,
  compose,
  reduce,
} from 'lodash/fp';


export const invokeIfArgExists = wrap((func, value) => (isNil(value) ? value : func(value)));

export const convertGqlError = invokeIfArgExists((gqlError) => ({
  code: get(['graphQLErrors', 0, 'extensions', 'code'], gqlError),
  message: get(['graphQLErrors', 0, 'message'], gqlError),
  details: get(['graphQLErrors', 0, 'extensions', 'exception', 'details'], gqlError),
  path: get(['graphQLErrors', 0, 'path'], gqlError),
}));

export const onFormError = curry((t, setError, setGraphError, graphQlError) => {
  const { code, details } = convertGqlError(graphQlError);

  if (code === 'BAD_USER_INPUT') {
    const validationErrors = map((error) => ({
      type: 'beError',
      name: error.name,
    }), details);
    setError(validationErrors);
  } else {
    setGraphError(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
  }
});

export const prepareDataById = (path, key, data) => compose(
  reduce((acc, item) => {
    acc[item.id] = item[key];
    return acc;
  }, {}),
  get(path),
)(data);
