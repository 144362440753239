import styled from 'styled-components';

const iconWidth = '40px';

export const Container = styled.span`
    display: flex;
    flex-direction: column;
`;
export const IconWrapper = styled.div`
  width: ${iconWidth};
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  // TODO: change color and opacity when design is ready.
  padding-left: ${iconWidth};
  color: ${({ theme }) => theme.palette.lynch}; 
  font-family: ${({ theme }) => theme.typography.roboto};
  font-size: 12px;
  line-height: 16px;
  opacity: 70%; 
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.palette.lynch};
  font-family: ${({ theme }) => theme.typography.robotoMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.11px;
  line-height: 16px;
`;

export const styleIcon = (icon) => styled(icon)`
  width: 18px;
  height: 18px;
  // TODO: change fill and opacity when design is ready.
  fill: #7B85A4; 
  opacity: 70%; 
`;
