import {
  /* CELL_TYPE_HIGHLIGHTED, */ CELL_TYPE_ARROW, CELL_TYPE_STATUS
} from 'components/Table/constants';
import { ROUTES } from 'configs';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'machineId',
      label: t('machineStatus.list.table.id'),
      /* type: CELL_TYPE_HIGHLIGHTED, */
      key: 'machineId',
    },
    {
      id: 'name',
      label: t('machineStatus.list.table.name'),
      key: 'name',
    },
    {
      id: 'vmNumber',
      label: t('machineStatus.list.table.vmNumber'),
      key: 'vmNumber',
    },
    {
      id: 'vendingWall',
      label: t('machineStatus.list.table.vendingWall'),
      key: 'vendingWall',
    },
    {
      id: 'status',
      label: t('machineStatus.list.table.status'),
      type: CELL_TYPE_STATUS,
      key: 'status',
    },
    {
      id: 'details',
      label: t('machineStatus.list.table.eventDetails'),
      key: 'details',
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ],
  rowLink: (data) => `${ROUTES.MACHINE_STATUS}/${data.id}`,
});
