import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { noop, size } from 'lodash/fp';
import {
  func, shape, string, arrayOf,
} from 'prop-types';

import { ROUTES } from 'configs';
import { capMap } from 'utils';
import { ProductInputPlaceholder } from 'static/images';

import {
  BackLink,
  Button,
  ConfirmPopupButton,
  ImageBox,
  InfoBlock,
  PrivateComponent,
  BackLinkHeaderContainer,
  ControlsContainer,
  ContentContainer,
  ContentBox,
  InfoTitle,
} from 'components';

import {
  InfoContainer,
} from './styled';

const ViewProduct = ({
  t,
  id,
  productName,
  category,
  productInfo,
  details,
  promotion,
  dimension,
  dimensionConditional,
  additionalFields,
  picture,
  handleDelete,
}) => (
  <>
    <BackLink to={`${ROUTES.PRODUCTS}`} />
    <ContentBox>
      <BackLinkHeaderContainer>
        <h1>{productName}</h1>
        <PrivateComponent roles={['admin', 'vendor']} availableGroups={['advancedVendor']}>
          <ControlsContainer>
            <ConfirmPopupButton
              confirmButtonText={t('common.delete')}
              cancelButtonText={t('common.cancel')}
              title={t('product.view.confirmMessage')}
              onConfirm={handleDelete}
              disabled={true}
            >
              {t('product.view.deleteProduct')}
            </ConfirmPopupButton>
            <Button component={Link} to={`${ROUTES.PRODUCTS}/${id}/edit`}>
              {t('product.view.editProduct')}
            </Button>
          </ControlsContainer>
        </PrivateComponent>
      </BackLinkHeaderContainer>
    </ContentBox>
    <ContentContainer>
      <ImageBox width="504px" height="504px" src={picture} placeholder={ProductInputPlaceholder} />
      <InfoContainer>
        {capMap((value, key) => (
          <InfoBlock
            key={key}
            leftIndent="70px"
            label={t(`product.common.${key}`)}
            text={value}
            multiline={true}
          />
        ))(productInfo)}
        {!!size(promotion) && <InfoTitle>{t('product.common.promotion')}</InfoTitle>}
        {capMap((value, key) => (
          <InfoBlock
            leftIndent="70px"
            key={key}
            label={t(`product.common.${key}`)}
            text={value}
            href={value}
            multiline={false}
          />
        ))(promotion)}
        {capMap(({ name, value }) => (
          <InfoBlock
            key={name}
            leftIndent="70px"
            label={name}
            text={value}
            multiline={false}
          />
        ))(additionalFields)}
        <InfoTitle>{t('product.common.dimension')}</InfoTitle>
        {console.log('dimension', dimension)}
        {capMap((value, key) => (
          <InfoBlock
            leftIndent="70px"
            key={key}
            label={t(`product.common.${key}`)}
            text={value}
            href={value}
            multiline={false}
          />
        ))(dimension)}        
        {category !== 'non-food' ? (
        <>
        {capMap((value, key) => (
          <InfoBlock
            leftIndent="70px"
            key={key}
            label={t(`product.common.${key}`)}
            text={value}
            href={value}
            multiline={false}
          />
        ))(dimensionConditional)}    
        </>
        ) : (
          null
        )}       
        {category !== 'non-food' ? (
        <>
          {!!size(details) && <InfoTitle>{t('product.common.details')}</InfoTitle>}
          {capMap((value, key) => (
            <InfoBlock
              leftIndent="70px"
              key={key}
              label={t(`product.common.${key}`)}
              text={value}
              href={value}
              multiline={false}
            />
          ))(details)}
        </>
        ) : (
          null
        )}
      </InfoContainer>
    </ContentContainer>
  </>
);

ViewProduct.propTypes = {
  t: func.isRequired,
  id: string,
  productName: string,
  category: string,
  picture: string,
  handleDelete: func,
  productInfo: shape({
    description: string,
    category: string,
    vendor: string,
    price: string,
  }),
  dimension: shape({
    width: string,
    height: string,
    depth: string,
    package: string,
    unit: string,
  }),
  dimensionConditional: shape({
    weight: string,
  }),
  details: shape({
    originCountry: string,
    quality: string,
    storageRequirements: string,
    ingredientList: string,
    propertyList: string,
    nutritionalInfo: string,
    allergenList: string,
    biologicalSpecies: string,
  }),
  promotion: shape({
    discountPrice: string,
    discountTime: string,
  }),
  additionalFields: arrayOf(shape({})),
};

ViewProduct.defaultProps = {
  id: '',
  productName: '',
  category: '',
  picture: '',
  productInfo: {},
  details: {},
  promotion: {},
  dimension: {},
  dimensionConditional: {},
  additionalFields: [],
  handleDelete: noop,
};

export default withTranslation()(ViewProduct);
