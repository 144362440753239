import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/styles';

export const StyledSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginBottom: '5px',
  },
  select: {
    '&:focus': {
      background: 'none',
    },
  },
}))(Select);
