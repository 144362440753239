import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const RowContainer = styled.div`
  width: 45%;
`;
