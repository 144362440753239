import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'configs';

import { AuthorisationPage } from 'pages';

const AuthRouter = () => (
  <Switch>
    <Route path={ROUTES.AUTH}>
      <AuthorisationPage />
    </Route>
    <Route path="/">
      <Redirect to={ROUTES.AUTH} />
    </Route>
  </Switch>
);

export default AuthRouter;
