import {
  CELL_TYPE_HIGHLIGHTED,
  CELL_TYPE_ARROW,
  CELL_TYPE_TRANSLATION,
  CELL_TYPE_DISABLED,
} from 'components/Table/constants';
import { ROUTES, NESTED_ROUTES } from 'configs';
import { get } from 'lodash/fp';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'vendor',
      label: t('stock.common.vendor'),
      type: CELL_TYPE_HIGHLIGHTED,
      key: (header, row) => (
        get(['requestType'], row) === 'system' ? t('common.systemRequest') : get(['vendor', 'companyName'], row)
      ),
    },
    {
      id: 'refillQuantity',
      label: t('stock.common.refillQuantity'),
      type: CELL_TYPE_DISABLED,
      key: (header, row) => (row.refillQuantity || 'Remove'),
      isDisabled: (row) => !row.refillQuantity,
    },
    {
      id: 'vmQuantity',
      label: t('stock.common.vmQuantity'),
      key: 'vmQuantity',
    },
    {
      id: 'status',
      label: t('stock.common.status'),
      key: 'status',
      translations: t('stock.refillRequests.list', { returnObjects: true }),
      type: CELL_TYPE_TRANSLATION,
    },
    {
      id: 'vendorAddress',
      label: t('stock.common.vendorAddress'),
      key: ['vendor', 'address'],
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ],
  rowLink: (data) => `${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}/${get(['id'], data)}`,
  isHighlightedRow: (row) => get(['requestType'], row) === 'system',
});
