import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { ROUTES } from 'configs';

import { useTranslation } from 'react-i18next';

import { useSnackBar } from 'hooks';
import { convertGqlError } from 'utils/graphQL';

import { DELETE_VENDOR, VENDORS_LIST, VENDOR, FETCH, CLEAR_FAILED_RESERVATIONS } from '../queries';

import { getVendorData } from './utils';

const useDeleteVendor = (id) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const history = useHistory();

  const [requestDeletion, { loading, error }] = useMutation(DELETE_VENDOR, {
    variables: { input: { accountId: id } },
    refetchQueries: [{ query: VENDORS_LIST }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      openBar(t('vendor.common.deleteMessage'));
      history.push(`${ROUTES.VENDORS}`);
    },
    onError: (gqlError) => {
      const { code } = convertGqlError(gqlError);
      openBar(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
    },
  });

  const handleDelete = useCallback(requestDeletion, [requestDeletion]);

  return { handleDelete, loading, error: convertGqlError(error) };
};

const useFetch = (id) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const [requestFetch, { loading, error }] = useMutation(FETCH, {
    variables: { input: { vendorId: id } },
    onCompleted: () => {
      openBar(t('vendor.common.fetchMessage'));
    },
    onError: (gqlError) => {
      const { code } = convertGqlError(gqlError);
      openBar(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
    },
  });

  const handleFetch = useCallback(requestFetch, [requestFetch]);

  return { handleFetch, loading, error: convertGqlError(error) };
};

export const useClearFailedReservations = (id) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const [requestClearFailedReservations, { loading, error }] = useMutation(CLEAR_FAILED_RESERVATIONS, {
    variables: { input: { vendorId: id } },
    onCompleted: () => {
      openBar(t('vendor.common.clearFailedTransactionsMessage'));
    },
    onError: (gqlError) => {
      const { code } = convertGqlError(gqlError);
      openBar(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
    },
  });

  const handleClearFailedReservations= useCallback(requestClearFailedReservations, [requestClearFailedReservations]);

  return { handleClearFailedReservations, loading, error: convertGqlError(error) };
};

const useVendor = (id) => {
  const { loading, error, data } = useQuery(VENDOR, { variables: { id } });

  return { loading, error: convertGqlError(error), data: getVendorData(data) };
};

export const useVendorView = (id) => {
  const {
    handleDelete,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteVendor(id);
  const {
    handleFetch,
    loading: fetchLoading,
    error: fetchError,
  } = useFetch(id);
  const {
    handleClearFailedReservations,
    loading: clearLoading,
    error: clearError
  } = useClearFailedReservations(id);

  const { loading: vendorLoading, error: vendorError, data } = useVendor(id);

  return {
    data,
    loading: vendorLoading || deleteLoading || fetchLoading || clearLoading,
    pageError: vendorError,
    gqlError: deleteError || fetchError || clearError,
    handleDelete,
    handleFetch,
    handleClearFailedReservations
  };
};
