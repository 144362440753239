import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { withStyles } from '@material-ui/styles';

const iconSize = {
  width: 24,
  height: 24,
};

export const DisabledIcon = withStyles((theme) => ({
  root: {
    ...iconSize,
    fill: theme.palette.periwinkleGray,
  },
}))(FiberManualRecordIcon);

export const CompleteIcon = withStyles((theme) => ({
  root: {
    ...iconSize,
    fill: theme.palette.greenBlueDarkened,
  },
}))(CheckCircleIcon);

export const ActiveIcon = withStyles((theme) => ({
  root: {
    ...iconSize,
    fill: theme.palette.greenBlueDarkened,
  },
}))(RadioButtonUncheckedIcon);

export const StyledStepper = withStyles(() => ({
  root: {
    width: '100%',
    minWidth: 300,
    marginTop: 200,
  },
}))(Stepper);

export const StyledConnector = withStyles((theme) => ({
  alternativeLabel: {
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.greenBlueDarkened,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.greenBlueDarkened,
    },
  },
  disabled: {
    '& $line': {
      backgroundColor: theme.palette.periwinkleGray,
    },
  },
  line: {
    height: 0,
  },
}))(StepConnector);

export const StyledStepLabel = withStyles((theme) => ({
  label: {
    color: theme.palette.lynch,
    '&$active': {
      color: theme.palette.greenBlueDarkened,
    },
    '&$completed': {
      color: theme.palette.lynch,
    },
    '&$alternativeLabel': {
      textAlign: 'center',
    },
  },
  active: {},
  completed: {},
  alternativeLabel: {},
  labelContainer: {
    position: 'absolute',
    bottom: 40,
  },
}))(StepLabel);
