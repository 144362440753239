import * as yup from 'yup';
import { useForm } from 'react-hook-form';

export const FIELDS = {
  PICTURE: 'picture',
  COMPANY_NAME: 'companyName',
  ADDRESS: 'address',
  REFIL_ADDRESS: 'addressForRefill',
  VAT: 'vatNumber',
  IBAN: 'iban',
  COMPANY_EMAIL: 'companyEmail',
  URL: 'website',
  DESCRIPTION: 'description',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  CONTACT_EMAIL: 'contactEmail',
};

const vendorScheme = yup.object().shape({
  [FIELDS.COMPANY_NAME]: yup.string().required().max(1000).trim(),
  [FIELDS.ADDRESS]: yup.string().required().trim(),
  [FIELDS.COMPANY_EMAIL]: yup.string().email().required().trim(),
  [FIELDS.VAT]: yup.string().required().max(15).trim(),
  [FIELDS.IBAN]: yup.string().required().max(32).trim()
    .matches(/^[A-Za-z0-9]*$/g),
  [FIELDS.URL]: yup.string().url().trim(),
  [FIELDS.DESCRIPTION]: yup.string().required().trim(),
  [FIELDS.FIRST_NAME]: yup.string().required().trim(),
  [FIELDS.LAST_NAME]: yup.string().required().trim(),
  [FIELDS.PHONE]: yup.string().required().trim(),
  [FIELDS.CONTACT_EMAIL]: yup.string().email().required().trim(),
});

export const useEditForm = (defaultValues = {}) => {
  const {
    register, handleSubmit, setValue, errors, setError,
  } = useForm({
    validationSchema: vendorScheme,
    defaultValues,
  });

  return {
    register, handleSubmit, setValue, errors, setError,
  };
};
