import React from 'react';

import { PageWrapper } from 'components';

import Router from './router';

const ProductsPage = () => (
  <PageWrapper>
    <Router />
  </PageWrapper>
);

export default ProductsPage;
