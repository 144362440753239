import React, { useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { 
  Row,
  ColumnLeftDateTimePicker,
  ColumnRightDateTimePicker,
} from './styled';

import {
  MAX_DAY_BACK,
  MAX_MONTH_BACK,
} from './const'


import moment from "moment";


const setStartingDate = (days, months) =>{
  var date = new Date(); 
  date.setDate( date.getDate() - days );
  date.setMonth( date.getMonth() - months );

  return date;    
}


const setCurrenDate = () =>{
  return new Date();    
}


const DateTimePicker = ({ setRange }) => { 
  const [dateFrom, setStartDate] = useState( setStartingDate( MAX_DAY_BACK, MAX_MONTH_BACK ) );
  const [dateTo, setStopDate]    = useState( setCurrenDate() );


  const handleStartDate = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    setStartDate(date);
  };


  const handleStopDate = (date) => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    setStopDate(date);
  };

  setRange( moment(dateFrom).format('YYYY-MM-DD') + "T00:00:00.000Z", moment(dateTo).format('YYYY-MM-DDTHH:mm:ss') + ".000Z" );

  return (
    <div>
      <Row>
        <ColumnLeftDateTimePicker>
          <MuiPickersUtilsProvider style={{width:"150px"}} utils={DateFnsUtils}>
            <DatePicker
              style={{width:"150px",height:"50px"}}
              label="From"
              value={dateFrom}
              onChange={handleStartDate}
            />
          </MuiPickersUtilsProvider>  
        </ColumnLeftDateTimePicker>
        <ColumnRightDateTimePicker>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              style={{width:"150px",height:"50px"}}
              label="To"
              value={dateTo}
              onChange={handleStopDate}
            />
          </MuiPickersUtilsProvider>  
        </ColumnRightDateTimePicker>
      </Row>
    </div>
  );
};

export default DateTimePicker;
