import { isArray, join, toString } from 'lodash/fp';

const sortChange = {
  asc: 'desc',
  desc: 'asc',
};

export const parseContent = (content) => {
  if (isArray(content)) {
    return join(', ', content);
  }
  return toString(content);
};

export const setSortDirection = (key, currentSort = {}) => {
  if (!currentSort[key]) {
    return { [key]: 'desc' };
  }
  return { [key]: sortChange[currentSort[key]] };
};
