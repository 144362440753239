import { gql } from 'apollo-boost';

export const LOGIN = gql`
  mutation Login($input: LoginInput) {
    login(input: $input) {
      token
    }
    updateLoggedIn @client
  }
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation SendResetAccountPasswordEmail($input: SendResetAccountPasswordEmailInput!){
    sendResetAccountPasswordEmail(input: $input){
      email
    }
  }
`;

export const CREATE_NEW_PASSWORD = gql`
  mutation ResetPassword($input: resetPasswordInput!) {
    resetPassword(input: $input) {
      message
    }
  }
`;
