import React from 'react';
import { useTranslation } from 'react-i18next';


import { LinkContainer, ReturnIcon, StyledLink } from './styled';

const BackLink = (props) => {
  const { t } = useTranslation();

  return (
    <LinkContainer>
      <StyledLink {...props}>
        <ReturnIcon />
        {t('common.back')}
      </StyledLink>
    </LinkContainer>
  );
};

export default BackLink;
