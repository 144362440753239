import {
  get, curry, isObject, trim, isString, omit, assoc,
} from 'lodash/fp';

import { capReduce } from './lodash';

export const getFieldError = curry((fieldName, errors) => {
  if (!errors || !errors[fieldName]) {
    return null;
  }
  return get([fieldName, 'type'], errors);
});

export const setInput = (form) => capReduce((acc, item, key) => {
  const value = isObject(item) ? item : trim(item);
  if (value) {
    acc[key] = value;
  }
  return acc;
}, {}, form);

export const prepareInput = (formInput) => {
  const values = setInput(formInput);
  return isString(formInput.picture) || (formInput.picture && formInput.picture.signature) ? omit('picture', values) : assoc(['picture'], formInput.picture, values);
};
