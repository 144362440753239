import React from 'react';
import {
  arrayOf, func, shape, string,
} from 'prop-types';
import { noop, isEmpty } from 'lodash/fp';

import {
  ContentBox,
  HeaderContainer,
  TableContainer,
  Table,
  ControlsContainer,
  Button,
  EditPageContent,
  NoContentPlaceholder,
} from 'components';

import { isAnyNewRequest } from 'utils';

import { StyledFooter } from './styled';

const RequestList = ({
  t, config, data, handleReadyForFetchRequest, role,
}) => (
  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('stock.refillRequests.list.title')}</h1>
      </HeaderContainer>
    </ContentBox>
    {isEmpty(data) ? (
      <NoContentPlaceholder placeholder={t('common.placeholders.refillRequests')} />
    ) : (
      <TableContainer>
        <Table
          config={config}
          data={data}
        />
      </TableContainer>
    )}
  </>
);

// TODO: it should've been inside the footer part of the list, but it's not working anyway now
// {role === 'admin' && isAnyNewRequest(data) && (
//     <StyledFooter>
//       <EditPageContent>
//         <ControlsContainer>
//           <Button onClick={handleReadyForFetchRequest}>{t('stock.refillRequests.list.readyForFetch')}</Button>
//         </ControlsContainer>
//       </EditPageContent>
//     </StyledFooter>
// )}

RequestList.propTypes = {
  config: shape({}),
  data: arrayOf(shape({})),
  t: func.isRequired,
  role: string.isRequired,
  handleReadyForFetchRequest: func,
};

RequestList.defaultProps = {
  data: [],
  config: {},
  handleReadyForFetchRequest: noop,
};


export default RequestList;
