import React from 'react';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';
import { get } from 'lodash/fp';

import { ProgressWrapper, Error } from 'components';
import { ROUTES } from 'configs';
import { convertGqlError } from 'utils';

import ViewVendingMachine from './VendingMachine';
import { getConfig } from './tableConfig';
import { useEditVM } from './hooks';

const ViewVendingMachineContainer = ({ match }) => {
  const { t } = useTranslation();
  const id = get(['params', 'id'], match);
  const {
    register, loading, data, onEditVM, error, formState,
  } = useEditVM(id);

  if (loading) return <ProgressWrapper />;
  if (error) {
    return (
      <Error
        code={convertGqlError(error).code}
        props={{ to: ROUTES.STOCK, linkText: t('stock.common.return-text') }}
      />
    );
  }

  const props = {
    config: getConfig(t, register),
    data,
    onSave: onEditVM,
    id,
    t,
    isDirty: formState.dirty,
  };

  return (
    <ViewVendingMachine {...props} />
  );
};

ViewVendingMachineContainer.propTypes = {
  match: shape({}).isRequired,
};

export default ViewVendingMachineContainer;
