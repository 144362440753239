import { useState, useCallback, useMemo } from 'react';
import {
  has, get, slice,
} from 'lodash/fp';

import { setSortDirection } from './util';

export const useTable = (
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  defaultSortKey,
  onSort,
) => {
  const [selectedPage, selectPage] = useState(page);
  const [pageRows, selectRowsPerPage] = useState(rowsPerPage);
  const [sort, setSort] = useState(defaultSortKey || {});

  const changePage = useCallback((element, nextPage) => {
    selectPage(nextPage);
    onPageChange(nextPage);
  }, [onPageChange]);

  const changeRowsPerPage = useCallback((element, { key }) => {
    selectPage(0);
    selectRowsPerPage(Number(key));
    onRowsPerPageChange(Number(key));
  }, [onRowsPerPageChange]);

  const changeSort = useCallback((key) => {
    const newSort = setSortDirection(key, sort);
    selectPage(0);
    setSort(newSort);
    onSort(newSort);
  }, [onSort, sort]);

  return {
    selectedPage,
    changePage,
    pageRows,
    changeRowsPerPage,
    sort,
    changeSort,
  };
};


export const useTableCollapse = (config, data) => {
  const [collapsed, setCollapsed] = useState(true);

  const collapsedData = useMemo(() => (
    has(['collapse'], config)
      ? slice(0, get(['collapse', 'visibleNumber'], config), data)
      : data
  ), [config, data]);

  const restNumber = useMemo(() => data.length - get(['collapse', 'visibleNumber'], config), [config, data]);
  const toggleCollapse = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  return {
    tableData: collapsed ? collapsedData : data,
    config: get(['collapse'], config),
    restNumber,
    collapsed,
    toggleCollapse,
  };
};
