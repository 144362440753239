import React from 'react';
import { shape } from 'prop-types';
import { ProgressWrapper } from 'components';

import { useCreateProductType } from './hooks';

import EditProduct from './Product';

const ProductEditContainer = () => {
  const {
    loading, vendors, categories, onSave, t, ...rest
  } = useCreateProductType();

  if (loading) return <ProgressWrapper />;

  const props = {
    t,
    ...rest,
    categories,
    vendors,
    onSave,
  };

  return <EditProduct {...props} />;
};

ProductEditContainer.propTypes = {
  match: shape({}),
};

ProductEditContainer.defaultProps = {
  match: {},
};

export default ProductEditContainer;
