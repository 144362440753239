import { get, filter, map } from 'lodash/fp';

const getSlotsNumber = (slots) => slots.length;
const getBookedSlots = (slots) => filter('vendor', slots).length;

export const prepareData = (data) => {
  const vendingMachines = get(['vendingMachines', 'nodes'], data);

  return map((vendingMachine) => {
    const { stagedSlots } = vendingMachine;

    return {
      ...vendingMachine,
      slotsNumber: `${getSlotsNumber(stagedSlots)}`,
      slotsBooked: `${getBookedSlots(stagedSlots)}`,
    };
  }, vendingMachines);
};
