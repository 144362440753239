export const getConfig = (t) => ({
  headers: [
    {
      id: 'machineId',
      label: t('vendorSlots.table.machineId'),
      key: 'machineId',
    },
    {
      id: 'machineName',
      label: t('vendorSlots.table.machineName'),
      key: 'name',
    },
    {
      id: 'address',
      label: t('vendorSlots.table.machineAddress'),
      key: ['address'],
      placeholder: t('slotsList.placeholders.table.machineAddress'),
    },
    {
      id: 'actualQuantity',
      label: t('vendorSlots.table.stock_capacity'),
      key: (header, row) => `${row.actualQuantity}/${row.capacity}`,
    },
    {
      id: 'soldFromLastRefill',
      label: t('vendor.common.ejectedSinceLastRefill'),
      key: 'ejectedSinceLastRefill',
      defaultValue: '0',
    },
  ],
  collapse: {
    visibleNumber: 5,
    getCollapseText: () => t('slotsList.table.collapse'),
    getExpandText: (number) => t('slotsList.table.expand', { number }),
  },
});
