import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';

export const StyledInput = withStyles((theme) => ({
  root: {
    fontSize: 14,
    color: theme.palette.lynch,
    borderColor: theme.palette.waterBlue,
    fontFamily: theme.typography.robotoMedium,
    '&:hover $notchedOutline': {
      borderColor: theme.palette.waterBlue,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.waterBlue,
      borderWidth: 1,
    },
  },
  focused: {},
  notchedOutline: {
    borderColor: theme.palette.waterBlue,
  },
  adornedEnd: {
    padding: 0,
  },
  input: {
    padding: '5px 0 5px 16px',
  },
}))(OutlinedInput);

export const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: '9px 12px',
    align: 'center',
    color: theme.palette.lynch,
    '&:hover': {
      color: theme.palette.lynch,
      backgroundColor: 'transparent',
      opacity: '40%',
      cursor: 'pointer',
    },
  },
}))(IconButton);

export const StyledSearchIcon = withStyles(() => ({
  root: {
    width: 22,
    height: 22,
  },
}))(SearchIcon);
