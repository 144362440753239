import React from 'react';
import {
  shape, func, bool, number,
} from 'prop-types';
import { noop } from 'lodash';

import { Button } from 'components';
import { StyledAngleIcon, CollapseButtonWrapper } from './styled';

const CollapseButton = ({
  config,
  restNumber,
  collapsed,
  toggleCollapse,
}) => (
  restNumber > 0
    ? (
      <CollapseButtonWrapper>
        <Button
          variant="text"
          color={collapsed ? 'primary' : 'secondary'}
          startIcon={<StyledAngleIcon disabled={!collapsed} />}
          onClick={toggleCollapse}
        >
          {collapsed ? (
            config.getExpandText(restNumber)
          ) : (
            config.getCollapseText()
          )}
        </Button>
      </CollapseButtonWrapper>
    ) : null
);

CollapseButton.defaultProps = {
  collapsed: false,
  restNumber: 0,
  toggleCollapse: noop,
};

CollapseButton.propTypes = {
  config: shape({
    getCollapseText: func.isRequired,
    getExpandText: func.isRequired,
  }).isRequired,
  collapsed: bool,
  restNumber: number,
  toggleCollapse: func,
};

export default CollapseButton;
