export const getConfig = (t) => ({
  headers: [
    {
      id: 'productName',
      label: t('vendorSlots.table.product'),
      key: 'name',
      placeholder: t('slotsList.placeholders.product'),
    },
    {
      id: 'actualQuantity',
      label: t('vendorSlots.table.stock_capacity'),
      key: (header, row) => `${row.actualQuantity}/${row.capacity}`,
    },
    {
      id: 'soldFromLastRefill',
      label: t('vendor.common.ejectedSinceLastRefill'),
      key: 'ejectedSinceLastRefill',
      defaultValue: '0',
    },
  ],
  collapse: {
    visibleNumber: 5,
    getCollapseText: () => t('slotsList.table.collapse'),
    getExpandText: (number) => t('slotsList.table.expand', { number }),
  },
});
