import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';

import { LightWrapper } from 'components/Wrappers/styled';
import Button from '@material-ui/core/Button';

export const HeaderContainer = styled.header`
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SideContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledLigthWrapper = styled(LightWrapper)`
  box-shadow: 0 4px 2px -2px ${({ theme }) => theme.palette.lightGrayBlue};
  z-index: 10;
`;

export const UserMenuButton = withStyles((theme) => ({
  root: {
    minWidth: '56px',
    width: '56px',
    height: '56px',
    border: 'none',
    borderRadius: '100%',
    fontWeight: '700',
    color: theme.palette.white,
    background: theme.palette.grayBlue,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      background: theme.palette.lightGrayBlue,
    },
  },
}))(Button);
