import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from 'translates/de.json';
import en from 'translates/en.json';

const resources = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    language: 'en',
    lng: 'en',
    languages: ['en'],
    fallbackLng: 'en',
    react: {
      wait: true,
    },
    objectNotation: true,
  });

export default i18n;
