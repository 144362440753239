import React, { useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_TEMPERATURE_DATA } from './queries';
import { Error, ProgressWrapper } from 'components';
import { convertGqlError } from 'utils';
import 'chartjs-adapter-date-fns';

import { 
  ChartContainer,
  ContentContainer,
  StyledButton,
} from './styled';

import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import zoomPlugin from 'chartjs-plugin-zoom';


ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
);


const TemperatureChart = ( props ) => {

  const chartRef = useRef(null);

  var machine                    = props.machine;

  var from                       = props.startDate;
  var to                         = props.endDate;
 
  var inside                     = props.graphs.inside;
  var outside                    = props.graphs.outside;
  var condensor                  = props.graphs.condensor;
  var doorStatus                 = props.graphs.doorStatus;
  var insideFiltered             = props.graphs.insideFiltered;
  var insideAlarmThresholdMin    = props.graphs.insideAlarmThresholdMin;
  var insideAlarmThresholdMax    = props.graphs.insideAlarmThresholdMax;
  var condensorAlarmThresholdMin = props.graphs.condensorAlarmThresholdMin;
  var condensorAlarmThresholdMax = props.graphs.condensorAlarmThresholdMax;


  const options  = {
    responsive: true,
    scales: {
      x: {
        suggestedMin: '2022-01-01T00:00:00',
        suggestedMax: '2022-01-31T00:00:00',
        parsing: false,
        type: 'time',
        time: {
          unit: 'day'
        }      
      },
      y: {
        position: 'left',
        stack: 'demo',
        stackWeight: 2,
        title: {
          display: true,
          text: 'Temperatur [°C]',
        },
        grid: {
          borderColor: 'rgb(255, 0, 0)',
        }
      },
      y1: {
        position: 'left',
        stack: 'demo',
        type: 'category',
        labels: ['Open', 'Closed'],
        offset: true,
        stackWeight: 1,
        title: {
          display: true,
          text: 'Door',
        },
        grid: {
          borderColor: 'rgb(0, 0, 0)',
        }
      }
    },
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: '',
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x',
        },
      },
    },
  }; 
  
  const labels = [];

  const tdata  = {
    labels,
    datasets: [
      {
        label: 'Indoor',    
        data: [],   
        borderColor: 'rgb(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 1)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'Outdoor',
        data: [],      
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(8, 158, 255, 0.8)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'Condenser',    
        data: [],   
        borderColor: 'rgb(0, 128, 0)',
        backgroundColor: 'rgba(113, 156, 0, 4)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'Door',    
        data: [],   
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        yAxisID: 'y1',
      },
      {
        label: 'InsideFiltered',    
        data: [],   
        borderColor: 'rgb(255, 255, 0)',
        backgroundColor: 'rgba(245, 245, 39, 1)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'InsideAlarmThresholdMin',    
        data: [],   
        borderColor: 'rgb(255, 178, 102)',
        backgroundColor: 'rgba(219, 170, 8, 1)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'InsideAlarmThresholdMax',    
        data: [],   
        borderColor: 'rgb(178, 255, 102)',
        backgroundColor: 'rgba(113, 219, 8, 0.8)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'CondensorAlarmThresholdMin',    
        data: [],   
        borderColor: 'rgb(0, 204, 204)',
        backgroundColor: 'rgba(9, 219, 175, 0.6)',
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'CondensorAlarmThresholdMax',    
        data: [],   
        borderColor: 'rgb(255, 204, 255)',
        backgroundColor: 'rgba(219, 9, 211, 0.4)',
        borderWidth: 1,
        yAxisID: 'y',
      },      
    ],
  };

  options.plugins.title.text = machine;

  
  console.log( "Temperature chart of : ",  machine, " started", from, "   ", to);
 

 

  const { loading, error, data } = useQuery( GET_TEMPERATURE_DATA, { variables: { machineId: machine, startDate: from, endDate: to, samplingRate: 1 } });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;
  

  const tempData = data.vmTemperature.values;

  options.scales.x.suggestedMin = from;
  options.scales.x.suggestedMax = to;


  for( let i = 0; i< tempData.length; i++){
    labels[i] = tempData[i]["timeStamp"];
  }

  if(inside){
    for( let i = 0; i< tempData.length ; i++){  
      tdata.datasets[0].data[i] = tempData[i]["inside"]; 
    }
  }

  if(outside){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[1].data[i] = tempData[i]["outside"]; 
    }
  }
  
  if(condensor){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[2].data[i] = tempData[i]["condensor"]; 
    }
  }
  
  if(doorStatus){
    for( let i = 0; i< tempData.length ; i++){
      if(tempData[i]["doorStatus"] == 0){
        tdata.datasets[3].data[i] = 'Closed'
      }
      if(tempData[i]["doorStatus"] == 1){
        tdata.datasets[3].data[i] = 'Open'
      }
    }  
  }
  
  if(insideFiltered){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[4].data[i] = tempData[i]["insideFiltered"]; 
    }
  }
  
  if(insideAlarmThresholdMin){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[5].data[i] = tempData[i]["insideAlarmThresholdMin"]; 
    }
  }
  
  if(insideAlarmThresholdMax){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[6].data[i] = tempData[i]["insideAlarmThresholdMax"]; 
    }
  }
  
  if(condensorAlarmThresholdMin){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[7].data[i] = tempData[i]["condensorAlarmThresholdMin"]; 
    }
  }
  
  if(condensorAlarmThresholdMax){
    for( let i = 0; i< tempData.length ; i++){
      tdata.datasets[8].data[i] = tempData[i]["condensorAlarmThresholdMax"]; 
    }
  }

  console.log( tdata.datasets[4].data );


  const resetZoom = () => { 
    chartRef.current.resetZoom(); 
  }


  return ( 
    <ChartContainer>
      <ContentContainer> 
        <StyledButton type="button" onClick={resetZoom}> Zoom out </StyledButton>
        <Line ref={chartRef} options={options} data={tdata} />
      </ContentContainer>
    </ChartContainer>
  ); 

};


export default TemperatureChart;
