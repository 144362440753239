import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SignInForm } from 'components';
import { onFormError } from 'utils';
import { useSnackBar } from 'hooks';

import { LOGIN } from './queries';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const SignIn = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const {
    register, handleSubmit, errors, setError,
  } = useForm({ validationSchema });

  const [requestLogin] = useMutation(LOGIN, {
    onError: onFormError(t, setError, openBar),
  });

  const handleSubmittion = (formData) => {
    requestLogin({ variables: { input: formData } });
  };

  return (
    <SignInForm
      handleSubmit={handleSubmit(handleSubmittion)}
      register={register}
      errors={errors}
    />
  );
};

export default SignIn;
