import React from 'react';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';
import { get } from 'lodash/fp';

import { ROUTES } from 'configs';
import { ProgressWrapper, Error } from 'components';
import { getPictureUrl } from 'utils';
import { useProductView } from './hooks';
import ViewProduct from './Product';

const ViewProductContainer = ({ match }) => {
  const { t } = useTranslation();
  const id = get(['params', 'id'], match);

  const {
    data, loading, pageError, handleDelete,
  } = useProductView(id);

  if (loading) return <ProgressWrapper />;
  if (pageError) {
    return (
      <Error
        code={pageError.code}
        props={{ to: ROUTES.PRODUCTS, linkText: t('product.common.return-text') }}
      />
    );
  }

  return (
    <ViewProduct
      {...data}
      t={t}
      picture={data.picture ? getPictureUrl(data.picture, true) : null}
      handleDelete={handleDelete}
    />
  );
};

ViewProductContainer.propTypes = {
  match: shape({}).isRequired,
};

export default ViewProductContainer;
