import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';

export const StyledBadgeContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}))(Checkbox);

export const StyledChip = withStyles((theme) => ({
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}))(Chip);
