import { useQuery } from '@apollo/react-hooks';

import { convertGqlError } from 'utils';

import { REFILL_REQUEST } from '../queries';


export const useRefillRequest = (id) => {
  const { loading, error, data } = useQuery(REFILL_REQUEST, {
    variables: { input: { requestId: id } },
  });

  return {
    loading,
    error: convertGqlError(error),
    data,
  };
};
