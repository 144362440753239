import {
  map, reduce, compose, curry, get, groupBy, complement, isEmpty,
} from 'lodash/fp';

export const capMap = map.convert({ cap: false });
export const capReduce = reduce.convert({ cap: false });
export const capGroupBy = groupBy.convert({ cap: false });

export const propSatisfies = curry((func, path, object) => compose(
  func,
  get(path),
)(object));

export const isNotEmpty = complement(isEmpty);

export const getKeyByValue = (values, value) => Object.keys(values).find((key) => values[key] === value);
