import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import { gql } from 'apollo-boost';

import { convertGqlError } from 'utils/graphQL';

import { getPagination, setPagination } from './pagination';
import { getConfig } from './tableConfig';

import { GET_PRODUCT_TYPE_LIST } from '../../queries';

const USER = gql`
  query IsUserLoggedIn {
    role @client(always: true)
    id @client(always: true)
  }
`;

export const useProductTable = (categoryId, vendorId) => {
  const { t } = useTranslation();
  const { data: { role, id } } = useQuery(USER);

  const pagination = getPagination(categoryId);
  const config = getConfig(t, role);
  const [sortDirection, setSortDirection] = useState(pagination.sortDirection);
  const [perPage, onChangePerPage] = useState(pagination.perPage);
  const [page, onChangePage] = useState(pagination.page);

  setPagination(categoryId, perPage, page, sortDirection);

  const onSort = useCallback((sort) => {
    onChangePage(0);
    setSortDirection(getOr('asc', ['name'], sort));
  }, [setSortDirection]);

  const onPerPage = useCallback((pageSize) => {
    onChangePage(0);
    onChangePerPage(pageSize);
  });


  const { data, loading, error } = useQuery(GET_PRODUCT_TYPE_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      categoryId,
      sortOrder: sortDirection,
      first: perPage,
      offset: page * perPage,
      vendorId: role === 'vendor' ? id : vendorId,
    },
  });

  return {
    loading,
    error: convertGqlError(error),
    config,
    data: get(['products', 'nodes'], data),
    defaultSort: { name: sortDirection },
    onSort,
    onChangePage,
    rowsPerPage: perPage,
    itemsCount: get(['products', 'totalCount'], data),
    page,
    onChangeRowsPerPage: onPerPage,
  };
};
