import React, { useCallback, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { noop } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { StyledTextEditor } from './styled';

const TextEditor = ({
  value, withBorder, setValue, register, name,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((data) => {
    setValue(name, data);
  }, [name, setValue]);

  useEffect(() => {
    register({ name });
  });

  return (
    <StyledTextEditor
      onEditorStateChange={handleChange}
      label={t('common.placeholder')}
      value={value}
      withBorder={withBorder}
      ref={register}
      name={name}
    />
  );
};

TextEditor.propTypes = {
  name: string,
  value: string,
  register: func,
  withBorder: bool,
  setValue: func,
};

TextEditor.defaultProps = {
  name: 'description',
  value: '',
  register: noop,
  withBorder: false,
  setValue: noop,
};

export default TextEditor;
