import { capReduce } from 'utils';
import {
  get, isNull, isNumber, toNumber,
} from 'lodash/fp';

export const getUpdatedSlots = (form) => capReduce((slots, value, key) => {
  const [property, slotId] = key.split('-');

  const slot = slots.find((item) => item.slotId === slotId);

  if (slot) {
    slot[property] = toNumber(value);
  } else {
    const newSlot = {
      slotId,
      [property]: toNumber(value),
    };
    slots.push(newSlot);
  }

  return slots;
}, [], form);

export const getSlots = (form, vendingMachine) => {
  const updatedSlots = getUpdatedSlots(form);

  return capReduce((slots, slot) => {
    const requestType = get(['refill', 'type'], slot);
    const refillProduct = get(['refill', 'product'], slot);
    const refillProductId = get(['refill', 'product', 'id'], slot);
    const actualProductId = get(['product', 'id'], slot);


    const slotId = slot.id;
    const updatedSlot = updatedSlots.find((item) => item.slotId === slotId);

    const refillQty = get(['refillQty'], updatedSlot);
    const actualQty = get(['actualQty'], updatedSlot);

    switch (true) {
      case !!(!requestType && isNumber(actualQty)): {
        slots.push({
          slotId,
          actualQty,
          refillQty: 0,
          actualProduct: actualProductId,
          refillProduct: actualProductId,
        });
        return slots;
      }
      case requestType === 'remove': {
        slots.push({
          slotId,
        });
        return slots;
      }
      case !!(!isNull(refillProduct) && slot.refill): {
        slots.push({
          slotId,
          actualProduct: actualProductId,
          refillProduct: refillProductId,
          refillQty,
          actualQty,
        });
        return slots;
      }
      default: return slots;
    }
  }, [], vendingMachine.slots);
};

export const isActualQuantityDisabled = (row) => {
  const isRemove = get(['refill', 'type'], row) === 'remove';
  const isSystemRequest = !!get(['systemRequest'], row);

  return isRemove || isSystemRequest;
};

export const isActualQuantityVisible = (row) => {
  const refillStatus = get(['refill', 'status'], row);
  const actualProduct = get(['product', 'name'], row);
  const isSystemReqest = !!get(['systemRequest'], row);

  return isSystemReqest || !(refillStatus === 'finished' || !actualProduct);
};

export const isRefillQuantityVisible = (row) => {
  const refillProduct = get(['refill', 'product'], row);
  const refillStatus = get(['refill', 'status'], row);

  if (refillStatus === 'finished') {
    return false;
  }

  return !!refillProduct;
};
