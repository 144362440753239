import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';

import { RecoveryPasswordForm } from 'components';

import { onFormError } from 'utils';
import { useSnackBar } from 'hooks';

import { SEND_RESET_PASSWORD_EMAIL } from './queries';


const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const RecoveryPassword = () => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();
  const {
    register, handleSubmit, errors, setError,
  } = useForm({ validationSchema });

  const [sendRecoveryEmail] = useMutation(
    SEND_RESET_PASSWORD_EMAIL,
    {
      onCompleted: (data) => {
        const { email } = data.sendResetAccountPasswordEmail;
        openBar(t('auth.password-sent-info', { email }));
      },
      onError: onFormError(t, setError, openBar),
    },
  );

  const handleSubmittion = ({ email }) => {
    sendRecoveryEmail({ variables: { input: { email } } });
  };

  return (
    <RecoveryPasswordForm
      handleSubmit={handleSubmit(handleSubmittion)}
      register={register}
      errors={errors}
    />
  );
};

export default RecoveryPassword;
