import { gql } from 'apollo-boost';

export const VENDORS_LIST = gql`
  query Accounts(
    $roles: [accountRole] = [vendor]
    $sortOrder: SortOrder = asc
    $sortBy: AccountSortByField = companyName
    $first: ConnectionLimitInt = 9999
  ) {
    accounts(
      roles: $roles
      sortOrder: $sortOrder
      sortBy: $sortBy
      first: $first
    ) {
      nodes {
        id: _id
        companyName
        firstName
        lastName
        contactEmail
        phone
        address
      }
    }
  }
`;

export const VENDOR = gql`
  query Account($id: ID!) {
    account(id: $id) {
      id: _id
      companyName
      address
      vatNumber
      iban
      companyEmail
      website
      firstName
      lastName
      phone
      contactEmail
      description
      landing {
        description
      }
      addressForRefill
      picture {
        signature
        pattern
        encodedUrl
        enlarge
        extension
        resizingType
        gravity
        width
        height
      }
    }
  }
`;

export const DELETE_VENDOR = gql`
  mutation RemoveClientMember($input: RemoveAccountInput!) {
    removeClientMember(input: $input) {
      account {
        _id
      }
    }
  }
`;

export const CREATE_VENDOR = gql`
  mutation CreateVendor($input: createClientMemberInput!) {
    createClientMember(input: $input) {
      account {
        _id
        name
      }
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        _id
        companyName
        address
        vatNumber
        iban
        companyEmail
        website
        firstName
        lastName
        phone
        contactEmail
        description
        landing {
          description
        }
        addressForRefill
        picture {
          signature
          extension
          encodedUrl
        }
      }
    }
  }
`;

export const FETCH = gql`
  mutation Fetch($input: FetchInput!) {
    fetch(input: $input)
  }
`;

export const CLEAR_FAILED_RESERVATIONS = gql`
  mutation ClearFailedReservations($input: ClearFailedReservationsInput!) {
    clearFailedReservations(input: $input)
  }
`;
