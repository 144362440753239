import { CELL_TYPE_HIGHLIGHTED, CELL_TYPE_ARROW, CELL_TYPE_DATE } from 'components/Table/constants';
import { ROUTES, NESTED_ROUTES } from 'configs';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'machineId',
      label: t('stock.stockManagement.table.vmID'),
      type: CELL_TYPE_HIGHLIGHTED,
      key: 'machineId',
    },
    {
      id: 'name',
      label: t('stock.stockManagement.table.name'),
      key: 'name',
    },
    {
      id: 'slotsNumber',
      label: t('stock.stockManagement.table.slotsNumber'),
      key: ['slots', 'length'],
    },
    {
      id: 'lastUpdate',
      label: t('stock.stockManagement.table.lastUpdate'),
      key: 'lastRefillUpdate',
      type: CELL_TYPE_DATE,
      placeholder: t('common.placeholders.date'),
    },
    {
      id: 'address',
      label: t('stock.stockManagement.table.address'),
      key: 'address',
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ],
  rowLink: (data) => `${ROUTES.STOCK}/${NESTED_ROUTES.STOCK_MANAGEMENT}/${data.id}`,
});
