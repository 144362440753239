
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { useSnackBar, useRefillRequestView } from 'hooks';
import { convertGqlError } from 'utils';

import {
  get, filter, reduce,
} from 'lodash/fp';

import { REMOVE_REFILL_REQUEST } from '../queries';

const useRefillRequestDelete = (refetch) => {
  const { t } = useTranslation();
  const { openBar } = useSnackBar();

  const [requestDeletion, { loading, error }] = useMutation(
    REMOVE_REFILL_REQUEST,
    {
      onCompleted: () => {
        refetch();
        openBar(t('vendorRefill.deleteMessage'));
      },
      onError: (gqlError) => {
        const { code } = convertGqlError(gqlError);
        openBar(t([`beErrors.${code}`, 'beErrors.global']), 'alert');
      },
    },
  );

  const handleDelete = (id) => requestDeletion({ variables: { input: { id } } });

  return { handleDelete, loading, error };
};

export const useVendorRefillView = () => {
  const {
    stockOverview: data, loadingStockOverview: refillLoading,
    stockOverviewError: error, refillRequest, refetch, networkStatus,
  } = useRefillRequestView();
  const { handleDelete, loading: deleteLoading } = useRefillRequestDelete(refetch);

  const filteredRequest = reduce((acc, item) => {
    const refillMachines = filter(get(['refillRequest', 'qty']), item.vendingMachines);
    if (refillMachines.length) {
      acc.push({
        ...item,
        vendingMachines: refillMachines,
      });
    }
    return acc;
  }, [], data);

  return {
    loading: refillLoading || deleteLoading,
    error: convertGqlError(error),
    data: filteredRequest,
    requestStatus: refillRequest,
    handleDelete,
    vendingMachines: [],
    networkStatus,
  };
};
