import React from 'react';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { number} from 'prop-types';
import { PageWrapper } from 'components';

const StyledProgress = withStyles((theme) => ({
  root: {
    color: theme.palette.greenBlueLightened,
  },
}))(CircularProgress);


export const Progress = ({ size, ...props }) => (
  <StyledProgress thickness={4} size={size} {...props} />
);

export const ProgressWrapper = ({ size, ...props }) => (
  <PageWrapper centered>
    <StyledProgress thickness={3} size={size} {...props} />
  </PageWrapper>
);

Progress.propTypes = {
  size: number,
};

Progress.defaultProps = {
  size: 100,
};

ProgressWrapper.propTypes = {
  size: number,
};

ProgressWrapper.defaultProps = {
  size: 100,
};
