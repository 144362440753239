import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import { PlusIcon, PenIcon, TrashIcon } from 'static/icons';

const iconStyles = css`
  width: 18px;
  height: 18px;
  fill: ${({ theme }) => theme.palette.grayBlue};
`;

export const StyledPlusIcon = styled(PlusIcon)`
  ${iconStyles}
`;
export const StyledPenIcon = styled(PenIcon)`
  ${iconStyles}
`;
export const StyledTrashIcon = styled(TrashIcon)`
  ${iconStyles}
`;

export const ControllsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  padding-left: 15px;
`;

export const TextButton = withStyles((theme) => ({
  root: {
    alignSelf: 'flex-start',
    textTransform: 'none',
    fontSize: 16,
    fontFamily: theme.typography.OpenSansSemiBold,
    color: theme.palette.grayBlue,
  },
}))(Button);

export const CustomInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
