import { useState, useEffect } from 'react';

import { reject } from 'lodash/fp';

const store = {
  bar: {},
  setBar(bar) {
    this.bar = bar;
    this.setters.forEach((setter) => setter(bar));
  },
  setters: [],
};

store.setBar = store.setBar.bind(store);

const useBarStore = () => {
  const [bar, setBarState] = useState(store.bar);

  if (!store.setters.includes(setBarState)) {
    store.setters.push(setBarState);
  }

  useEffect(() => () => {
    store.setters = reject((item) => item === setBarState, store.setters);
  }, []);

  const closeBar = () => store.setBar({});

  return {
    bar,
    setBar: store.setBar,
    closeBar,
  };
};

export const useSnackBar = () => {
  const { setBar, closeBar, bar } = useBarStore();

  const hideBar = () => {
    clearInterval(bar.interval);
    closeBar();
  };

  const openBar = (message, type, confirmText) => {
    const time = Date.now();

    if (bar.interval) {
      clearInterval(bar.interval);
    }

    const interval = setInterval(() => {
      if (time + 5000 < Date.now()) {
        hideBar();
        clearInterval(interval);
      }
    }, 500);

    setBar({
      time,
      message,
      type,
      confirmText,
      isOpen: true,
      interval,
    });
  };


  return {
    bar,
    openBar,
    hideBar,
  };
};
