import React from 'react';
import { shape, node, func } from 'prop-types';
import { noop } from 'lodash/fp';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import { StyledTableCell } from '../styled';

import { headerType } from '../propTypes';


const HeaderCell = ({
  sort, config, children, setSort,
}) => (
  <StyledTableCell
    onClick={() => config.sortable && setSort(config.id)}
  >
    {config.sortable ? (
      <TableSortLabel active={!!sort[config.id]} direction={sort[config.id]}>
        {children}
      </TableSortLabel>
    ) : children}
  </StyledTableCell>
);

HeaderCell.propTypes = {
  sort: shape({}),
  config: headerType.isRequired,
  children: node,
  setSort: func,
};

HeaderCell.defaultProps = {
  sort: {},
  children: null,
  setSort: noop,
};

export default HeaderCell;
