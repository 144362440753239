import React from 'react';
import { string } from 'prop-types';

import { Placeholder, StyledPrevious, StyledRightAngleIcon } from './styled';

const HistoryCell = ({ prevValue, value, defaultValue }) => (
  <>
    {prevValue && prevValue !== value && (
    <StyledPrevious>
      {prevValue}
      <StyledRightAngleIcon />
    </StyledPrevious>
    )}
    {value || (prevValue ? defaultValue : <Placeholder>{defaultValue}</Placeholder>)}
  </>
);

HistoryCell.propTypes = {
  prevValue: string,
  value: string,
  defaultValue: string,
};

HistoryCell.defaultProps = {
  prevValue: '',
  value: '',
  defaultValue: '',
};

export default HistoryCell;
