import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import { detect } from 'detect-browser';

import { capMap } from 'utils/lodash';


const getGQCode = async (data) => {
  const { productItemId, expDate, productType: { _id, name } } = data;

  const qrCode = await QRCode.toDataURL(`${productItemId}`);
  return qrCode;
};

export const printData = async (dataArray, iframeRef) => {
  try {
    /* eslint-disable new-cap */
    const doc = new jsPDF('l', 'mm', [24.0, 24.0]);
    const qRcodesMap = await Promise.all(dataArray.map(getGQCode));

    capMap((data, index) => {
      doc.addPage([24.0, 24.0], 'l');

      const { productItemId, expDate, productType: { name } } = data;

      doc.addImage(qRcodesMap[index], 'PNG', 5, 5, 14, 14);

      doc.setFontSize(4);
      doc.text(name, 12, 19, { align: 'center' });

      // doc.setFontSize(10);
      // doc.setLineHeightFactor(1.8);
      // doc.text(`${productItemId}\n${expDate}`, 6, 42);
    }, dataArray);

    // Remove the blank page, created on the first map step (line 22)
    doc.deletePage(1);

    // unfortunately, printing from browser seems to only work in chrome.
    // use save file instead for other browsers such as firefox, edge, ...
    const browser = detect();

    switch (browser && browser.name) {
      case 'chrome':
        doc.autoPrint();
        const pdfData = doc.output('bloburl');
        iframeRef.current.setAttribute('src', pdfData);
        break;

      default:
        doc.save('print.pdf');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
