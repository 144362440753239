import React from 'react';
import {
  func, node, string, bool,
} from 'prop-types';
import { noop, isFunction } from 'lodash/fp';

import { Button } from 'components';
import {
  StyledDialog, StyledTitle, StyledContent, StyledActions,
} from './styled';

const ConfirmDialog = ({
  open,
  confirmButtonText,
  cancelButtonText,
  title,
  onConfirm,
  onCancel,
  children,
  onLeave,
  leaveButtonText,
}) => (
  <StyledDialog open={open} onClose={onCancel}>
    <StyledTitle>{title}</StyledTitle>
    <StyledContent>
      {children}
    </StyledContent>
    <StyledActions>
      {isFunction(onLeave) && (
        <Button color="secondary" variant="text" onClick={onLeave}>
          {leaveButtonText}
        </Button>
      )}
      <Button color="secondary" variant="text" onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <Button color="primary" variant="text" onClick={onConfirm}>
        {confirmButtonText}
      </Button>
    </StyledActions>
  </StyledDialog>
);

ConfirmDialog.propTypes = {
  open: bool,
  children: node,
  confirmButtonText: string,
  cancelButtonText: string,
  leaveButtonText: string,
  title: string,
  onConfirm: func,
  onCancel: func,
  onLeave: func,
};

ConfirmDialog.defaultProps = {
  open: false,
  children: null,
  confirmButtonText: 'Agree',
  cancelButtonText: 'Cancel',
  leaveButtonText: 'Leave',
  title: 'Are you sure?',
  onConfirm: noop,
  onCancel: noop,
  onLeave: null,
};

export default ConfirmDialog;
