import React, { useCallback } from 'react';
import { shape, arrayOf, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { isNotEmpty } from 'utils/lodash';

import { printData } from './utils';

const PrintButton = ({ data, disabled }) => {
  const iframeRef = React.useRef();
  const { t } = useTranslation();

  const handlePrint = useCallback(() => {
    if (isNotEmpty(data)) {
      printData(data, iframeRef);
    }
  }, [data]);

  return (
    <>
      <iframe type="application/pdf" ref={iframeRef} title="printFrame" style={{ display: 'none' }} />
      <Button color="secondary" onClick={handlePrint} disabled={disabled}>
        {t('vendorRefill.printLabels')}
      </Button>
    </>
  );
};

PrintButton.propTypes = {
  data: arrayOf(shape({})),
  disabled: bool,
};

PrintButton.defaultProps = {
  data: null,
  disabled: false,
};

export default PrintButton;
