import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES, NESTED_ROUTES } from 'configs';

import { PageWrapper, LightWrapper, SubNavigationBar } from 'components';

import Router from './router';

const SlotsPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <SubNavigationBar
        routes={[
          {
            to: `${ROUTES.SLOTS}/${NESTED_ROUTES.PER_PRODUCT}`,
            name: t('vendorSlots.perProduct'),
          },
          {
            to: `${ROUTES.SLOTS}/${NESTED_ROUTES.PER_MACHINE}`,
            name: t('vendorSlots.perMachine'),
          },
        ]}
      />
      <LightWrapper>
        <Router />
      </LightWrapper>
    </PageWrapper>
  );
};

export default SlotsPage;
