import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 210,
    height: 40,
    '& .MuiInputBase-root': {
      minHeight: 40,
      paddingRight: 0,
      '& .MuiInputBase-input': {
        padding: '0 14px',
      },
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: 6,
          '& .MuiIconButton-label': {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.greenBlueLightened,
            },
          },
        },
      },
    },
  },
}));
