import React from 'react';
import { string, elementType } from 'prop-types';
import * as icons from '../../static/icons';
import {
  styleIcon, Container, Label, Text, IconWrapper, TextWrapper,
} from './styled';

const LabeledText = ({ icon, label, text }) => {
  const Icon = icons[icon];
  const StyledIcon = Icon ? styleIcon(Icon) : null;

  return (
    <Container>
      <Label>
        {label}
      </Label>
      <TextWrapper>
        <IconWrapper>
          {Icon ? <StyledIcon /> : null}
        </IconWrapper>
        <Text>
          {text}
        </Text>
      </TextWrapper>
    </Container>
  );
};

LabeledText.propTypes = {
  label: string.isRequired,
  icon: elementType,
  text: string,
};

LabeledText.defaultProps = {
  icon: null,
  text: '',
};

export default LabeledText;
