import React, { useState } from 'react';
import { useVendingMachines } from 'hooks';
import { Error, ProgressWrapper } from 'components';

import { 
  PageContainer, 
  ContentContainer1,
  ContentContainer2,
  ColumnSelectMachines,
  ColumnRangePicker,
  ColumnSamplingRate,
  ColumnButton,
  StyledButton,
  Title,
  Line,
} from './styled';

import {
  TemperatureChart,
} from '../../components';

import {
  MAX_DAY_BACK,
  MAX_MONTH_BACK,
} from './const'


import DateTimePicker from './DateTimePicker';
import CheckBoxSelection from './CheckBoxSelection';
import SelectSamplingRate from './SelectSamplingRate';
import SelectMachines from './SelectMachines';

import moment from "moment";


const setStartingDate = (days, months) =>{
  var date = new Date(); 
  date.setDate( date.getDate() - days );
  date.setMonth( date.getMonth() - months );

  return date;    
}


const setCurrenDate = () =>{
  return new Date();    
}


var dateFrom    = setStartingDate( MAX_DAY_BACK, MAX_MONTH_BACK );
var dateCurrent = setCurrenDate();
var from        = moment( dateFrom ).format('YYYY-MM-DD') + "T00:00:00.000Z";
var to          = moment( dateCurrent ).format('YYYY-MM-DDTHH:mm:ss') + ".000Z";
var machineId   = null;
var graphs      = null;


const Machines = () => {

  const [count, setCount] = useState(0);               // only used to force a render
  const {loadingVendingMachines, vendingMachinesError, vendingMachines } = useVendingMachines();


  const setRange = ( ffrom, tto ) => {                 // from DateTimePicker.jsx
    from = ffrom;
    to   = tto;
    console.log('selected date :', from, to );
  }

  
  const selectMachine = ( mmachineId ) => {            // from SelectMachines.jsx
    machineId = mmachineId;
    console.log('selected machine :', machineId );
  }


  const setSelected = ( ggraphs ) => {                 // from CheckBoxSelection.jsx
    graphs = ggraphs;
    console.log('setSelected graphs :', graphs);
  }


  const onUpdateButton = () => {                       // from button update
    setCount( count + 1 );
  }


  const CheckSelection = () => { 
    if ( machineId != "all" ){
      return <TemperatureChart machine={machineId} startDate={from} endDate={to} graphs={graphs} />   
    }else{
      return (
        <>
          { foundVms.map((vm, index) => <TemperatureChart key={index} machine={vm} startDate={from} endDate={to} graphs={graphs} /> ) }     
        </>
      )
    }
  }


  if ( vendingMachinesError ) return <Error code = { vendingMachinesError } />;
  if ( loadingVendingMachines ) return <ProgressWrapper />;

 
  const foundVms = [];
  for( let i=0; i<vendingMachines.length; i++){
    console.log( "machineId : ",  vendingMachines[i]['machineId'] )
    foundVms[i] = vendingMachines[i]['machineId'];
  }
 

  return ( 
    <PageContainer>
      <Title> { ('Temperature of vending machines') }</Title>
      <ContentContainer1>
        <ColumnSelectMachines>
          <SelectMachines foundVms={foundVms} selectMachine={selectMachine} />
        </ColumnSelectMachines>
        <ColumnRangePicker> 
          <DateTimePicker setRange={setRange} />
        </ColumnRangePicker>
        <ColumnSamplingRate>
          <SelectSamplingRate />     
        </ColumnSamplingRate>    
        <ColumnButton>
          <StyledButton type="button" onClick={onUpdateButton}> Update </StyledButton>     
        </ColumnButton>    
      </ContentContainer1>       
      <ContentContainer2>
        <CheckBoxSelection setSelected={setSelected} />
      </ContentContainer2>
      <Line></Line>     
      <CheckSelection/>     
    </PageContainer>
  ); 

};


export default Machines;
