import React from 'react';
import { useTranslation } from 'react-i18next';

import { Error, ProgressWrapper } from 'components';

import { useRefillRequestList } from './hooks';
import { getConfig } from './tableConfig';
import RequestList from './List';

const RequestListContainer = () => {
  const { t } = useTranslation();
  const {
    loading, data, error, handleReadyForFetchRequest, role,
  } = useRefillRequestList();

  if (loading) return <ProgressWrapper />;
  if (error) return <Error code={error.code} />;

  const props = {
    handleReadyForFetchRequest,
    config: getConfig(t),
    data,
    role,
    t,
  };

  return (<RequestList {...props} />);
};

export default RequestListContainer;
