import React, { Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { map, get } from 'lodash/fp';

import { ROUTES, NESTED_ROUTES } from 'configs';

import {
  ContentBox,
  BackLinkHeaderContainer,
  Table,
  BackLink,
  InfoBlock,
  HighLightedText,
  RefillRequestInfo,
  VMTableContainer,
  VMHeadInfo,
} from 'components';


const ViewRequest = ({
  t, data, totalProducts, config,
}) => (
  <>
    <BackLink to={`${ROUTES.STOCK}/${NESTED_ROUTES.REFILL_REQUESTS}`} />
    <ContentBox>
      <BackLinkHeaderContainer>
        {data.requestType === 'system' ? (
          <h1>{t('stock.refillRequests.view.systemTitle')}</h1>
        ) : (
          <h1>
            {t('stock.refillRequests.view.fromTitle')}
            <HighLightedText>{get(['vendor', 'companyName'], data)}</HighLightedText>
          </h1>
        )}
      </BackLinkHeaderContainer>
      <RefillRequestInfo>
        <InfoBlock
          label={t('stock.refillRequests.view.status')}
          text={t(`refillRequest.status.${data.status}`)}
        />
        {get(['vendor', 'address'], data) && (
          <InfoBlock
            label={t('stock.refillRequests.view.vendorAddress')}
            text={get(['vendor', 'address'], data)}
          />
        )}
        {totalProducts && (
          <InfoBlock
            label={t('stock.refillRequests.view.totalProducts')}
            text={totalProducts}
          />
        )}
      </RefillRequestInfo>
    </ContentBox>
    {map((machine) => (
      <Fragment key={machine.id}>
        <ContentBox>
          <h1>{machine.name}</h1>
          <VMHeadInfo
            title={t('slotsList.head.id')}
            value={machine.id}
          />
          <VMHeadInfo
            title={t('slotsList.head.address')}
            value={machine.address}
          />
        </ContentBox>
        <VMTableContainer>
          <Table
            config={config}
            data={machine.refill}
          />
        </VMTableContainer>
      </Fragment>
    ), data.vendingMachines)}
  </>
);

ViewRequest.propTypes = {
  t: func.isRequired,
  data: shape({}),
  config: shape({}).isRequired,
  totalProducts: string,
};

ViewRequest.defaultProps = {
  data: {},
  totalProducts: string,
};

export default withTranslation()(ViewRequest);
