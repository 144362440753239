import React from 'react';
import { shape, arrayOf, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { noop } from 'lodash/fp';

import { ROUTES } from 'configs';
import {
  Table, Button, ContentBox, TableContainer, HeaderContainer,
} from 'components';

const VendorList = ({
  config, data, t, handleSort,
}) => (
  <>
    <ContentBox>
      <HeaderContainer>
        <h1>{t('vendor.list.title')}</h1>
        <Button
          component={Link}
          to={`${ROUTES.VENDORS}/new`}
        >
          {t('vendor.list.addVendor')}
        </Button>
      </HeaderContainer>
    </ContentBox>
    <TableContainer>
      <Table
        config={config}
        data={data}
        onSort={handleSort}
      />
    </TableContainer>
  </>
);

VendorList.propTypes = {
  config: shape({}),
  data: arrayOf(shape({})),
  t: func.isRequired,
  handleSort: func,
};

VendorList.defaultProps = {
  data: [],
  config: {},
  handleSort: noop,
};

export default VendorList;
