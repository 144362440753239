import { useState, useCallback } from 'react';

export const useDialogState = (onConfirm) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleConfirm = useCallback((e) => {
    onConfirm(e);
    handleClose();
  }, [handleClose, onConfirm]);

  return {
    isOpen, handleOpen, handleClose, handleConfirm,
  };
};
