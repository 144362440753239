import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { convertGqlError } from 'utils';
import { Error, ProgressWrapper } from 'components';

import { getConfig } from './tableConfig';
import VendingMachineList from './List';
import { STOCK_MANAGEMENT_LIST } from '../queries';

const VendingMachineListContainer = () => {
  const { t } = useTranslation();
  const {
    loading, error, data,
  } = useQuery(STOCK_MANAGEMENT_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;

  const props = {
    data: get(['vendingMachines', 'nodes'], data),
    config: getConfig(t),
    t,
  };

  return (<VendingMachineList {...props} />);
};

export default VendingMachineListContainer;
