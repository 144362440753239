import styled, { css } from 'styled-components';
import { AngleDown } from 'static/icons';

export const StyledAngleIcon = styled(AngleDown)`
  width: 18px;
  height: 18px;
  fill: ${({ theme }) => theme.palette.greenBlueLightened};

  padding: 10px;
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.polar};
  margin-right: 7px;
  transition: transform 0.3s;

  ${({ disabled }) => disabled && css`
    background: ${({ theme }) => theme.palette.lightGray};
    transform: rotate(180deg);
    fill: ${({ theme }) => theme.palette.grayBlue};
  `}
`;

export const CollapseButtonWrapper = styled.div`
  margin: 15px 0 0 35px;

  & button {
    justify-content: flex-start;
  }
`;
