import styled from 'styled-components';

export const ErrorHeading = styled.h1`
  font-size: 130px;
  line-height: 140px;
`;

export const ErrorDescription = styled.p`
  margin: 20px 0 35px;
  line-height: 24px;
  opacity: 0.7;
  font-weight: 400;
`;
