import React from 'react';
import {
  elementType, string, number, oneOfType,
} from 'prop-types';
import * as icons from 'static/icons';

import {
  styleIcon, Container, LabelContainer, Label, InfoText, InfoTextMultiLine
} from './styled';


const InfoBlock = ({
  icon, label, text, leftIndent, multiline
}) => {
  const Icon = icons[icon];
  const StyledIcon = Icon ? styleIcon(Icon) : null;

  return (
    <Container>
      <LabelContainer>
        {Icon ? <StyledIcon /> : null}
        <Label>{label}</Label>
      </LabelContainer>
      { multiline ? (
        <InfoTextMultiLine leftIndent={leftIndent} >{text}</InfoTextMultiLine>
      ):(
        <InfoText leftIndent={leftIndent} >{text}</InfoText>
      )}
    </Container>
  );
};

InfoBlock.propTypes = {
  icon: elementType,
  label: string.isRequired,
  text: oneOfType([string, number]).isRequired,
  leftIndent: string,
};

InfoBlock.defaultProps = {
  icon: null,
  leftIndent: '',
};

export default InfoBlock;
