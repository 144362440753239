import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  func, shape, arrayOf
} from 'prop-types';

import { ROUTES } from 'configs';
import {
  Table, HeadInfo, ContentBox, TableContainer, BackLink, HeadInfoContainer, StyledHeader,
} from 'components';

const ViewVendingMachine = ({
  t,
  config,
  data,
}) => (
  <>
    <BackLink to={`${ROUTES.SLOT_BOOKING}`} />
    <ContentBox>
      <StyledHeader>
        <h1>{data[0].name}</h1>
      </StyledHeader>
      <HeadInfoContainer>
        <HeadInfo
          title={t('slotsList.head.id')}
          value={data[0].machineId}
        />
        <HeadInfo
          title={t('slotsList.head.address')}
          value={data[0].address}
        />
      </HeadInfoContainer>
    </ContentBox>
    <TableContainer>
      <Table
        config={config}
        data={data[0].slots}
      />
    </TableContainer>
  </>
);

ViewVendingMachine.propTypes = {
  t: func.isRequired,
  config: shape({}).isRequired,
  data: arrayOf(shape({})),// shape({}), 
};

ViewVendingMachine.defaultProps = {
  data: arrayOf(shape({})), //shape({}),
};

export default withTranslation()(ViewVendingMachine);
