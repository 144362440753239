import { CELL_TYPE_DIVIDE } from 'components/Table/constants';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'name',
      label: t('vendorRefill.table.name'),
      key: 'name',
    },
    {
      id: 'address',
      label: t('common.address'),
      key: 'address',
    },
    {
      id: 'actualQuantity',
      label: `${t('vendorRefill.table.actualQuantity')} / ${t('vendorRefill.table.capacity')}`,
      key: ['actualQuantity'],
      secondKey: ['capacity'],
      type: CELL_TYPE_DIVIDE,
    },
    {
      id: 'soldFromLastRefill',
      label: t('vendor.common.ejectedSinceLastRefill'),
      key: 'ejectedSinceLastRefill',
      defaultValue: '0',
    },
    {
      id: 'refillQuantity',
      label: t('vendorRefill.table.refillQuantity'),
      key: ['refillRequest', 'qty'],
    },
  ],
});
