import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';

import { PageWrapper, Button } from 'components';

import { ErrorHeading, ErrorDescription } from './styled';


const UnhandledError = ({ code }) => {
  const { t } = useTranslation();

  return (
    <PageWrapper centered>
      <ErrorHeading>{t('errors.unhandled.code')}</ErrorHeading>
      <ErrorDescription>{t([`errors.${code}`, 'errors.unhandled.message'])}</ErrorDescription>
      <Button color="secondary" component={Link} to="/">{t('errors.unhandled.link')}</Button>
    </PageWrapper>
  );
};

UnhandledError.propTypes = {
  code: string,
};

UnhandledError.defaultProps = {
  code: '',
};


export default UnhandledError;
