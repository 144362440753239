import { gql } from 'apollo-boost';

export const ASSIGN_PRODUCT = gql`
  mutation newAssignProductIntoSlot($input: assignProductIntoSlotInput!) {
    newAssignProductIntoSlot(input:$input) {
      id: _id
      bookedSlot {
        id: _id
      }
    } 
  }
`;
