import styled from 'styled-components';

import { HeadInfo } from 'components';
import { TableContainer } from './styled';

export const BackLinkHeaderContainer = styled.div`
   height: 47px;
   margin: 5px 0 32px 0;
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
`;

export const RefillRequestInfo = styled.div`
  max-width: 775px;
  margin: 15px 0 65px;
  display: grid;
  grid-auto-flow: rows;
  grid-gap: 29px;
`;

export const VMTableContainer = styled(TableContainer)`
  margin-bottom: 75px;
`;

export const VMHeadInfo = styled(HeadInfo)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: inline-block;
`;

export const HeadInfoContainer = styled.div`
  margin-bottom: 24px;
`;

export const StyledFormHeadline = styled.h6`
   margin: 12px 0 20px 0;
`;

export const InfoTitle = styled.p`
  padding-left: 15px;
  margin: 31px 0 10px 0;
  font-family: ${({ theme }) => theme.typography.OpenSansSemiBold};
  color: ${({ theme }) => theme.palette.purpleBlue};
`;

export const Placeholder = styled.span`
  color: ${({ theme }) => theme.palette.grayBlue};
`;

export const StyledHeadInfo = styled(HeadInfo)`
  display: inline-block;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBackLinkHeaderContainer = styled(BackLinkHeaderContainer)`
  margin-bottom: 54px;
`;

export const DateTimePickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  padding-right: 14px;
  color: ${({ theme }) => theme.palette.purpleGray};
  font-family: ${({ theme }) => theme.typography.roboto};
`;

export const HeadContainer = styled.div`
  height: 56px;
  display: flex;
  padding: 0 5px;
  margin-top: 8px;
  margin-bottom: 6px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.lightGray};
`;
