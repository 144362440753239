import {
  string, shape, oneOfType, number, arrayOf, bool, func,
} from 'prop-types';

export const headerType = shape({
  id: oneOfType([string, number]).isRequired,
  label: string,
  key: oneOfType([string, arrayOf(string), func]),
  type: string,
  sortable: bool,
  link: func,
});

export const rowType = shape({
  id: oneOfType([string, number]).isRequired,
});
