import { capReduce } from 'utils';
import {
  filter, get, includes, head,
} from 'lodash/fp';

export const getProductNames = (products) => capReduce(
  (acc, item) => {
    const variants = get('variants', item);
    const variantId = get('id', head(variants));

    acc[variantId] = item.name;
    return acc;
  }, {}, products,
);


export const getProductsBySlotType = (products, slot) => {
  const slotType = get(['slotParams', 'slotType'], slot);

  const productsBySlotType = filter((product) => {
    const productSlotTypes = get(['slotTypes'], product);

    return includes(slotType, productSlotTypes);
  }, products);

  return getProductNames(productsBySlotType);
};
