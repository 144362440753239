import React from 'react';
import {
  string, shape, func, bool, arrayOf,
} from 'prop-types';
import { noop } from 'lodash/fp';
import MenuItem from '@material-ui/core/MenuItem';
import { Progress } from 'components';
import FormElementContainer, { BaseInput, OutlineInput, useInput } from 'components/BaseInput';
import { capMap } from 'utils/lodash';
import { useAssignSelect } from './hooks';
import {
  StyledSelect,
  Container,
  StyledButton,
  StyledEditIcon,
  StyledCancelIcon,
  StyledConfirmIcon,
  ProgressContainer,
} from './styled';

export const AssignSelect = ({
  width, values, defaultValue, label, error, errorText,
  name, required, variant, disabled, displayEmpty, onSubmit,
  assignedSlots, resetComplete, loading,
}) => {
  const {
    editMode, selectValue, setSelectValue, confirmSelect, toggleEditMode, displayValue, value,
  } = useAssignSelect(
    values, defaultValue, name, onSubmit, assignedSlots, resetComplete,
  );

  const { inputErrorText } = useInput('', errorText, error);

  return editMode ? (
    <Container>
      <FormElementContainer
        error={!!error}
        label={label}
        errorText={inputErrorText}
        width={width}
        required={required}
        name={name}
        variant={variant}
        disabled={disabled}
      >
        <StyledSelect
          name={name}
          id={name}
          input={variant === 'outlined' ? <OutlineInput /> : <BaseInput />}
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
          displayEmpty={displayEmpty}
          disabled={loading}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {capMap((item, key) => (
            <MenuItem
              key={key}
              value={key}
            >
              {item}
            </MenuItem>
          ), values)}
        </StyledSelect>
      </FormElementContainer>
      {loading
        ? (
          <ProgressContainer>
            <Progress size={26} />
          </ProgressContainer>
        )
        : (
          <>
            <StyledButton type="button" disabled={selectValue === value} onClick={confirmSelect}>
              <StyledConfirmIcon disabled={selectValue === value} />
            </StyledButton>
            <StyledButton type="button" onClick={toggleEditMode}>
              <StyledCancelIcon />
            </StyledButton>
          </>
        )}
    </Container>
  ) : (
    <Container>
      <span>{displayValue()}</span>
      <StyledButton onClick={toggleEditMode}>
        <StyledEditIcon />
      </StyledButton>
    </Container>
  );
};

AssignSelect.propTypes = {
  width: string,
  values: shape({}),
  defaultValue: string,
  label: string,
  error: string,
  errorText: string,
  variant: string,
  name: string.isRequired,
  required: bool,
  disabled: bool,
  displayEmpty: bool,
  onSubmit: func,
  assignedSlots: arrayOf(string),
  resetComplete: func,
  loading: bool,
};

AssignSelect.defaultProps = {
  width: '100%',
  values: {},
  defaultValue: '',
  label: '',
  error: '',
  errorText: '',
  variant: 'standard',
  required: false,
  disabled: false,
  displayEmpty: false,
  onSubmit: noop,
  assignedSlots: [],
  resetComplete: noop,
  loading: false,
};

export default AssignSelect;
