import { CELL_TYPE_HISTORY } from 'components/Table/constants';
import { get } from 'lodash/fp';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'product',
      label: t('stock.common.product'),
      key: (header, row) => (get(['product', 'name'], row)),
      defaultValue: t('common.remove'),
      type: CELL_TYPE_HISTORY,
    },
    {
      id: 'actualQuantity',
      label: t('stock.common.actualQuantity'),
      key: ['actualQty'],
      defaultValue: 0,
    },
    {
      id: 'refillQuantity',
      label: t('stock.common.refillQuantity'),
      key: ['qty'],
    },
  ],
});
