import { useCallback, useEffect, useState } from 'react';

export const useRouteLeavingGuard = (shouldBlockNavigation, onConfirm, navigate) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [update, setUpdate] = useState(false);

  const closeModal = useCallback(() => setModalVisible(false), []);

  const handleBlockedNavigation = useCallback((nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }, [confirmedNavigation, shouldBlockNavigation]);

  const handleConfirmNavigationClick = useCallback(() => {
    onConfirm(lastLocation.pathname)();
    setModalVisible(false);
    setConfirmedNavigation(true);
    setUpdate(true);
  }, [onConfirm, lastLocation]);

  const handleLeaveNavigationClick = useCallback(() => setConfirmedNavigation(true), []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation && !update) {
      navigate(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, navigate, update]);

  return {
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    handleLeaveNavigationClick,
    modalVisible,
    closeModal,
  };
};
