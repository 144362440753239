import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  shape, arrayOf, number, func,
} from 'prop-types';

import {
  map, get, has, noop, isNumber, isFunction, invokeArgs,
} from 'lodash/fp';

import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { headerType, rowType } from './propTypes';

import Cell from './Cell';
import HeaderCell from './Cell/Header';
import CollapseButton from './CollapseButton';

import {
  StyledTable,
  StyledTableRow,
  StyledTablePagination,
  StyledTableContainer,
} from './styled';

import { useTable, useTableCollapse } from './hooks';

const DataTable = ({
  config,
  data,
  rowsPerPageOptions,
  itemsCount,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  defaultSort,
  onSort,
}) => {
  const {
    selectedPage,
    changePage,
    pageRows,
    changeRowsPerPage,
    sort,
    changeSort,
  } = useTable(page, onChangePage, rowsPerPage, onChangeRowsPerPage, defaultSort, onSort);

  const { tableData, ...collapseProps } = useTableCollapse(config, data);

  const history = useHistory();

  const handleRowClick = useCallback((row) => () => {
    const link = invokeArgs(['rowLink'], [row], config);
    if (link) history.push(link);
  }, [config, history]);

  return (
    <>
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              {map((item) => (
                <HeaderCell
                  config={item}
                  key={item.id}
                  sort={sort}
                  setSort={changeSort}
                >
                  {item.label}
                </HeaderCell>
              ), config.headers)}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              map((row) => (
                <StyledTableRow
                  key={row.id}
                  data-highlighted={invokeArgs(['isHighlightedRow'], [row], config)}
                  data-haslink={has(['rowLink'], config)}
                  onClick={handleRowClick(row)}
                >
                  {
                    map((header) => (
                      <Cell
                        key={`${row.id}-${header.key}`}
                        row={row}
                        header={header}
                        register={config.register}
                        setValue={config.setValue}
                      >
                        {isFunction(header.key) ? header.key(header, row) : get(header.key, row)}
                      </Cell>
                    ), config.headers)
                  }
                </StyledTableRow>
              ), tableData)
            }
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {has(['collapse'], config) && (
        <CollapseButton {...collapseProps} />
      )}
      {isNumber(page) && (itemsCount > rowsPerPageOptions[0]) && (
        <StyledTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={itemsCount}
          rowsPerPage={pageRows}
          page={selectedPage}
          onChangePage={changePage}
          onChangeRowsPerPage={changeRowsPerPage}
        />
      )}
    </>
  );
};

DataTable.propTypes = {
  config: shape({
    headers: arrayOf(headerType),
  }),
  data: arrayOf(rowType),
  defaultSort: shape({}),
  rowsPerPageOptions: arrayOf(number),
  itemsCount: number,
  rowsPerPage: number,
  page: number,
  onChangePage: func,
  onChangeRowsPerPage: func,
  onSort: func,
};

DataTable.defaultProps = {
  config: {
    headers: [{}],
  },
  data: [],
  rowsPerPageOptions: [10, 20, 50, 100, 200],
  itemsCount: 100,
  rowsPerPage: 10,
  page: null,
  defaultSort: {},
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  onSort: noop,
};

export default DataTable;
