import React from 'react';

import { Error, ProgressWrapper } from 'components';

import Edit from './Edit';
import { useProductAssignment } from './hooks';

const ProductAssignment = () => {
  const {
    t, loading, data, config, onSubmit, errorCode,
  } = useProductAssignment();

  if (errorCode) return <Error code={errorCode} />;
  if (loading) return <ProgressWrapper />;

  const props = {
    onSubmit,
    config,
    data,
    t,
  };

  return (
    <Edit {...props} />
  );
};

export default ProductAssignment;
