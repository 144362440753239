import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 100px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.grey,
    "&$checked": {
      color:  theme.palette.green,
    }
  },
  checked: {}
}))(Checkbox );
