import React from 'react';
import { func, string } from 'prop-types';
import { noop } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import { PromptDialog } from 'components';
import { useDialogState } from 'hooks';

import {
  ControllsContainer, StyledPenIcon, StyledTrashIcon,
} from './styled';

const InputControlls = ({ name, onEdit, onRemove }) => {
  const { t } = useTranslation();
  const {
    isOpen, handleOpen, handleClose, handleConfirm,
  } = useDialogState(onEdit);

  return (
    <>
      <ControllsContainer>
        <IconButton onClick={handleOpen}>
          <StyledPenIcon />
        </IconButton>
        <IconButton onClick={onRemove}>
          <StyledTrashIcon />
        </IconButton>
      </ControllsContainer>
      <PromptDialog
        defaultValue={name}
        label={t('customInput.dialog.label')}
        open={isOpen}
        onConfirm={handleConfirm}
        onCancel={handleClose}
        title={t('customInput.dialog.edit-title')}
        confirmButtonText={t('common.edit')}
        cancelButtonText={t('common.cancel')}
      />
    </>
  );
};

InputControlls.propTypes = {
  name: string,
  onEdit: func,
  onRemove: func,
};

InputControlls.defaultProps = {
  name: '',
  onEdit: noop,
  onRemove: noop,
};

export default InputControlls;
