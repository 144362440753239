import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, StyledIcon, Placeholder } from './styled';

const NoContentPlaceholder = ({ placeholder }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledIcon />
      <Placeholder>{placeholder || t('common.noContentPlaceholder')}</Placeholder>
    </Container>
  );
};

NoContentPlaceholder.propTypes = {
  placeholder: string,
};

NoContentPlaceholder.defaultProps = {
  placeholder: '',
};

export default NoContentPlaceholder;
