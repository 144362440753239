import React from 'react';

import {
  PageWrapper,
} from 'components';

import Router from './router';

const MachineStatusPage = () => (
  <PageWrapper>
    <Router />
  </PageWrapper>
);

export default MachineStatusPage;
