import React from 'react';
import { func } from 'prop-types';
import { noop } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { PromptDialog } from 'components';
import { useDialogState } from 'hooks';

import { StyledPlusIcon, TextButton } from './styled';

const AddButton = ({ onAdd }) => {
  const { t } = useTranslation();
  const {
    isOpen, handleOpen, handleClose, handleConfirm,
  } = useDialogState(onAdd);

  return (
    <>
      <PromptDialog
        label={t('customInput.dialog.label')}
        open={isOpen}
        onConfirm={handleConfirm}
        onCancel={handleClose}
        title={t('customInput.dialog.add-title')}
        confirmButtonText={t('common.add')}
        cancelButtonText={t('common.cancel')}
      />
      <TextButton
        startIcon={<StyledPlusIcon />}
        onClick={handleOpen}
      >
        {t('customInput.add')}
      </TextButton>
    </>
  );
};

AddButton.propTypes = {
  onAdd: func,
};

AddButton.defaultProps = {
  onAdd: noop,
};

export default AddButton;
