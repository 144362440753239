import { CELL_TYPE_HIGHLIGHTED, CELL_TYPE_ARROW } from 'components/Table/constants';
import { ROUTES } from 'configs';

export const getConfig = (t) => ({
  headers: [
    {
      id: 'machineId',
      label: t('slot.list.table.id'),
      type: CELL_TYPE_HIGHLIGHTED,
      key: 'machineId',
    },
    {
      id: 'name',
      label: t('slot.list.table.name'),
      key: 'name',
    },
    {
      id: 'slotsNumber',
      label: t('slot.list.table.slots'),
      key: 'slotsNumber',
    },
    {
      id: 'slotsBooked',
      label: t('slot.list.table.slotsBooked'),
      key: 'slotsBooked',
    },
    {
      id: 'address',
      label: t('slot.list.table.address'),
      key: 'address',
    },
    {
      id: 'forward',
      type: CELL_TYPE_ARROW,
    },
  ],
  rowLink: (data) => `${ROUTES.SLOT_BOOKING}/${data.id}`,
});
